<template>
 
  <v-row style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;">Состав </span>
      </div>

    </v-col>
  <!-- </v-row> -->
  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      >
  <v-row v-for="(num, index) in events_list" :key="`row-${num.id}`">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      @click="slideToggle(index,num[0].id)"
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">
            {{ (index == 'goalkeeper') ? 'Вратари' : (index == 'defender') ? 'Защитники' : (index == 'midfielder') ? 'Полузащитники' : 'Нападающие'}}
          </span>
        </div>

      </div>
    </v-col>
    <!-- <vue-slide-up-down :active="num.show"> -->
    <v-col
      v-if="num[0].show"
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      :id="`slide-${num.id}`"
    >
    <v-data-table
        :headers="headers"
        :items="num"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        style="width:100%;"
      >
      <template v-slot:item.number="{item}">
        <div style="width:20%; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style=" font-size:14px;"> {{ item.number }}</span>
        </div>
      </template>
      <template v-slot:item.player_name="{item}">
        <div style="display: flex; align-items: center;">
          <div style="width:20%;height:100%;display: flex; align-items:center; padding:2px 0px; height:64px;" >
              <div style="width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="item.player_image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
          </div>
          <div style="width:100%;height:100%;display: flex;flex-direction: column; padding:2px 0px; height:64px;justify-content: center;" >
              <span> {{ item.player_name }}</span>
          </div>
      </div>
      </template>
      <template v-slot:item.age="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;"> {{ item.age }}</span>
        </div>
      </template>
      <template v-slot:item.player_stats.games="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;"> {{ item.player_stats.games ? item.player_stats.games : 0 }}</span>
        </div>
      </template>
      <template v-slot:item.player_stats.goals="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;"> {{ item.player_stats.goals ? item.player_stats.goals : 0 }}</span>
        </div>
      </template>
      <template v-slot:item.player_stats.yellow_cards="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;"> {{ item.player_stats.yellow_cards ? item.player_stats.yellow_cards : 0 }}</span>
        </div>
      </template>
      <template v-slot:item.player_stats.red_cards="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;"> {{ item.player_stats.red_cards ? item.player_stats.red_cards : 0 }}</span>
        </div>
      </template>

    </v-data-table>
    </v-col>
  <!-- </vue-slide-up-down> -->

  </v-row>
</v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {teamsActionTypes} from '@/store/modules/teams'

export default {
inject: {
    theme: {
      default: { isDark: false },
    },
  },
name: 'LeagueResults',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    events_list:[]
  }
},
computed: {
  ...mapState({
    isLoading: state => state.team.isLoading,
    team: state => state.team.data,
    validationErrors: state => state.team.validationErrors,
  }),

  headers() {
    return [
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'number',
        width: '10%'
      },
      {
        text: 'Имя',
        align: 'start',
        sortable: false,
        value: 'player_name',
        width: '40%'
      },
      {
        text: 'Возраст',
        align: 'start',
        sortable: false,
        value: 'age',
        width: '10%'
      },
      {
        text: 'И',
        align: 'start',
        sortable: false,
        value: 'age',
        width: '10%'
      },
      {
        text: 'Г',
        align: 'start',
        sortable: false,
        value: 'player_stats.games',
        width: '10%'
      },
      {
        text: 'ЖК',
        align: 'start',
        sortable: false,
        value: 'player_stats.yellow_cards',
        width: '10%'
      },
      {
        text: 'КК',
        align: 'start',
        sortable: false,
        value: 'player_stats.red_cards',
        width: '10%'
      },
    ];

  },
},
watch: {
  
},
mounted() {
  this.fetchFeed()
},
methods: {
  slideToggle(ind,id){
    console.log(ind)
    console.log(id)
    this.events_list[ind].show = this.events_list[ind].show ? false : true 
  },
  fetchFeed() {
    var team = this.$route.params.slug.toString()
      this.$store.dispatch(teamsActionTypes.getTeamPlayers,{competitor_id:team}).then((data) => {
        console.log(data)
        this.events_list = data.data
        this.loading = false;
      })
  },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}
</style>

