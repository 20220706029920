import leaguesApi from '@/api/leagues'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  leagues: null,
  popular:null,
  validationErrors: null,
  league: null
}

export const mutationTypes = {

  getLeaguesStart: '[leagues] Get leagues start',
  getLeaguesSuccess: '[leagues] Get leagues success',
  getLeaguesFailure: '[leagues] Get leagues failure',

  getPopularStart: '[popular] Get popular start',
  getPopularSuccess: '[leagues] Get popular success',
  getPopularFailure: '[popular] Get popular failure',

  getLeagueStart: '[league] Get league start',
  getLeagueSuccess: '[league] Get league success',
  getLeagueFailure: '[league] Get league failure',
}

export const leaguesActionTypes = {
  getLeagues: '[leagues] Get leagues',
  getLeague: '[league] Get league',
  getPopular: '[popular] Get popular'
}

const mutations = {
  [mutationTypes.getLeaguesStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getLeaguesSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.leagues = payload
  },
  [mutationTypes.getLeaguesFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getLeagueStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getLeagueSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.league = payload
  },
  [mutationTypes.getLeagueFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getPopularStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getPopularSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.popular = payload
  },
  [mutationTypes.getPopularFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

}

const actions = {
  [leaguesActionTypes.getLeagues](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getLeaguesStart)
      leaguesApi
        .getLeagues(data)
        .then(response => {
          context.commit(mutationTypes.getLeaguesSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getLeaguesFailure)
        })
    })
  },
  [leaguesActionTypes.getLeague](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getLeagueStart)
      leaguesApi
        .getLeagues(data)
        .then(response => {
          context.commit(mutationTypes.getLeagueSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getLeagueFailure)
        })
    })
  },
  [leaguesActionTypes.getPopular](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getPopularStart)
      leaguesApi
        .getPopular(data)
        .then(response => {
          context.commit(mutationTypes.getPopularSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getPopularFailure)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations
}
