import { render, staticRenderFns } from "./LeagueScorers.vue?vue&type=template&id=479b89fe&scoped=true"
import script from "./LeagueScorers.vue?vue&type=script&lang=js"
export * from "./LeagueScorers.vue?vue&type=script&lang=js"
import style0 from "./LeagueScorers.vue?vue&type=style&index=0&id=479b89fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479b89fe",
  null
  
)

export default component.exports