<template>
  <v-container style="background-color: #EBEBEB; border-radius: 8px; max-width:100% !important;">

    <v-row style=" border: 0.5px solid #E4E5E5;  background-color: #fff;margin-bottom: 16px; border-radius: 8px;">
      <v-col
        class="d-flex demo-content"
        cols="12"
        style="flex-direction: column; padding: 24px 24px 0px 24px;"
        >
        <div class="d-flex">
          <div style="width:25px; cursor: pointer;" @click="$router.push({path: '/main', params: {}})">
            <v-img :src="require('@/assets/sc_home.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img></div>
          
          <span class="mob_size_span0" style="padding-left:10px;"> / </span>
          
          <div class="mob_size_span0" style="padding-left:10px; font-size: 14px; color: #747780; cursor: pointer;"
          @click="setLeague(event_data)">{{ event_data.league_name }}</div>
          
          <span class="mob_size_span0" style="padding-left:10px;"> / </span>

          <div class="mob_size_span0" style="padding-left:10px; font-size: 14px; color: #747780;">{{ event_data.name }}</div>
        </div>

        <div class="d-flex dbgr" style="align-items: center; justify-content: space-around; margin:20px 0px; padding:23px; height:auto; border-radius: 8px; background-color: #F8F8F8;">

          <div @click="setTeam(event_data,event_data.team_1_id,event_data.team_1_name,event_data.team_1_logo)" style="
          width: 200px;
          
          cursor: pointer; display: flex; flex-direction: column; align-items: center;">
              <div style="
              padding: 11px 15px 15px 15px;
          border-radius: 150px;
          border: 0.5px solid #E4E5E5;
          height: 84px;
          width:84px;
              ">
                <v-img :src="event_data.team_1_logo" class="" alt="" contain  max-height="60"
                min-height="60"></v-img>
            </div>
            <span style="padding-top:10px; font-size: 16px;
              font-style: normal;
              text-align: center;
              font-weight: 500;
              line-height: 24px;">{{ event_data.team_1_name }}</span>
          </div>

          <div class="d-flex" style="flex-direction:column; align-items: center; padding:0px 0px 0px 0px;">
            <div style="font-size: 14px;">{{ event_data.league_country }}. {{ event_data.league_name }}</div>
            <div style="margin:10px 0px; font-weight: bold; font-size:17px;">
              <span style="font-size: 24px;" v-if="event_data.result == ':'">ожидается</span>
              <span style="font-size: 24px;" v-else>{{ event_data.result }}</span>
            </div>
  

            <div style="font-size: 22px; font-weight: 600;" v-if="event_data.status == 'live'">
                {{ event_data.team_1_result }} : {{ event_data.team_2_result }}
            </div>
            <div style="font-size: 14px; margin:5px 0px;" v-if="event_data.status == 'live'">
               <span v-if="+event_data.time && event_data.time > 45"> 2 тайм</span> 
               <span v-if="+event_data.time && event_data.time < 46"> 1 тайм</span> 
            </div>

            <div style="font-size: 14px;" v-if="event_data.status != 'live'">{{ event_data.date }} {{ event_data.time }} </div>
            <div style="    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    padding: 4px 8px;
    background: rgb(251, 235, 234);
    border-radius: 4px;     color: rgb(220, 54, 46);" v-else>{{ event_data.time }}'</div>

            <div style="font-size: 14px;" v-if="event_data.status == 'closed'">Завершён</div>

          </div>

          <div @click="setTeam(event_data,event_data.team_2_id,event_data.team_2_name,event_data.team_2_logo)" style="
          width: 200px;
          
          cursor: pointer; display: flex; flex-direction: column; align-items: center;">
              <div style="
              padding: 11px 15px 15px 15px;
          border-radius: 150px;
          border: 0.5px solid #E4E5E5;
          height: 84px;
          width:84px;
              ">
                <v-img :src="event_data.team_2_logo" class="" alt="" contain  max-height="60"
                min-height="60"></v-img>
            </div>
            <span style="padding-top:10px; font-size: 16px;
              font-style: normal;
              text-align: center;
              font-weight: 500;
              line-height: 24px;">{{ event_data.team_2_name }}</span>
          </div>
        </div>

        <div class="d-flex blcks" style="align-items: center; flex-wrap: wrap;">
          <div class="d-flex" @click="newLeageu = false, eventContent = 'Info'" v-bind:class="{ active: eventContent == 'Info' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Обзор</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, eventContent = 'H2H'" v-bind:class="{ active: eventContent == 'H2H' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>H2H</span>
          </div>
          <div v-if="league_data.league_type == 'world'" class="d-flex" @click="newLeageu = false, eventContent = 'Table'" v-bind:class="{ active: eventContent == 'Table' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Таблица</span>
          </div>
          <div v-if="league_data.league_type != 'world'" class="d-flex" @click="newLeageu = false, eventContent = 'TableNational'" v-bind:class="{ active: eventContent == 'TableNational' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Таблица</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, eventContent = 'Stats'" v-bind:class="{ active: eventContent == 'Stats' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Статистика</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, eventContent = 'Team'" v-bind:class="{ active: eventContent == 'Team' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Состав</span>
          </div>
        </div>

      </v-col>

    </v-row>

    <EventInfo v-if="eventContent == 'Info'" />

    <EventH2H v-if="!newLeageu && eventContent == 'H2H'" />

    <EventTable v-if="!newLeageu && eventContent == 'Table'" />

    <EventTableNational v-if="!newLeageu && eventContent == 'TableNational'" />

    <EventStats v-if="!newLeageu && eventContent == 'Stats'" />

    <EventTeam v-if="!newLeageu && eventContent == 'Team'" />

    <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        
  </v-sheet>
  </v-container>


</template>

<script>
import {mapState} from "vuex";
import EventInfo from '@/views/Event/EventInfo.vue';
import EventH2H from '@/views/Event/EventH2H.vue';
import EventTable from '@/views/Event/EventTable.vue';
import EventTableNational from '@/views/Event/EventTableNational.vue';
import EventStats from '@/views/Event/EventStats.vue';
import EventTeam from '@/views/Event/EventTeam.vue';
import {eventBus} from '@/main';
import {eventsActionTypes} from '@/store/modules/events'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'League',
  // props: ['league_id','league_value_name'],
  components: {
    EventInfo,
    EventH2H,
    EventTable,
    EventStats,
    EventTeam,
    EventTableNational
  },
  data() {
    return {
      newLeageu:false,
      event_data_def:[
        {
          league_name:'Английская премьер лига',
          league_id:'sr:competition:17',
          league_type:'national',
          league_event:'Манчестер Юнайтед - Челси',
          date:'26.09',
          time:'20:00',
          country:'Англия',
          season_stage:'2 тур',
          team_1:{
            id:"sr:competitor:1",
            name:"Манчестер Юнайтед",
            logo:"mu.png"
          },
          team_2:{
            id:"sr:competitor:2",
            name:"Челси",
            logo:"chelsea.png"
          }
        },
        {
          league_name:'Лига чемпионов',
          league_id:'sr:competition:1',
          league_type:'world',
          league_event:'Манчестер Сити - Арсенал',
          date:'26.09',
          time:'20:00',
          country:'Европа',
          season_stage:'2 тур',
          team_1:{
            id:"sr:competitor:1",
            name:"Манчестер Сити",
            logo:"mancity.png"
          },
          team_2:{
            id:"sr:competitor:2",
            name:"Арсенал",
            logo:"arsenal.png"
          }
        },
        {
          league_name:'Лига европы',
          league_id:'sr:competition:7',
          league_type:'world',
          league_event:'Ливерпуль - Бавария',
          date:'26.09',
          time:'20:00',
          country:'Европа',
          season_stage:'2 тур',
          team_1:{
            id:"sr:competitor:1",
            name:"Ливерпуль",
            logo:"liverpool.png"
          },
          team_2:{
            id:"sr:competitor:2",
            name:"Бавария",
            logo:"bavaria.png"
          }
        }
      ],
      event_data:{
        league_name:'',
        league_id:'',
        league_type:'',
        league_event:'',
      },
      league_season:'2022/2023',
      loading:false,
      eventContent:'Info',
      //bgImage:"url('@/assets/apl.png')",
      bgImage:`url(${require('../../assets/apl.png')})`,
      //bgImage:0.6,
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      league_data:{
        name:'',
        league_id:'',
        league_type:'',
      },
      sports_list_locale:{
        "soccer":"Футбол",
        "tennis":"Теннис",
        "icehockey":"Хоккей",
      },
      competitionDates:[
        '2022/2023',
         '2021/2022'
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.event.isLoading,
      event: state => state.event.data,
      validationErrors: state => state.event.validationErrors,
    }),
    // computedStyle() { 
    //   return {
    //     'background-color': '#fff'
    //   }
    // },
  },
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
        console.log(newSlug)
        console.log(oldSlug)
        if(newSlug != oldSlug){
          this.newLeageu = true
        }
        if(newSlug == 'sr:competition:1'){
          this.event_data = this.event_data_def[1]
        }
        else if(newSlug == 'sr:competition:17'){
          this.event_data = this.event_data_def[0]
        } else {
          this.event_data = this.event_data_def[2]
        }
    }
  },
//   created () {
//     eventBus.$on('setliveinfo', data => {
//       console.log('setliveinfo '+data)
//       console.log(data)
//       this.event_data.status = data.status
//       this.event_data.team_1_result = data.home_score
//       this.event_data.team_2_result = data.away_score
//       this.event_data.time = data.time.split(':')[0]
  
//     })
//  },
  mounted() {

    this.league_data = JSON.parse(localStorage.getItem('localCompetition'))
    console.log(this.league_data)

    this.event_data = JSON.parse(localStorage.getItem('localEvent'))

    this.fetchFeed()

    if(this.event_data && this.event_data.status && this.event_data.status != 'closed' && this.event_data.status != 'not_started' && this.event_data.status != 'ended' && this.event_data.status != 'postponed'){
      this.timer = setInterval(() => {
        console.log('set')
        this.fetchFeed()
      }, 10000)
    }
  },
  beforeDestroy() {
    console.log('close')
    clearInterval(this.timer)
  },
  methods: {
    setLeague(item){
      console.log(item)
      var leagueItem = {}
      leagueItem.id = item.league_id
      leagueItem.name = item.league_name
      leagueItem.logo = item.league_logo
      if(leagueItem.name.split('Межд').length > 1 || leagueItem.name.split('межд').length > 1 || leagueItem.name.split('Int').length > 1  || leagueItem.name.split('int').length > 1){
        leagueItem.league_type = "world"
      } else {
        leagueItem.league_type = "national"
      }
    
      console.log(leagueItem)
      // localStorage.setItem('localCompetition',JSON.stringify(leagueItem))
      if(this.$route.path.toString().split('guene').length > 1){
        this.$router.push({path: '/league/'+leagueItem.id, replace: true})
      } else {
        this.$router.push({path: '/leaguenext/'+leagueItem.id, replace: true})
      }
    },
    setTeam(num,id,name,logo){
      console.log(num)
      console.log(id)
      
      var teamObj = {}
      teamObj.country = num.league_country
      teamObj.logo = logo
      teamObj.name = name
      teamObj.id = id
      localStorage.setItem('localTeam',JSON.stringify(teamObj))
      if(this.$route.path.toString().split('mnex').length > 1){
        this.$router.push({path: '/team/'+id, replace: true})
      } else {
        this.$router.push({path: '/teamnext/'+id, replace: true})
      }
    },
    slideToggle(ind,id){
      console.log(ind)
      console.log(id)
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    fetchFeed() {
      this.$store.dispatch(eventsActionTypes.getEventInfo,{event_id:this.event_data.id}).then((data) => {
        console.log(data)
      this.event_data.status = data.status
      this.event_data.team_1_result = data.home_score
      this.event_data.team_2_result = data.away_score
      this.event_data.time = data.time.split(':')[0]
      })
    },
  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.dbgr {
    /* background-image: url(require('../../assets/event_bg.png')), */
    
    background-image: url('~@/assets/event_bg.png');
  background-repeat: no-repeat;
  background-position: 95% 100%;
    background-size: 100% 100%;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field >>> .v-input__control >>> .v-input__slot:before {
  display: none !important;
}
.year_check >>> .v-input__slot:before {
  display: none !important;
}

.v-text-field >>> .v-input__slot:after {
  display: none !important;
}

.v-text-field >>> .v-input__slot:before {
  display: none !important;
}

</style>

