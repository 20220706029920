import eventsApi from '@/api/events'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  events: null,
  validationErrors: null,
  league: null
}

export const mutationTypes = {

  getEventsStart: '[events] Get events start',
  getEventsSuccess: '[events] Get events success',
  getEventsFailure: '[events] Get events failure',

  getLeagueEventsStart: '[events] Get league events start',
  getLeagueEventsSuccess: '[events] Get league events success',
  getLeagueEventsFailure: '[events] Get league events failure',

  getTeamEventsStart: '[events] Get team events start',
  getTeamEventsSuccess: '[events] Get leteamague events success',
  getTeamEventsFailure: '[events] Get team events failure',

  getEventStart: '[league] Get league start',
  getEventSuccess: '[league] Get league success',
  getEventFailure: '[league] Get league failure',

  getEventInfoStart: '[event] Get event info start',
  getEventInfoSuccess: '[event] Get event info success',
  getEventInfoFailure: '[event] Get event info failure',

  getEventHStart: '[eventh] Get eventh start',
  getEventHSuccess: '[eventh] Get eventh success',
  getEventHFailure: '[eventh] Get eventh failure',

  getEventStatsStart: '[eventstats] Get event stats start',
  getEventStatsSuccess: '[eventstats] Get event stats success',
  getEventStatsFailure: '[eventstats] Get event stats failure',

  getEventTeamStart: '[eventteam] Get event team start',
  getEventTeamSuccess: '[eventteam] Get event team success',
  getEventTeamFailure: '[eventteam] Get event team failure',

  getEventStatsLiveStart: '[eventstats] Get event stats live start',
  getEventStatsLiveSuccess: '[eventstats] Get event stats live success',
  getEventStatsLiveFailure: '[eventstats] Get event stats live failure',
  
}

export const eventsActionTypes = {
  getEvents: '[events] Get events',
  getLeagueEvents: '[events] Get league events',
  getTeamEvents: '[events] Get team events',
  getEvent: '[league] Get league',
  getEventInfo: '[event] Get event info',
  getEventH: '[eventh] Get eventh',
  getEventStats: '[eventstats] Get event stats',
  getEventTeam: '[eventteam] Get event team',
  getEventStatsLive: '[eventstats] Get event stats live',
}

const mutations = {
  [mutationTypes.getEventsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.events = payload
  },
  [mutationTypes.getEventsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getLeagueEventsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getLeagueEventsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.events = payload
  },
  [mutationTypes.getLeagueEventsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getTeamEventsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getTeamEventsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.events = payload
  },
  [mutationTypes.getTeamEventsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getEventStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.league = payload
  },
  [mutationTypes.getEventFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getEventInfoStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventInfoSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.event = payload
  },
  [mutationTypes.getEventInfoFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getEventHStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventHSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.eventh = payload
  },
  [mutationTypes.getEventHFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getEventStatsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventStatsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.eventstats = payload
  },
  [mutationTypes.getEventStatsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getEventTeamStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventTeamSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.eventteam = payload
  },
  [mutationTypes.getEventTeamFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getEventStatsLiveStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getEventStatsLiveSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.eventstats = payload
  },
  [mutationTypes.getEventStatsLiveFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

}

const actions = {
  [eventsActionTypes.getEvents](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventsStart)
      eventsApi
        .getEvents(data)
        .then(response => {
          context.commit(mutationTypes.getEventsSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventsFailure)
        })
    })
  },
  [eventsActionTypes.getLeagueEvents](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getLeagueEventsStart)
      eventsApi
        .getLeagueEvents(data)
        .then(response => {
          context.commit(mutationTypes.getLeagueEventsSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getLeagueEventsFailure)
        })
    })
  },
  [eventsActionTypes.getTeamEvents](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTeamEventsStart)
      eventsApi
        .getTeamEvents(data)
        .then(response => {
          context.commit(mutationTypes.getTeamEventsSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getTeamEventsFailure)
        })
    })
  },
  [eventsActionTypes.getEvent](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventStart)
      eventsApi
        .getEvents(data)
        .then(response => {
          context.commit(mutationTypes.getEventSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventFailure)
        })
    })
  },
  [eventsActionTypes.getEventInfo](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventInfoStart)
      eventsApi
        .getEventInfo(data)
        .then(response => {
          context.commit(mutationTypes.getEventInfoSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventInfoFailure)
        })
    })
  },
  [eventsActionTypes.getEventH](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventHStart)
      eventsApi
        .getEventH(data)
        .then(response => {
          context.commit(mutationTypes.getEventHSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventHFailure)
        })
    })
  },
  [eventsActionTypes.getEventStats](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventStatsStart)
      eventsApi
        .getEventStats(data)
        .then(response => {
          context.commit(mutationTypes.getEventStatsSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventStatsFailure)
        })
    })
  },
  [eventsActionTypes.getEventTeam](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventTeamStart)
      eventsApi
        .getEventTeam(data)
        .then(response => {
          context.commit(mutationTypes.getEventTeamSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventTeamFailure)
        })
    })
  },
  [eventsActionTypes.getEventStatsLive](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getEventStatsLiveStart)
      eventsApi
        .getEventStatsLive(data)
        .then(response => {
          context.commit(mutationTypes.getEventStatsLiveSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getEventStatsLiveFailure)
        })
    })
  }
}

export default {
  state,
  actions,
  mutations
}
