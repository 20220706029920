<template>
  <v-app id="inspire">
    <Header type="return-search" style="background-color: #DC362E; left: 0px;" />
    <v-main class="main_div" style="padding: 110px 0px 50px 322px;">
            <!-- <v-container> -->
              <v-row style="margin:0px !important;">

                <Menu />

                <!-- <v-container fluid class="adaptive-container"> -->
                  <v-row style="margin:-12px 0px -12px 0px !important; width:100%;">
                    <v-col
                        class="page-top d-flex justify-space-between"
                        cols="12"
                    >
                          <router-view />
                    </v-col>
                  </v-row>
                <!-- </v-container> -->
              </v-row>

             
        <!-- </v-container> -->
    </v-main>
    <!-- <Login v-if="!$store.state.userInterface" /> -->
  </v-app>
</template>

<script>
//import Login from "@/views/Login";
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';

export default {
  name: "App",
  components: {
    //Login,
    Header,
    Menu
  },
  data() {
    return {
      drawer: true,
      group: null,
      isLoading:false,
      filter: {
        country: ""
      },
      countryList: [
        { id: '1', Name: 'Англия' },
        { id: '2', Name: 'Австралия' },
        { id: '3', Name: 'Австрия' },
        { id: '4', Name: 'Айзербаджан' },
        { id: '5', Name: 'Албания' },
        { id: '6', Name: 'Алжир' }
      ],
    };
  },
  mounted() {
    console.log('appvue')
  },
  methods: {
    checkClass(page){
      let res = 'divt'
      if(page == this.$route.fullPath.split('/')[1]){
        res = 'divt divta'
      }
      return res;
    },
    clearStorage() {
      if(localStorage.getItem('pagination_page')){
        localStorage.setItem('pagination_page', 0)
      }
      if(localStorage.getItem('pagination_number')){
        localStorage.setItem('pagination_number', 0)
      }
    } 
  },
  watch: {
    group() {
      this.drawer = false;
    },
    showMenu() {
      let type = localStorage.getItem('type')
      this.showMenu = type
    },
  },
};
</script>
<style scoped>
.main_div{
  background-color: #EBEBEB !important;
  border-color: #EBEBEB !important;
}
.divt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    font-size: 16px;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.0892857143em;
    line-height: normal;
    min-width: 90px;
    max-width: 360px;
    color: rgba(255, 255, 255, 0.6);
}
.divta {
  color:white;
}
.menu {
  height: 80px;
  padding: 8px 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-decoration: none;
}

.router-link-active {
  background: #F1F1F1 !important;
  border-right: 4px solid #2D3648;
  border-left: 4px solid transparent;
}

.router-link-active>.menu-logo {
  background: #2D3648 !important;
}

.router-link-active>.menu-title {
  color: #2D3648 !important;
}

.router-link-active:hover {
  background: #F1F1F1 !important;
}

.router-link-active:hover>.menu-logo {
  background: #2D3648 !important;
}

.router-link-active:hover>.menu-title {
  color: #2D3648 !important;
}

.v-list-item:hover {
  background: none !important;
}

.menu:hover {
  background: #F1F1F1;
}

.menu:hover>.menu-logo {
  background: #000;
}

.menu:hover>.menu-title {
  color: #000;
}

.v-list-item {
  padding: 0 0 8px !important;
}

.v-list-item:before {
  background: none !important;
}

.menu-logo {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #767676;
  border-radius: 25px;
  color: #fff;
}

.menu-title {
  padding-top: 8px;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
}
.v-navigation-drawer__content{
overflow: inherit!important;
}
.v-navigation-drawer::-webkit-scrollbar {
    width: 4px !important;
}
html {
  scrollbar-width: thin;
  background-color: #EBEBEB !important;
}
#inspire {
  background-color: #EBEBEB !important;
}
@media (max-width: 1264px) {
.main_div {
  padding: 110px 0px 0px 0px !important;
}
}

</style>
<style>
.v-data-table__mobile-row {
justify-content: flex-start !important;
}
@media (max-width: 600px) {
.mob_size_span0 {
  font-size:11px !important;
}  
.mob_size_span {
  font-size:13px !important;
}
.mob_size_span2 {
  font-size:12px !important;
}
.mob_size_span3 {
  font-size:18px !important;
}
.to_column {
  flex-direction: column !important;
}
.to_row {
  flex-direction: row !important;
}
.mob_not_show {
  display: none !important;
}
.demo-wrap{
  --bgImage: none !important;
}
.handPickerItemCenter {
  width: 87px !important;
}
.handPicker {
  left: -88px !important;
}
.mob_search {
  width:60% !important;
}
.mob_logo {
  width:80px !important;
}
.show_mob {
  display:block !important;
}
}
</style>