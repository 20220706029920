<template>
 
  <v-row class="table_main_row" style="background-color: #fff; border-radius: 8px;">

<v-col
      cols="12"
      style="display: flex; flex-direction: column; padding:16px 0px 0px 0px;"
      >
  <div style="display:flex;align-items:center; padding: 0px 25px; width:100%; border-bottom: 0.5px solid #E4E5E5; height: 56px;">
    <span style="padding-left:12px; font-size: 14x;">Турнирная таблица</span>
  </div>
  <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>
  <div class="tab_type" style="display:flex;align-items:center;width:100%; padding:16px 24px;border-bottom: 0.5px solid #E4E5E5; height: 56px;">
    <div class="item" @click="leagueContent = 'Qualify', fetchFeed(season_id,'Qualify')" v-bind:class="{ active: leagueContent == 'Qualify' }" style="border-radius:6px;cursor:pointer;font-size: 14px; width:157px; height:32px; display:flex; align-items: center; justify-content: center;">
      Квалификация</div>
    <div class="item" @click="leagueContent = 'Group', fetchFeed(season_id,'Group')" v-bind:class="{ active: leagueContent == 'Group' }" style="border-radius:6px;cursor:pointer;margin-left:12px; font-size: 14px;width:157px; height:32px; display:flex; align-items: center; justify-content: center;">
      Групповой этап</div>
    <div class="item" @click="leagueContent = 'Playoff', fetchFeed(season_id,'Playoff')" v-bind:class="{ active: leagueContent == 'Playoff' }" style="border-radius:6px;cursor:pointer;margin-left:12px; font-size: 14px;width:157px; height:32px; display:flex; align-items: center; justify-content: center;">
      Плей-офф</div>
  </div>

</v-col>

<v-col
v-if="table_list && leagueContent == 'Qualify'"
      cols="12"
      style="display: flex; flex-direction: column; padding:0px 0px;"
      >

  <div v-if="table_list && (table_list['semifinal'] || table_list['final']) && leagueContent == 'Qualify'" class="" style="display:flex;align-items:center;width:100%; padding:8px 24px;border-bottom: 0.5px solid #E4E5E5;height: 36px; background-color: #F8F8F8;">
    <div class="" style="width:50%; display: flex; justify-content: center;">1/2 финала</div>
    <div class="" style="width:50%; display: flex; justify-content: center;">Финал - квалификации</div>
  </div>

  <div v-if="table_list && (table_list['semifinal'] || table_list['final']) && leagueContent == 'Qualify'" class="" style="display:flex; width:100%; padding:24px 0px; height:auto; flex-direction: column;">

    
    <div class="" style="width:100%; display: flex; align-items: center; padding: 0px 24px;">

      <div style="display: flex; flex-direction: column; width:48%">
        <div v-for="(stage, index) in table_list['semifinal']" :key="`row-${index}-${stage[0].cup_round_id}`" style="width:100%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px;  margin-bottom: 24px;">
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="setTeam(stage[0],1)">
                <div style="width:30px;">
            
                   <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].period_1_home_score }}</span>
                  <span style="padding-left:12px;">{{ stage[0].period_2_home_score }}</span>
              </div>
            </div>
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div  style="cursor:pointer; display: flex; width:100%; align-items: center;" @click="setTeam(stage[0],2)">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].period_1_away_score }}</span>
                  <span style="padding-left:12px;">{{ stage[0].period_2_away_score }}</span>
              </div>
            </div>

        </div>


      </div>

      <div style="width:4%; height: 84px; box-sizing: border-box; display: flex; align-items: center;">
        <div style="width:50%; height: 100%;border-top: 1px solid #E4E5E5; border-bottom: 1px solid #E4E5E5; border-right: 1px solid #E4E5E5; 
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px; 
        ">
        </div>
        <div style="width:50%; height: 1px; background:  #E4E5E5;">
        </div>
      </div>


      <div v-for="(stage, index2) in table_list['final']" :key="`row-${index2}-${stage[0].cup_round_id}`" style="width:48%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px; ">
                      <!--v-list-->
                      <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_1_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].period_1_home_score }}</span>
                  <span style="padding-left:12px;">{{ stage[0].period_2_home_score }}</span>
              </div>
            </div>
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div  style="cursor:pointer; display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_2_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].period_1_away_score }}</span>
                  <span style="padding-left:12px;">{{ stage[0].period_2_away_score }}</span>
              </div>
            </div>

        </div>

    </div>
  </div>

  <div style="display:flex; flex-direction: column;">

    <v-row v-if="table_list['1']">
      <v-col cols="12"><h2 style="padding: 10px 30px;">1-й квалификационный раунд</h2></v-col>
      <v-col cols="8" style="padding:10px 30px;">
      <div style="width:100%; display:flex; align-items: center; color:#94999f; font-weight: 600; padding:10px; justify-content: space-between;">
        <div style="width:230px;">Команда</div>
        <div></div>
        <div style="width:50px;"> 1</div>
        <div style="width:50px;"> 2</div>
        <div style="width:50px;">Мячи</div>
      </div>
      <div v-for="(stage, index2) in table_list['1']" :key="`dd-${index2}-${stage.cup_round_id}`" style="border-top:1px solid #eee; width:100%;padding:10px; display:flex; flex-direction:column;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
          </div>
          <div style="width:200px;">{{ stage[0].competitor_1_name }}</div>
          <div style="width:50px;">
            <span v-if="stage[0].home_score">{{ stage[0].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[0].away_score">{{ stage[0].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;"> </div>
          <div style="width:50px;">{{ +stage[0].home_score + (+(stage[1] && stage[1].home_score ? stage[1].home_score : 0)) }}</div>
        </div>
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <div style="width:200px;">{{ stage[0].competitor_2_name }}</div>
          <div style="width:50px;"></div>
          <div style="width:50px;">
            <span v-if="stage[1] && stage[1].home_score">{{ stage[1].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[1] && stage[1].away_score">{{ stage[1].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;">{{ +stage[0].away_score + (+(stage[1] && stage[1].away_score ? stage[1].away_score : 0)) }}</div>
        </div>
      </div>
    </v-col>
    </v-row>

    <v-row v-if="table_list['2']">
      <v-col cols="12"><h2 style="padding: 10px 30px;">2-й квалификационный раунд</h2></v-col>
      <v-col cols="8" style="padding:10px 30px;">
      <div style="width:100%; display:flex; align-items: center; color:#94999f; font-weight: 600; padding:10px; justify-content: space-between;">
        <div style="width:230px;">Команда</div>
        <div></div>
        <div style="width:50px;"> 1</div>
        <div style="width:50px;"> 2</div>
        <div style="width:50px;">Мячи</div>
      </div>
      <div v-for="(stage, index2) in table_list['2']" :key="`dd-${index2}-${stage.cup_round_id}`" style="border-top:1px solid #eee; width:100%;padding:10px; display:flex; flex-direction:column;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
          </div>
          <div style="width:200px;">{{ stage[0].competitor_1_name }}</div>
          <div style="width:50px;">
            <span v-if="stage[0].home_score">{{ stage[0].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[0].away_score">{{ stage[0].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;"> </div>
          <div style="width:50px;">{{ +stage[0].home_score + (+(stage[1] && stage[1].home_score ? stage[1].home_score : 0)) }}</div>
        </div>
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <div style="width:200px;">{{ stage[0].competitor_2_name }}</div>
          <div style="width:50px;"></div>
          <div style="width:50px;">
            <span v-if="stage[1] && stage[1].home_score">{{ stage[1].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[1] && stage[1].away_score">{{ stage[1].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;">{{ +stage[0].away_score + (+(stage[1] && stage[1].away_score ? stage[1].away_score : 0)) }}</div>
        </div>
      </div>
    </v-col>
    </v-row>

    <v-row v-if="table_list['3']">
      <v-col cols="12"><h2 style="padding: 10px 30px;">3-й квалификационный раунд</h2></v-col>
      <v-col cols="8" style="padding:10px 30px;">
      <div style="width:100%; display:flex; align-items: center; color:#94999f; font-weight: 600; padding:10px; justify-content: space-between;">
        <div style="width:230px;">Команда</div>
        <div></div>
        <div style="width:50px;"> 1</div>
        <div style="width:50px;"> 2</div>
        <div style="width:50px;">Мячи</div>
      </div>
      <div v-for="(stage, index2) in table_list['3']" :key="`dd-${index2}-${stage.cup_round_id}`" style="border-top:1px solid #eee; width:100%;padding:10px; display:flex; flex-direction:column;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
          </div>
          <div style="width:200px;">{{ stage[0].competitor_1_name }}</div>
          <div style="width:50px;">
            <span v-if="stage[0].home_score">{{ stage[0].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[0].away_score">{{ stage[0].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;"> </div>
          <div style="width:50px;">{{ +stage[0].home_score + (+(stage[1] && stage[1].home_score ? stage[1].home_score : 0)) }}</div>
        </div>
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <div style="width:200px;">{{ stage[0].competitor_2_name }}</div>
          <div style="width:50px;"></div>
          <div style="width:50px;">
            <span v-if="stage[1] && stage[1].home_score">{{ stage[1].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[1] && stage[1].away_score">{{ stage[1].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;">{{ +stage[0].away_score + (+(stage[1] && stage[1].away_score ? stage[1].away_score : 0)) }}</div>
        </div>
      </div>
    </v-col>
    </v-row>

    <v-row v-if="table_list['4']">
      <v-col cols="12"><h2 style="padding: 10px 30px;">Раунд плей-офф</h2></v-col>
      <v-col cols="8" style="padding:10px 30px;">
      <div style="width:100%; display:flex; align-items: center; color:#94999f; font-weight: 600; padding:10px; justify-content: space-between;">
        <div style="width:230px;">Команда</div>
        <div></div>
        <div style="width:50px;"> 1</div>
        <div style="width:50px;"> 2</div>
        <div style="width:50px;">Мячи</div>
      </div>
      <div v-for="(stage, index2) in table_list['4']" :key="`dd-${index2}-${stage.cup_round_id}`" style="border-top:1px solid #eee; width:100%;padding:10px; display:flex; flex-direction:column;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
          </div>
          <div style="width:200px;">{{ stage[0].competitor_1_name }}</div>
          <div style="width:50px;">
            <span v-if="stage[0].home_score">{{ stage[0].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[0].away_score">{{ stage[0].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;"> </div>
          <div style="width:50px;">{{ +stage[0].home_score + (+(stage[1] && stage[1].home_score ? stage[1].home_score : 0)) }}</div>
        </div>
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <div style="width:200px;">{{ stage[0].competitor_2_name }}</div>
          <div style="width:50px;"></div>
          <div style="width:50px;">
            <span v-if="stage[1] && stage[1].home_score">{{ stage[1].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[1] && stage[1].away_score">{{ stage[1].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;">{{ +stage[0].away_score + (+(stage[1] && stage[1].away_score ? stage[1].away_score : 0)) }}</div>
        </div>
      </div>
    </v-col>
    </v-row>

    <v-row v-if="table_list['playoff_round']">
      <v-col cols="12"><h2 style="padding: 10px 30px;">Раунд плей-офф</h2></v-col>
      <v-col cols="8" style="padding:10px 30px;">
      <div style="width:100%; display:flex; align-items: center; color:#94999f; font-weight: 600; padding:10px; justify-content: space-between;">
        <div style="width:230px;">Команда</div>
        <div></div>
        <div style="width:50px;"> 1</div>
        <div style="width:50px;"> 2</div>
        <div style="width:50px;">Мячи</div>
      </div>
      <div v-for="(stage, index2) in table_list['playoff_round']" :key="`dd-${index2}-${stage.cup_round_id}`" style="border-top:1px solid #eee; width:100%;padding:10px; display:flex; flex-direction:column;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
          </div>
          <div style="width:200px;">{{ stage[0].competitor_1_name }}</div>
          <div style="width:50px;">
            <span v-if="stage[0].home_score">{{ stage[0].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[0].away_score">{{ stage[0].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;"> </div>
          <div style="width:50px;">{{ +stage[0].home_score + (+(stage[1] && stage[1].home_score ? stage[1].home_score : 0)) }}</div>
        </div>
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div style="width:30px;">
            <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <div style="width:200px;">{{ stage[0].competitor_2_name }}</div>
          <div style="width:50px;"></div>
          <div style="width:50px;">
            <span v-if="stage[1] && stage[1].home_score">{{ stage[1].home_score }}</span>
            <span v-else>0</span>
            :
            <span v-if="stage[1] && stage[1].away_score">{{ stage[1].away_score }}</span>
            <span v-else>0</span>
          </div>
          <div style="width:50px;">{{ +stage[0].away_score + (+(stage[1] && stage[1].away_score ? stage[1].away_score : 0)) }}</div>
        </div>
      </div>
    </v-col>
    </v-row>

  </div>

</v-col>

<v-col
v-if="table_list && leagueContent == 'Group'"
      cols="12"
      style="padding: 0px;"
      >
<div v-for="(group,indexg) in table_list" :key="`group-${indexg}`" style="    padding: 15px;
    margin: 20px 0px;">

  <div style="margin:20px 0px; font-weight: 500; font-size: 18px;">Группа {{indexg}}</div>
  <v-data-table
  v-if="table_list && group"
        :headers="headers"
        :items="group"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        style="width:100%;"
        disable-pagination
      >
      <template v-slot:item.rank="{item}">
        <div v-if="item.rank < 3" style="background: #1D6ADC; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;" :id="'event-id-'+id">
            <span style="color:#fff; font-size:14px;"> {{ item.rank }}</span>
        </div>
        <div v-else-if="item.rank == 3" style="background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;" :id="'event-id-'+id">
            <span style="color:#fff; font-size:14px;"> {{ item.rank }}</span>
        </div>
        <!-- <div v-else-if="item.rank > 17" style="background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;" :id="'event-id-'+id">
            <span style="color:#fff; font-size:14px;"> {{ item.rank }}</span>
        </div> -->
        <div v-else style="width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;" :id="'event-id-'+id">
            <span style="color:#000; font-size:14px;"> {{ item.rank }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_name="{item}">
        <div style="display: flex; align-items: center;">
          <div style="width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;">
            <v-img v-if="item.competitor_logo && item.competitor_logo.length > 3" :src="item.competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
          </div>
          <span> {{ item.competitor_name }}</span>
       </div>
      </template>
      <template v-slot:item.game_count="{item}">
          <span style="text-align: center;"> {{ item.game_count }}</span>
      </template>
      <template v-slot:item.win_count="{item}">
          <span> {{ item.win_count }}</span>
      </template>
      <template v-slot:item.substitute="{item}">
          <span> {{ item.loose_count }}</span>
      </template>
      <template v-slot:item.loose_count="{item}">
          <span> {{ item.substitute }}</span>
      </template>
      <template v-slot:item.score_count="{item}">
          <span> {{ item.score_count }}</span>
      </template>
      <template v-slot:item.missed_count="{item}">
          <span> {{ item.missed_count }}</span>
      </template>
      <template v-slot:item.score_los_count="{item}">
          <span> {{ item.score_los_count }}</span>
      </template>
      <template v-slot:item.points="{item}">
          <span> {{ item.points }}</span>
      </template>
      <template v-slot:item.last_games="{item}">
        <div style="display: flex; align-items: center; justify-content: center;" >
        <div v-for="games in item.last_games" :key="`team-${games.id}`" :id="'event-id-'+id">
            <div v-if="games.winner_id && games.winner_id == item.competitor_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else-if="games.winner_id && games.winner_id != item.competitor_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else-if="!games.winner_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else style="margin-right:8px; cursor:pointer; background: #eee; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span></span>
            </div>
        </div>
      </div>
      </template>
    </v-data-table>
  </div>

</v-col>

<v-col
v-if="leagueContent == 'Playoff'"
      cols="12"
      style="display: flex; flex-direction: column; padding:0px 0px;"
      >

  <div class="" style="display:flex;align-items:center;width:100%; padding:8px 24px;border-bottom: 0.5px solid #E4E5E5;height: 36px; background-color: #F8F8F8;">
    <div v-if="table_list && table_list['round_of_16']" class="" style="width:25%; display: flex; justify-content: center;">1/8</div>
    <div v-if="table_list && table_list['quarterfinal']" class="" style="width:25%; display: flex; justify-content: center;">1/4</div>
    <div v-if="table_list && table_list['semifinal']" class="" style="width:25%; display: flex; justify-content: center;">1/2</div>
    <div v-if="table_list && table_list['final']" class="" style="width:25%; display: flex; justify-content: center;">Финал</div>
  </div>

  <div class="duda" style="display:flex; width:100%; padding:24px 0px; height:auto; ">

    <div v-if="table_list && table_list['round_of_16']" class="" style="width:25%; display: flex; align-items: center; padding: 0px 24px; flex-direction: column;">
     <!-- 1/8 -->
      <div style="display: flex; flex-direction: column; width:100%">
        <div v-for="(stage, index) in table_list['round_of_16']" :key="`row-${index}-${stage[0].cup_round_id}`" style="width:100%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px;  margin-bottom: 24px;">
            <!--v-list-->
            <!-- {{ stage[2] }} -->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;" :class="checkNumber(stage[2])">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_1_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].home_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].away_score }}</span>
              </div>
            </div>
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;" >
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_2_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[1].event_id, replace: true})">
                <span>{{ stage[0].away_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].home_score }}</span>
              </div>
            </div>

        </div>


      </div>


    </div>

    <div v-if="table_list && table_list['quarterfinal']" class="" style="width:25%; display: flex; align-items: center; padding: 0px 24px; flex-direction: column;">
     <!-- 1/4 -->
      <div style="display: flex; flex-direction: column; width:100%">
        <div v-for="(stage, index) in table_list['quarterfinal']" :key="`row-${index}-${stage[0].cup_round_id}`" 
        :style="'width:100%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px; margin-top:'+(getPos(table_list['quarterfinal']))*62+'px'">
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;" :class="checkNumber(stage[2],1)">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_1_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].home_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].away_score }}</span>
              </div>
            </div>
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_2_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>  
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[1].event_id, replace: true})">
                <span>{{ stage[0].away_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].home_score }}</span>
              </div>
            </div>

        </div>


      </div>
    </div>

    <div v-if="table_list && table_list['semifinal']" class="" style="width:25%; display: flex; align-items: center; padding: 0px 24px; flex-direction: column;">
     <!-- 1/2 -->
      <div style="display: flex; flex-direction: column; width:100%">
        <div v-for="(stage, index) in table_list['semifinal']" :key="`row-${index}-${stage.id}`" 
        :style="'width:100%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px; margin-top:'+(getPos2(table_list['semifinal']))*186+'px'">
                 <!--v-list-->
                 <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;" :class="checkNumber(stage[2],1,1)">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_1_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].home_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].away_score }}</span>
              </div>
            </div>
            <!--v-list-->
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_2_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_2_logo && stage[0].competitor_2_logo.length > 3" :src="stage[0].competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[1].event_id, replace: true})">
                <span>{{ stage[0].away_score }}</span>
                  <span style="padding-left:12px;">{{ stage[1].home_score }}</span>
              </div>
            </div>

        </div>


      </div>

    </div>

    <div v-if="table_list && table_list['final']" class="" style="width:25%; display: flex; align-items: center; padding: 0px 24px; flex-direction: column;">
     <!-- 1 -->
      <div style="display: flex; flex-direction: column; width:100%">
        <div v-for="(stage, index) in table_list['final']" :key="`row-${index}-${stage.id}`" 
        :style="'width:100%; display: flex; flex-direction: column; height: 84px; padding:12px; box-sizing: border-box; border: 1px solid #E4E5E5; border-radius: 8px; margin-top:372px'">
                       <!--v-list-->
                       <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_1_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_1_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].home_score }}</span>
              </div>
            </div>
            <div style="display: flex; width:100%; align-items: center; justify-content: space-between; margin-bottom:8px;">
              <div style="cursor:pointer;display: flex; width:100%; align-items: center;" @click="$router.push({path: '/team/'+stage[0].competitor_2_id, replace: true})">
                <div style="width:30px;">
                  <v-img v-if="stage[0].competitor_logo && stage[0].competitor_logo.length > 3" :src="stage[0].competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                    <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>    
                </div>
                <span style="padding-left:12px;">{{ stage[0].competitor_2_name }}</span>
              </div>

              <div style="cursor: pointer;" @click="$router.push({path: '/event/'+stage[0].event_id, replace: true})">
                <span>{{ stage[0].away_score }}</span>
              </div>
            </div>


        </div>


      </div>

    </div>


  </div>


</v-col>

</v-row>


</template>

<script>
import {mapState} from "vuex";
import {competitionsActionTypes} from '@/store/modules/competitions'
import {eventBus} from '@/main'

export default {
inject: {
    theme: {
      default: { isDark: false },
    },
  },
name: 'LeagueTable',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    leagueContent:'Qualify',
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    season_id:null,
    qpos:0,
    qpos2:0,
    qpos3:0,
    stages_list_semi_final: [
          {
            id:1,
            stage_type:'semi_final',
            stage_pairs: 
              {
                id:1,
                team_1_id: 'muid',
                team_2_id: 'chid',
                team_1_logo: 'ball.svg',
                team_2_logo: 'ball.svg',
                team_1_name: 'Манчестер Юнайтед',
                team_2_name: 'Челси',
                team_1_result_1: "1",
                team_1_result_2: "2",
                team_2_result_1: "4",
                team_2_result_2: "3",
              }
          },
          {
            id:2,
            stage_type:'semi_final',
            stage_pairs: 
              {
                id:1,
                team_1_id: 'mcid',
                team_2_id: 'arcid',
                team_1_logo: 'ball.svg',
                team_2_logo: 'ball.svg',
                team_1_name: 'Манчестер сити',
                team_2_name: 'Арсенал',
                team_1_result_1: "0",
                team_1_result_2: "1",
                team_2_result_1: "0",
                team_2_result_2: "1",
              }
          }
        ],
        stages_list_final: [
          {
            id:3,
            stage_type:'final',
            stage_pairs: 
              {
                id:1,
                team_1_id: 'mcid',
                team_2_id: '',
                team_1_logo: 'ball.svg',
                team_2_logo: 'ball.svg',
                team_1_name: 'Манчестер сити',
                team_2_name: '',
                team_1_result_1: "-",
                team_1_result_2: "",
                team_2_result_1: "",
                team_2_result_2: "",
              },
          }
        ],
    table_list: [],
  }
},
computed: {
  ...mapState({
    isLoading: state => state.competitions.isLoading,
    competitions: state => state.competitions.data,
    validationErrors: state => state.competitions.validationErrors,
  }),
  headers() {
    return [
      {
        text: '#',
        align: 'start',
        sortable: true,
        value: 'rank',
        width: '50px',
        style: 'display:flex'
      },
      {
        text: 'Команда',
        align: 'start',
        sortable: false,
        value: 'competitor_name',
        width: '27%'
      },
      {
        text: 'И',
        align: 'start',
        sortable: true,
        value: 'game_count',
        width: '40px'
      },
      {
        text: 'В',
        align: 'start',
        sortable: true,
        value: 'win_count',
        width: '40px'
      },
      {
        text: 'Н',
        align: 'start',
        sortable: true,
        value: 'substitute',
        width: '40px'
      },
      {
        text: 'П',
        align: 'start',
        sortable: true,
        value: 'loose_count',
        width: '40px'
      },
      {
        text: 'ЗМ',
        align: 'start',
        sortable: true,
        value: 'score_count',
        width: '40px'
      },
      {
        text: 'ПМ',
        align: 'start',
        sortable: true,
        value: 'missed_count',
        width: '40px'
      },
      {
        text: 'РМ',
        align: 'start',
        sortable: true,
        value: 'score_los_count',
        width: '40px'
      },
      {
        text: 'О',
        align: 'start',
        sortable: true,
        value: 'points',
        width: '40px'
      },
      {
        text: 'Последние 5',
        align: 'center',
        sortable: false,
        value: 'last_games',
        width: '200px'
      },
    ];

  },
},
watch: {
},
mounted() {
  console.log('UNIVERSAL')
  this.qpos = 0
  this.qpos2 = 0
  this.qpos3 = 0
  this.fetchFeed()
},
created () {
    eventBus.$on('gettable', data => {
      console.log('gettable '+data.season)
      localStorage.setItem('competitionSeason',data.season)
        this.fetchFeed(data,this.leagueContent)
    })
},
methods: {
    setEvent(league,event){

      var eventItem = {}
      eventItem.league_logo = league.event_logo
      eventItem.league_id = league.id
      eventItem.league_name = league.event_name.split(',')[1]
      eventItem.league_country = league.event_name.split(',')[0]
      eventItem.id = event.id
      eventItem.name = event.name
      eventItem.result = event.result
      eventItem.status = event.status
      eventItem.date = event.event_date
      eventItem.time = event.time_description
      eventItem.team_1_id = event.teams[0].id
      eventItem.team_1_name = event.teams[0].name
      eventItem.team_1_logo = event.teams[0].logo
      eventItem.team_1_result = event.teams[0].result 
      eventItem.team_1_result_1 = event.teams[0].result_1
      eventItem.team_2_id = event.teams[1].id
      eventItem.team_2_name = event.teams[1].name
      eventItem.team_2_logo = event.teams[1].logo
      eventItem.team_2_result = event.teams[1].result 
      eventItem.team_2_result_1 = event.teams[1].result_1
      console.log(eventItem)
      localStorage.setItem('localEvent',JSON.stringify(eventItem))
      if(this.$route.path.toString().split('ntnex').length > 1){
        this.$router.push({path: '/event/'+eventItem.id, replace: true})
      } else {
        this.$router.push({path: '/eventnext/'+eventItem.id, replace: true})
      }
    },
  setTeam(item,num){
    console.log(item['competitor_'+((num==2)?2+'_':1+'_')+'id'])
    console.log(item['competitor_'+((num==2)?2+'_':1+'_')+'name'])
    console.log(item['competitor_'+((num==2)?2+'_':'')+'logo'])

    let obj = {}
    obj.id = item['competitor_'+((num==2)?2+'_':1+'_')+'id']
    obj.name = item['competitor_'+((num==2)?2+'_':1+'_')+'name']
    obj.logo = item['competitor_'+((num==2)?2+'_':'')+'logo']

      localStorage.setItem('localTeam',JSON.stringify(obj))

      if(this.$route.path.toString().split('mnex').length > 1){
        this.$router.push({path: '/team/'+obj.id, replace: true})
      } else {
        this.$router.push({path: '/teamnext/'+obj.id, replace: true})
      }
  },
  checkNumber(item,item2,item3){
    console.log(item)
    return 'div_af'+item+(item2?item2:'')+(item3?item3:'')
  },
  getPos(obj){
    if(+this.qpos < Object.keys(obj).length){
      this.qpos++;
      return (this.qpos > 2) ? 2 : this.qpos;
    }
  },
  getPos2(obj){
    if(+this.qpos2 < Object.keys(obj).length){
      this.qpos2++;
      return (this.qpos2 > 1) ? 1.7 : this.qpos2;
    }
  },
  slideToggle(ind,id){
    console.log(ind)
    console.log(id)
    this.table_list[ind].show = this.table_list[ind].show ? false : true 
  },
  fetchFeed(season,type) {
    this.loading = true;
    this.qpos = 0
    this.qpos2 = 0
    this.qpos3 = 0
    console.log(season)
    console.log(type)
    this.table_list = []
    console.log('eeeeeee')
    console.log(localStorage.getItem('competitionSeason'))
    if(season){
      this.season_id = season.season
    } else {
      if(localStorage.getItem('competitionSeason')){
        this.season_id = localStorage.getItem('competitionSeason')

      }
    }
    var evv = JSON.parse(localStorage.getItem('localCompetition'))
      console.log(evv)
    if(!type || type == 'Qualify') {
      console.log('Qualify')
      this.$store.dispatch(competitionsActionTypes.getCompetitionsUniversalQual,{competition_id:evv.id,season_id:this.season_id ? this.season_id:null}).then((data) => {
        console.log(data)
        this.table_list = data.events_list
        this.loading = false;
      })
    } else if(type && type == 'Group') {
      this.$store.dispatch(competitionsActionTypes.getCompetitionsUniversal,{competition_id:evv.id,season_id:this.season_id ? this.season_id:null}).then((data) => {
        console.log(data)
        this.table_list = data.events_list
        this.loading = false;
      })
    } else if(type && type == 'Playoff') {
      console.log('Playoff')
      this.$store.dispatch(competitionsActionTypes.getCompetitionsUniversalPlayoff,{competition_id:evv.id,season_id:this.season_id ? this.season_id: null}).then((data) => {
        console.log(data)
        this.table_list = data.events_list
        this.loading = false;
      })
    }
  },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  background: #F8F8F8 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.v-text-field >>> .v-input__slot:after {
  display: none !important;
}

.v-data-table >>> span {
  font-size:14px;
}
.table_main_row {
  color:#333;
}
.table_main_row >>> span{
  /* color:#333; */
}
.v-data-table{
border-bottom: 0.5px solid #E4E5E5 !important;
box-shadow: none !important;
}
.tab_type>.item:hover {
  transition: 0.4s;
  background: #FCC341;
}
.tab_type>.active {
  background: #FCC341;
}
.div_af1,.div_af2,.div_af3,.div_af4,.div_af5,.div_af6,.div_af7,.div_af8,.div_af11,.div_af21,.div_af31,.div_af41,.div_af111,.div_af211 {
  position: relative;
}
.div_af1:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -85px;
    width: 74px;
    border-bottom: 0;
    top: 26px;
    height: 24px;
    z-index: 90;
}
.div_af2:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -85px;
    width: 74px;
    border-top: 0;
    top: 26px;
    height: 24px;
    z-index: 90;
}
.div_af3:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -85px;
    width: 74px;
    border-bottom: 0;
    top: 17px;
    height: 24px;
    z-index: 90;
}
.div_af4:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-top: 0;
    top: 17px;
    height: 24px;
    z-index: 90;
}
.div_af5:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-bottom: 0;
    top: 10px;
    height: 24px;
    z-index: 90;
}
.div_af6:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-top: 0;
    top: 7px;
    height: 24px;
    z-index: 90;
}
.div_af7:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-bottom: 0;
    top: 2px;
    height: 24px;
    z-index: 90;
}
.div_af8:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-top: 0;
    top: 0px;
    height: 24px;
    z-index: 90;
}

.div_af11:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    position: absolute;
    right: -85px;
    width: 74px;
    border-bottom: 0;
    top: 26px;
    height: 87px;
    z-index: 90;
}
.div_af21:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    right: -85px;
    width: 74px;
    border-top: 0;
    top: -13px;
    height: 57px;
    z-index: 90;
}
.div_af31:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    position: absolute;
    right: -85px;
    width: 74px;
    border-bottom: 0;
    top: 17px;
    height: 79px;
    z-index: 90;
}
.div_af41:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    position: absolute;
    right: -86px;
    width: 74px;
    border-top: 0;
    top: -29px;
    height: 57px;
    z-index: 90;
}

.div_af111:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    position: absolute;
    right: -86px;
    width: 74px;
    border-bottom: 0;
    top: 26px;
    height: 148px;
    z-index: 90;
}
.div_af211:after{
  border: 1px solid #d7d8dc;
    border-left: 0;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    right: -86px;
    width: 74px;
    border-top: 0;
    top: -143px;
    height: 172px;
    z-index: 90;
}
</style>

