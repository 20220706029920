<template>
 
  <v-row class="table_main_row" style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;"> Бомбардиры </span>
      </div>

    </v-col>
  <!-- </v-row> -->
  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5; padding: 0px;"
      >

    <v-data-table
        :headers="headers"
        :items="scorers_list"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        style="width:100%;"
      >
      <template v-slot:item.id="{item}">
            <span style="color:#333; font-size:14px;"> {{ item.id }}</span>
      </template>
      <template v-slot:item.name="{item}">
        <div style="display: flex; align-items: center;width:50%;">
          <div style="width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;">
            <v-img :src="require('@/assets/'+item.logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
          </div>
          <span> {{ item.name }}</span>
       </div>
      </template>
      <template v-slot:item.team_name="{item}">
        <div style="display: flex; align-items: center;width:50%;" @click="$router.push({path: '/team/'+item.team_id, replace: true})">
          <div style="width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;">
            <v-img :src="require('@/assets/'+item.team_logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
          </div>
          <span> {{ item.team_name }}</span>
       </div>
      </template>
      <template v-slot:item.goals="{item}">
          <span> {{ item.goals }}</span>
      </template>
      <template v-slot:item.asists="{item}">
          <span> {{ item.asists }}</span>
      </template>
    </v-data-table>

</v-col>
</v-row>
</template>

<script>
import {mapState} from "vuex";

export default {
inject: {
    theme: {
      default: { isDark: false },
    },
  },
name: 'LeagueScorers',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    scorers_list: [
        {
              id:1,
              scorer_id:'h1',
              name:'Холанд',
              logo:'flags/england.png',
              team_id:'sr:competitor:7',
              team_logo:'teams/mancity.png',
              team_name:'Мансечтер Сити',
              goals:21,
              asists:21,
            },
            {
              id:2,
              scorer_id:'h1',
              name:'Холанд',
              logo:'flags/england.png',
              team_id:'sr:competitor:7',
              team_logo:'teams/mancity.png',
              team_name:'Мансечтер Сити',
              goals:18,
              asists:20,
            },
            {
              id:3,
              scorer_id:'h1',
              name:'Холанд',
              logo:'flags/england.png',
              team_id:'manc1',
              team_logo:'teams/mancity.png',
              team_name:'Мансечтер Сити',
              goals:11,
              asists:15,
            },

          ]
  }
},
computed: {
  ...mapState({
    isLoading: state => state.events_list.isLoading,
    events_list: state => state.events_list.data,
    validationErrors: state => state.events_list.validationErrors,
  }),
  headers() {
    return [
      {
        text: '#',
        align: 'start',
        sortable: true,
        value: 'id',
        width: '50px'
      },
      {
        text: 'Игроки',
        align: 'start',
        sortable: false,
        value: 'name',
        width: '200px'
      },
      {
        text: 'Команды',
        align: 'start',
        sortable: true,
        value: 'team_name',
        width: '200px'
      },
      {
        text: 'Г',
        align: 'start',
        sortable: true,
        value: 'goals',
        width: '40px'
      },
      {
        text: 'П',
        align: 'start',
        sortable: true,
        value: 'asists',
        width: '40px'
      }
    ];

  },
},
watch: {
},
mounted() {
  this.fetchFeed()
},
methods: {
  // slideToggle(ind,id){
  //   this.scorers_list[ind].show = this.scorers_list[ind].show ? false : true 
  // },
  fetchFeed() {
    //this.$store.dispatch(actionTypes.getInfo)
  },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  background: #F8F8F8 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.v-data-table >>> span {
  font-size:14px;
}
.table_main_row {
  color:#333;
}
.table_main_row >>> span{
  /* color:#333; */
}
.v-data-table{
border-bottom: 0.5px solid #E4E5E5 !important;
box-shadow: none !important;
}
</style>

