import Vue from 'vue'
import VueRouter from 'vue-router'
//import Login from "@/views/Login"
import Main from '@/views/Main'
import League from '@/views/League/League'
import LeagueNext from '@/views/LeagueNext/LeagueNext'
import Event from '@/views/Event/Event'
import EventNext from '@/views/Event/EventNext'
import Team from '@/views/Team/Team'
import TeamNext from '@/views/Team/TeamNext'

Vue.use(VueRouter)

const routes = [
  {
    path: '/logout',
    name: 'logout',
    meta: { logout: true }
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/league/:slug',
    name: 'League',
    component: League,
    meta: { requiresAuth: false }
  },
  {
    path: '/leaguenext/:slug',
    name: 'LeagueNext',
    component: LeagueNext,
    meta: { requiresAuth: false }
  },
  {
    path: '/event/:slug',
    name: 'Event',
    component: Event,
    meta: { requiresAuth: false }
  },
  {
    path: '/eventnext/:slug',
    name: 'EventNext',
    component: EventNext,
    meta: { requiresAuth: false }
  },
  {
    path: '/team/:slug',
    name: 'Team',
    component: Team,
    meta: { requiresAuth: false }
  },
  {
    path: '/teamnext/:slug',
    name: 'TeamNext',
    component: TeamNext,
    meta: { requiresAuth: false }
  },

]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

let isFirstTransition = true;

router.afterEach(to => {
  localStorage.setItem('LS_ROUTE_KEY', to.path);
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {

  //   if (localStorage.getItem('type')) {
  //     store.commit('setUserType', localStorage.getItem('type'));
  //   }

  //   if (localStorage.getItem('accessToken')) {
  //     console.log('routing1')
  //     store.commit('setUserInterface', defData);
  //     next()
  //   } else {
  //     console.log('routing1111111111')
  //     next({ path: '/' })
  //   }
  // } else if (to.matched.some(record => record.meta.logout)) {
  //   // Remove bearer token from localStorage
  //   //localStorage.clear();
  //   //store.commit('unSetUserInterface')
  //   console.log('routing2222222222')
  //   next({
  //     path: '/login'
  //   })
  // } else {
  //   console.log('routing333333333333333')
  //   console.log(to)
  //   console.log(from)
  //   if(from.fullPath == '/' && to.fullPath && to.fullPath != '/main'){
  //     next({
  //       path: to.fullPath
  //     })
  //   } else {
  //     next()
  //   }
    
  // }

  const lastRouteName = localStorage.getItem('LS_ROUTE_KEY');

  
  console.log(lastRouteName)

  // const shouldRedirect = Boolean(
  //   to.name === "Main"
  //   && lastRouteName
  //   && isFirstTransition
  // );

  
  //console.log(shouldRedirect)

  if (isFirstTransition && lastRouteName && lastRouteName != '/main'){
    console.log('----------------')
    console.log(isFirstTransition)
    console.log(lastRouteName)
    console.log(to.path)
    console.log('----------------')
    isFirstTransition = false;
    next({ path: lastRouteName });
  } else {
    console.log('---1-------')
    console.log(isFirstTransition)
    console.log(lastRouteName)
    console.log(to.path)
    console.log('---2-------')
    isFirstTransition = false;
    next();
  }

  

})

export default router
