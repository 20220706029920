<template>
 
  <v-row class="table_main_row" style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;"> Турнирная таблица </span>
      </div>

    </v-col>
  <!-- </v-row> -->
  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5; padding: 0px;"
      >

    <v-data-table
        :headers="headers"
        :items="table_list"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        style="width:100%;"
        disable-pagination
      >

      <template v-slot:item.rank="{item}">
        <div :style="`${checkRank(item)}width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;`" :id="'event-id-'+id">
            <span style="font-size:14px;"> {{ item.rank }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_name="{item}" >
        <div style="display: flex; align-items: center; position: relative;" >
          <div style="width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;    align-items: center;
    justify-content: center;">
            <v-img v-if="item.competitor_logo && item.competitor_logo.length > 0" :src="item.competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
          </div>
          <span> {{ item.competitor_name }}</span>

          <div v-bind:class="{ highlited: (team_1_id == item.competitor_id || team_2_id == item.competitor_id) }" style="position: absolute;height: 122%;
    width: 408%;
    top: -11%;
    left: -30%;
    opacity: 0.3;"></div>
       </div>
      
      </template>
      <template v-slot:item.game_count="{item}">
          <span style="text-align: center;"> {{ item.game_count }}</span>
      </template>
      <template v-slot:item.win_count="{item}">
          <span> {{ item.win_count }}</span>
      </template>
      <template v-slot:item.substitute="{item}">
          <span> {{ item.loose_count }}</span>
      </template>
      <template v-slot:item.loose_count="{item}">
          <span> {{ item.substitute }}</span>
      </template>
      <template v-slot:item.score_count="{item}">
          <span> {{ item.score_count }}</span>
      </template>
      <template v-slot:item.missed_count="{item}">
          <span> {{ item.missed_count }}</span>
      </template>
      <template v-slot:item.score_los_count="{item}">
          <span> {{ item.score_los_count }}</span>
      </template>
      <template v-slot:item.points="{item}">
          <span> {{ item.points }}</span>
      </template>
      <template v-slot:item.last_games="{item}">
        <div style="display: flex; align-items: center; justify-content: center;" >
        <div v-for="(games,ind) in item.last_games" :key="`team-${ind}`" :id="'event-id-'+id">
            <div v-if="games.winner_id && games.winner_id == item.competitor_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else-if="games.winner_id && games.winner_id != item.competitor_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else-if="!games.winner_id" @mouseover="games.show_hidden = false" @mouseleave="games.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!games.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 100; transition:0.3s; top:-59px; left:-220px; width:236px; min-height:58px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ games.home_score }} : {{ games.away_score }}</span>
                  <span>( {{ games.competitor_1_name }} - {{ games.competitor_2_name }} )</span>
                </div>
                <span>{{ games.date }}</span>
              </div>
            </div>
            <div v-else style="margin-right:8px; cursor:pointer; background: #eee; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span></span>
            </div>
        </div>
      </div>
      </template>
    </v-data-table>

</v-col>

<v-col
      cols="12"
      style="display: flex; flex-direction: column;"
      >
  <div v-if="checkranktext()">
  <div style="display:flex;align-items:center;margin-top: 12px;" >
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #1D6ADC;"></div>
    <span style="padding-left:12px; font-size: 12px;">Проход дальше - Лига чемпионов (Групповой этап)</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" >
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #E46E00;"></div>
    <span style="padding-left:12px; font-size: 12px;">Проход дальше - Лига Европы (Групповой этап)</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" >
    <div style="width:16px;height:16px; border-radius: 4px; background-color: rgb(1 156 26);"></div>
    <span style="padding-left:12px; font-size: 12px;">Проход дальше - Лига Конференций (Групповой этап)</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" v-if="league_id_def == 'sr:competition:17'">
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Зона вылета - Чемпионшип</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" v-else-if="league_id_def == 'sr:competition:8'">
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Вылет в Сегунду</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" v-else-if="league_id_def == 'sr:competition:35'">
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Вылет в Бундеслигу 2</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" v-else-if="league_id_def == 'sr:competition:34'">
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Вылет в Лигу 2</span>
  </div>
  <div style="display:flex;align-items:center;margin-top: 12px;" v-else-if="league_id_def == 'sr:competition:23'">
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Вылет в Серию В</span>
  </div>
</div>

<div v-else>
<div style="display:flex;align-items:center;margin-top: 12px;" >
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #E46E00;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Стыковые матчи с командами, занявшими 3-е и 4-е места в ФНЛ</span>
  </div>

  <div style="display:flex;align-items:center;margin-top: 12px;" >
    <div style="width:16px;height:16px; border-radius: 4px; background-color: #C70000;"></div>
    <span style="padding-left:12px;  font-size: 12px;">Вылет в ФНЛ</span>
  </div>
</div>

  <p style="font-size: 12px; color:#838383; margin-top:28px;">Если команды наберут одинаковое количество очков, то места в таблице будут определены по разнице мячей.</p>

</v-col>

</v-row>


</template>

<script>
import {mapState} from "vuex";
import {competitionsActionTypes} from '@/store/modules/competitions'
import {eventBus} from '@/main'

export default {
inject: {
    theme: {
      default: { isDark: false },
    },
  },
name: 'LeagueTable',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    table_list: [],
    league_id_def:null,
    team_1_id:null,
    team_2_id:null
  }
},
computed: {
  ...mapState({
    isLoading: state => state.competitions.isLoading,
    competitions: state => state.competitions.data,
    validationErrors: state => state.competitions.validationErrors,
  }),
  // computedStyle() { 
  //   return {
  //     'background-color': '#fff'
  //   }
  // },
  headers() {
    return [
      {
        text: '#',
        align: 'start',
        sortable: true,
        value: 'rank',
        width: '50px',
        style: 'display:flex'
      },
      {
        text: 'Команда',
        align: 'start',
        sortable: false,
        value: 'competitor_name',
        width: '27%'
      },
      {
        text: 'И',
        align: 'start',
        sortable: true,
        value: 'game_count',
        width: '40px'
      },
      {
        text: 'В',
        align: 'start',
        sortable: true,
        value: 'win_count',
        width: '40px'
      },
      {
        text: 'Н',
        align: 'start',
        sortable: true,
        value: 'loose_count',
        width: '40px'
      },
      {
        text: 'П',
        align: 'start',
        sortable: true,
        value: 'substitute',
        width: '40px'
      },
      {
        text: 'ЗМ',
        align: 'start',
        sortable: true,
        value: 'score_count',
        width: '40px'
      },
      {
        text: 'ПМ',
        align: 'start',
        sortable: true,
        value: 'missed_count',
        width: '40px'
      },
      {
        text: 'РМ',
        align: 'start',
        sortable: true,
        value: 'score_los_count',
        width: '40px'
      },
      {
        text: 'О',
        align: 'start',
        sortable: true,
        value: 'points',
        width: '40px'
      },
      {
        text: 'Последние 5',
        align: 'center',
        sortable: false,
        value: 'last_games',
        width: '200px'
      },
    ];

  },
},
watch: {
},
mounted() {
  console.log('NATIONAL')
  this.league_id_def = JSON.parse(localStorage.getItem('localCompetition')).id

  if(JSON.parse(localStorage.getItem('localEvent'))){
    this.team_1_id = JSON.parse(localStorage.getItem('localEvent')).team_1_id ? JSON.parse(localStorage.getItem('localEvent')).team_1_id : null
    this.team_2_id = JSON.parse(localStorage.getItem('localEvent')).team_2_id ? JSON.parse(localStorage.getItem('localEvent')).team_2_id : null
  }

  this.fetchFeed()
},
created () {
    eventBus.$on('gettablenational', data => {
      console.log('gettablenational '+data)
        this.fetchFeed(data)
    })
},
methods: {
  checkranktext(){
    let res = true
    let league_id = JSON.parse(localStorage.getItem('localCompetition')).id
    if(league_id && league_id === 'sr:competition:203'){
      console.log('ASASSAAS')
      res = false
    } else if(league_id) {
      console.log('ASASSAAS2')
      res = true
    } else {
      console.log('ASASSAAS3')
    }

    return res
  },
  checkRank(item){
    // #1D6ADC
    // #E46E00
    //   #C70000 
    let bgColor = 'transparent'
    let textColor = '#333'
    let league_id = JSON.parse(localStorage.getItem('localCompetition')).id
    if(league_id == 'sr:competition:17'){
      if(item.rank < 5) {
        bgColor = '#1D6ADC'
        textColor = '#fff'
      } else if(item.rank < 7) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank < 8) {
        bgColor = 'rgb(1 156 26)'
        textColor = '#fff'
      } else if(item.rank < 21 && item.rank > 17) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    }else if(league_id == 'sr:competition:8'){
      if(item.rank < 5) {
        bgColor = '#1D6ADC'
        textColor = '#fff'
      } else if(item.rank < 7) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank < 8) {
        bgColor = 'rgb(1 156 26)'
        textColor = '#fff'
      } else if(item.rank < 21 && item.rank > 17) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    } else if(league_id == 'sr:competition:23'){
      if(item.rank < 5) {
        bgColor = '#1D6ADC'
        textColor = '#fff'
      } else if(item.rank < 7) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank < 8) {
        bgColor = 'rgb(1 156 26)'
        textColor = '#fff'
      } else if(item.rank < 21 && item.rank > 17) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    }else if(league_id == 'sr:competition:35'){
      if(item.rank < 5) {
        bgColor = '#1D6ADC'
        textColor = '#fff'
      } else if(item.rank == 5) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank == 6) {
        bgColor = 'rgb(1 156 26)'
        textColor = '#fff'
      } else if(item.rank == 16) {
        bgColor = '#f44336'
        textColor = '#fff'
      } else if(item.rank < 19 && item.rank > 16) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    }else if(league_id == 'sr:competition:34'){
      if(item.rank < 4) {
        bgColor = '#1D6ADC'
        textColor = '#fff'
      } else if(item.rank == 4) {
        bgColor = '#03a9f4'
        textColor = '#fff'
      } else if(item.rank == 5) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank == 6) {
        bgColor = 'rgb(1 156 26)'
        textColor = '#fff'
      } else if(item.rank == 16) {
        bgColor = '#f44336'
        textColor = '#fff'
      } else if(item.rank < 19 && item.rank > 16) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    }else if(league_id == 'sr:competition:203'){

      if(item.rank < 15 && item.rank > 12) {
        bgColor = '#E46E00'
        textColor = '#fff'
      } else if(item.rank < 17 && item.rank > 14) {
        bgColor = '#C70000'
        textColor = '#fff'
      } else {
        textColor = '#333'
      }
    }
    
    return 'background:'+bgColor+';color:'+textColor+';'
  },
  slideToggle(ind,id){
    console.log(ind)
    console.log(id)
    this.table_list[ind].show = this.table_list[ind].show ? false : true 
  },
  fetchFeed(season) {
    var evv = JSON.parse(localStorage.getItem('localCompetition'))
      console.log(evv)
      this.$store.dispatch(competitionsActionTypes.getCompetitions,{competition_id:evv.id,season_id:season ? season.season : null}).then((data) => {
        console.log(data)
        this.table_list = data.events_list
        this.loading = false;
      })
  },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  background: #F8F8F8 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.v-data-table >>> span {
  font-size:14px;
}
.table_main_row {
  color:#333;
}
.table_main_row >>> span{
  /* color:#333; */
}
.v-data-table{
border-bottom: 0.5px solid #E4E5E5 !important;
box-shadow: none !important;
}
.tab_type>.item:hover {
  transition: 0.4s;
  background: #FCC341;
}
.tab_type>.active {
  background: #FCC341;
}

.highlited {
  background: #fce2ab;
}
</style>

