import axios from 'axios'
import apiConfig from '../config/config.json'

axios.defaults.headers.get['header-name'] = 'value'
axios.defaults.baseURL = apiConfig.apiHost

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// axios.interceptors.request.use(config => {
//     // const token = getItem('accessToken')
//     // const authorizisationToken = token ? `Bearer ${token}` : ''
//     // config.headers.Authorization = authorizisationToken
//     return config
// })


export default axios
