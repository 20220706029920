import authApi from '@/api/auth'
import {setItem} from '@/helpers/persistanceStorage'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  currentUser: null,
  validationErrors: null,
  user: null
}

export const mutationTypes = {
  loginStart: '[auth] Login start',
  loginSuccess: '[auth] Login success',
  loginFailure: '[auth] Login failure',

  getCurrentUserStart: '[auth] Get current user start',
  getCurrentUserSuccess: '[auth] Get current user success',
  getCurrentUserFailure: '[auth] Get current user failure',

  
  authentificationStart: '[auth] Athentification start',
  authentificationSuccess: '[auth] Athentification success',
  authentificationFailure: '[auth] Athentification failure',
}

export const actionTypes = {
  login: '[auth] Login',
  getCurrentUser: '[auth] Get current user',
  authentification: '[auth] athentification',
}

export const getterTypes = {
  currentUser: '[auth] currentUser',
  isLoggedIn: '[auth] isLoggedIn',
  isAnonymous: '[auth] isAnonymous'
}

const getters = {
  [getterTypes.currentUser]: state => {
    return state.currentUser
  },
  [getterTypes.isLoggedIn]: state => {
    return Boolean(state.isLoggedIn)
  },
  [getterTypes.isAnonymous]: state => {
    return state.isLoggedIn === false
  }
}

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.currentUser = payload
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.authentificationStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.authentificationSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.user = {...payload.find(item => item.id === 106)}
  },
  [mutationTypes.authentificationFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  }
}

const actions = {
  [actionTypes.login](context, credentials) {
    return new Promise(resolve => {
      context.commit(mutationTypes.loginStart)
      authApi
        .login(credentials)
        .then(response => {
          context.commit(mutationTypes.loginSuccess, response.data.user)
          setItem('accessToken', response.data.token)
          setItem('type', response.data.type)
          resolve(response.data.user)
        })
          .catch(result => {
            console.log('catch')
            setItem('accessToken', 1)
            setItem('type', 1)
            resolve([result])
            // context.commit(
            //     mutationTypes.loginFailure,
            //     result.response.data.error_description
            // )
          })
    })
  },
  [actionTypes.authentification](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.authentificationStart)
      authApi
        .authentification()
        .then(response => {
          context.commit(mutationTypes.authentificationSuccess, response.data)
          resolve(response.data)
        })
   /*       .catch(result => {
            context.commit(
                mutationTypes.loginFailure,
                result.response.data.error_description
            )
          })*/
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
