<template>
 
    <v-row style="background-color: #fff; border-radius: 8px;">
      <v-col
        class="d-flex"
        cols="12"
        style="align-items:center; height: 56px;"
      >
        <div style="display: flex; align-items: center; ">
          <span style="font-size: 22px; font-weight: 500; padding-left: 12px;">Обзор </span>
        </div>

        <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>
      </v-col>
    <!-- </v-row> -->
    <v-col
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        >
    <v-row>

      <v-col
        class="d-flex"
        cols="12"
        style="cursor: pointer; background-color:#F8F8F8; "
        
      >
        <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
          <div style="display:flex; align-items:center;">
            <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">1 тайм</span>
          </div>
        </div>
      </v-col>

      <v-col
        class="d-flex"
        cols="12"
        style="border-top:0.5px solid #E4E5E5; "
        
      >
        <div style="display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 12px; ">
    
            <div v-if="events_list && events_list.half_1" style="display:flex;  width:100%; flex-direction: column;">

              <div v-for="(stage, index) in events_list.half_1" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 32px;">

                <!-- <div v-if="stage && stage.type == 'period_start'" style="padding:3px; font-size:14px; ">
                  <div>Начало периода</div>
                  <div></div>
                </div> -->

                <div v-if="stage.competitor && stage.competitor == 'home'" class="timeline_item" style="">
                  <div v-if="stage.type == 'yellow_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/yellow_card.svg')" class="" alt="" title="предупреждение" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    <!-- <div style="margin-left:12px;">Нарушение</div> -->

                    <!-- <div v-if="stage.commentaries && stage.commentaries[0].text" style="margin:0px 10px; width:250px">{{ stage.commentaries[0].text }}</div> -->

                  </div>

                  <div v-if="stage.type == 'red_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/line_red_card.svg')" class="" alt="" title="удаление" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    <!-- <div style="margin-left:12px;">Грубое нарушение</div> -->

                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>

                  <div v-if="stage.type == 'score_change'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    <div style="margin-left:12px;">{{ stage.home_score }} : {{ stage.away_score }} </div>
                    <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/line_ball.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    <div style="margin-left:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>

                  <div v-if="stage.type == 'injury'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    
                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/injury.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                    <div style="margin-left:12px;">Травма</div>

                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>

                  <div v-if="stage.type == 'video_assistant_referee'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    <div style="width:60px; margin-left:12px;">
                      <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                                  min-height="36"></v-img>
                    </div>

                    <div v-if="stage.description">
                      <span v-if="stage.description == 'penalty'"> Возможно пенальти</span>
                      <span v-else>{{ stage.description }}</span>
                    </div>
                    <div v-else>Проверка ВАР</div>


                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>

                  <div v-if="stage.type == 'video_assistant_referee_over'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                  <div>{{ stage.match_time }}'</div>
                  <div style="width:60px; margin-left:12px;">
                    <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                                min-height="36"></v-img>
                  </div>

                  <div v-if="stage.description">
                    <span v-if="stage.description == 'no_penalty'"> Нет пенальти</span>
                    <span v-else>{{ stage.description }}</span>
                  </div>
                  <div v-else>Проверка ВАР</div>

                  <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>


                  <div v-if="stage.type == 'penalty_awarded'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>

                    <!-- <div style="margin-left:12px; width:20px; height: 20px; display: flex; align-items: center;justify-content:center; color:#DC362E; border:1px solid #DC362E; font-size:12px;">11</div> -->
                    <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/penalty.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    

                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>

                  <div v-if="stage.type == 'substitution'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                    <div>{{ stage.match_time }}'</div>
                    <div style="width:60px; margin-left:12px;">
                      <v-img :src="require('@/assets/subst.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>

                    <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                    <div style="margin-left:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                    <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                  </div>


                  <div></div>

                </div>

                <div v-else-if="stage.competitor && stage.competitor == 'away'" class="timeline_item" style="padding:3px; font-size:14px; ">
                  <div></div>
                    <div v-if="stage.type == 'yellow_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <!-- <div v-if="stage.commentaries && stage.commentaries[0].text" style="margin:0px 10px; width:250px">{{ stage.commentaries[0].text }}</div> -->

                      <!-- <div style="margin-right:12px;">Нарушение</div> -->

                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                      <div style="width:20px; margin-right:12px;">
                        <v-img :src="require('@/assets/yellow_card.svg')" class="" alt="" title="предупреждение" contain  max-height="24"
                                    min-height="24"></v-img>
                      </div>

                      <div>{{ stage.match_time }}'</div>

                    

                    </div>

                    <div v-if="stage.type == 'red_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">
                      
                      
                      <!-- <div style="margin-right:12px;">Грубое нарушение</div> -->

                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                      <div style="width:20px; margin-right:12px;">
                        <v-img :src="require('@/assets/line_red_card.svg')" class="" alt="" title="удаление" contain  max-height="24"
                                    min-height="24"></v-img>
                      </div>

                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'score_change'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      
                      <div style="margin-right:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                      
                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                      
                      <div style="width:20px; margin-right:12px;">
                        <v-img :src="require('@/assets/line_ball.svg')" class="" alt="" title="гол" contain  max-height="24"
                                    min-height="24"></v-img>
                      </div>

                      <div style="margin-right:12px;">{{ stage.home_score }} : {{ stage.away_score }} </div>

                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'injury'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <div style="margin-right:12px;">Травма</div>

                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                      <div style="width:20px; margin-right:12px;">
                      <v-img :src="require('@/assets/injury.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                      </div>
                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'video_assistant_referee'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <div v-if="stage.description">
                        <span v-if="stage.description == 'penalty'"> Возможно пенальти</span>
                        <span v-else>{{ stage.description }}</span>
                      </div>
                      <div v-else>Проверка ВАР</div>

                      <div style="width:60px; margin-right:12px;">
                        <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                                    min-height="36"></v-img>
                      </div>

                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'video_assistant_referee_over'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <div v-if="stage.description">
                        <span v-if="stage.description == 'no_penalty'"> Нет пенальти</span>
                        <span v-else>{{ stage.description }}</span>
                      </div>
                      <div v-else>Проверка ВАР</div>

                      <div>{{ stage.match_time }}'</div>
                      <div style="width:60px; margin-right:12px;">
                        <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                                    min-height="36"></v-img>
                      </div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'penalty_awarded'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                      <!-- <div style="margin-right:12px; width:20px; height: 20px; display: flex; align-items: center;justify-content:center; color:#DC362E; border:1px solid #DC362E; font-size:12px;">11</div> -->

                      <div style="width:20px; margin-right:12px;">
                      <v-img :src="require('@/assets/penalty.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                      </div>

                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>

                    <div v-if="stage.type == 'substitution'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                      <div style="margin-right:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                      <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                      <div style="width:60px; margin-right:12px;">
                        <v-img :src="require('@/assets/subst.svg')" class="" alt="" title="гол" contain  max-height="24"
                                    min-height="24"></v-img>
                      </div>

                      <div>{{ stage.match_time }}'</div>

                      <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

                    </div>
                </div>

                <!-- <div v-if="stage && stage.type == 'period_score'" style="padding:3px; font-size:14px; ">
                  <div>Конец периода</div>
                  <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>
                </div> -->

              </div>
            </div>

        </div>

      </v-col>

      <v-col
        class="d-flex"
        cols="12"
        style="cursor: pointer; background-color:#F8F8F8; "
        
      >
        <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
          <div style="display:flex; align-items:center;">
            <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">2 тайм</span>
          </div>
        </div>
      </v-col>

      <v-col
        class="d-flex"
        cols="12"
        style=""
      >
      <div v-if="events_list && events_list.half_2" style="display:flex;  width:100%; flex-direction: column;">

        <div v-for="(stage, index) in events_list.half_2" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px;">

          <!-- <div v-if="stage && stage.type == 'period_start'" style="padding:3px; font-size:14px; ">
            <div>Начало периода</div>
            <div></div>
          </div> -->

          <div v-if="stage.competitor && stage.competitor == 'home'" class="timeline_item" style="">
            <div v-if="stage.type == 'yellow_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              <div style="width:20px; margin-left:12px;">
                <v-img :src="require('@/assets/yellow_card.svg')" class="" alt="" title="предупреждение" contain  max-height="24"
                            min-height="24"></v-img>
              </div>
              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              <!-- <div style="margin-left:12px;">Нарушение</div> -->

              <!-- <div v-if="stage.commentaries && stage.commentaries[0].text" style="margin:0px 10px; width:250px">{{ stage.commentaries[0].text }}</div> -->

            </div>

            <div v-if="stage.type == 'red_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              <div style="width:20px; margin-left:12px;">
                <v-img :src="require('@/assets/line_red_card.svg')" class="" alt="" title="удаление" contain  max-height="24"
                            min-height="24"></v-img>
              </div>
              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              <!-- <div style="margin-left:12px;">Грубое нарушение</div> -->

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'score_change'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              <div style="margin-left:12px;">{{ stage.home_score }} : {{ stage.away_score }} </div>
              <div style="width:20px; margin-left:12px;">
                <v-img :src="require('@/assets/line_ball.svg')" class="" alt="" title="гол" contain  max-height="24"
                            min-height="24"></v-img>
              </div>
              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              <div style="margin-left:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'injury'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              
              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/injury.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
              <div style="margin-left:12px;">Травма</div>

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'video_assistant_referee'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              <div style="width:60px; margin-left:12px;">
                <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                            min-height="36"></v-img>
              </div>

              <div v-if="stage.description">
                      <span v-if="stage.description == 'penalty'"> Возможно пенальти</span>
                      <span v-else>{{ stage.description }}</span>
                    </div>
                    <div v-else>Проверка ВАР</div>

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'video_assistant_referee_over'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div>{{ stage.match_time }}'</div>

                <div style="width:60px; margin-left:12px;">
                  <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                              min-height="36"></v-img>
                </div>

                <div v-if="stage.description">
                  <span v-if="stage.description == 'no_penalty'"> Нет пенальти</span>
                  <span v-else>{{ stage.description }}</span>
                </div>
                <div v-else>Проверка ВАР</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'penalty_awarded'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>

              <!-- <div style="margin-left:12px; width:20px; height: 20px; display: flex; align-items: center;justify-content:center; color:#DC362E; border:1px solid #DC362E; font-size:12px;">11</div> -->
              <div style="width:20px; margin-left:12px;">
                      <v-img :src="require('@/assets/penalty.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>

            <div v-if="stage.type == 'substitution'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

              <div>{{ stage.match_time }}'</div>
              <div style="width:60px; margin-left:12px;">
                <v-img :src="require('@/assets/subst.svg')" class="" alt="" title="гол" contain  max-height="24"
                            min-height="24"></v-img>
              </div>

              <div style="color:#333;font-weight: 500;margin-left:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
              <div style="margin-left:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

            </div>


            <div></div>

          </div>

          <div v-else-if="stage.competitor && stage.competitor == 'away'" class="timeline_item" style="padding:3px; font-size:14px; ">
            <div></div>
              <div v-if="stage.type == 'yellow_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <!-- <div v-if="stage.commentaries && stage.commentaries[0].text" style="margin:0px 10px; width:250px">{{ stage.commentaries[0].text }}</div> -->

                <!-- <div style="margin-right:12px;">Нарушение</div> -->

                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                <div style="width:20px; margin-right:12px;">
                  <v-img :src="require('@/assets/yellow_card.svg')" class="" alt="" title="предупреждение" contain  max-height="24"
                              min-height="24"></v-img>
                </div>

                <div>{{ stage.match_time }}'</div>

                

              </div>

              <div v-if="stage.type == 'red_card'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">
                
                
                <!-- <div style="margin-right:12px;">Грубое нарушение</div> -->

                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                <div style="width:20px; margin-right:12px;">
                  <v-img :src="require('@/assets/line_red_card.svg')" class="" alt="" title="удаление" contain  max-height="24"
                              min-height="24"></v-img>
                </div>

                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'score_change'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                
                <div style="margin-right:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                
                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>
                
                <div style="width:20px; margin-right:12px;">
                  <v-img :src="require('@/assets/line_ball.svg')" class="" alt="" title="гол" contain  max-height="24"
                              min-height="24"></v-img>
                </div>

                <div style="margin-right:12px;">{{ stage.home_score }} : {{ stage.away_score }} </div>

                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'injury'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div style="margin-right:12px;">Травма</div>
                <div style="width:20px; margin-right:12px;">
                      <v-img :src="require('@/assets/injury.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'video_assistant_referee'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div v-if="stage.description">
                      <span v-if="stage.description == 'penalty'"> Возможно пенальти</span>
                      <span v-else>{{ stage.description }}</span>
                    </div>
                    <div v-else>Проверка ВАР</div>

                <div style="width:60px; margin-right:12px;">
                  <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                              min-height="36"></v-img>
                </div>

                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'video_assistant_referee_over'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div v-if="stage.description">
                <span v-if="stage.description == 'no_penalty'"> Нет пенальти</span>
                <span v-else>{{ stage.description }}</span>
              </div>
              <div v-else>Проверка ВАР</div>

              <div>{{ stage.match_time }}'</div>

              <div style="width:60px; margin-right:12px;">
                <v-img :src="require('@/assets/line_var.svg')" class="" alt="" title="гол" contain  max-height="36"
                            min-height="36"></v-img>
              </div>

              <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'penalty_awarded'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                <!-- <div style="margin-right:12px; width:20px; height: 20px; display: flex; align-items: center;justify-content:center; color:#DC362E; border:1px solid #DC362E; font-size:12px;">11</div> -->
                <div style="width:20px; margin-right:12px;">
                      <v-img :src="require('@/assets/penalty.svg')" class="" alt="" title="гол" contain  max-height="24"
                                  min-height="24"></v-img>
                    </div>
                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>

              <div v-if="stage.type == 'substitution'" style="display: flex; align-items: center; font-size: 16px; color:#838383;">

                <div style="margin-right:12px;" v-if="stage.players && stage.players[1]">{{ stage.players[1].name }}</div>

                <div style="color:#333;font-weight: 500;margin-right:12px;" v-if="stage.players && stage.players[0]">{{ stage.players[0].name }}</div>

                <div style="width:60px; margin-right:12px;">
                  <v-img :src="require('@/assets/subst.svg')" class="" alt="" title="гол" contain  max-height="24"
                              min-height="24"></v-img>
                </div>

                <div>{{ stage.match_time }}'</div>

                <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>

              </div>
          </div>

          <!-- <div v-if="stage && stage.type == 'period_score'" style="padding:3px; font-size:14px; ">
            <div>Конец периода</div>
            <div v-if="stage.commentaries && stage.commentaries[0].text" hidden>{{ stage.commentaries[0].text }}</div>
          </div> -->

        </div>
        </div>

      </v-col>


      <v-col
        class="d-flex"
        cols="12"
        style="border-top:0.5px solid #E4E5E5; "
      >
      <div v-if="events_list && events_list.referees && events_list.referees.length > 0" style="display: flex; flex-direction: column; width:100%;">
        
        <div  style="padding: 10px 10px;font-size: 16px;">
          <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom:10px;">
            <div>Судья</div>
              <div style="font-weight: 500;">
                <div v-for="referee in events_list.referees" :key="`team-${referee.id}`"> 
                  <div v-if="referee.type == 'main_referee'" >
                      <span >{{ referee.name }}</span>
                      <span v-if="referee.nationality">({{ referee.nationality }})</span>
                  </div>
                </div>
              </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom:10px;" >
            <div>Помощники судьи</div>
            <div style="display:flex; align-items: center;font-weight: 500;">
              <div v-for="(referee,ind) in events_list.referees" :key="`team-${referee.id}`" style="display:flex;"> 
                <div v-if="referee.type == 'first_assistant_referee' || referee.type == 'second_assistant_referee' || referee.type == 'video_assistant_referee'">
                  <span v-if="ind > 1" style="margin-left:5px;">,{{ referee.name }} </span>
                  <span v-else>{{ referee.name }}</span>
                  <span v-if="referee.nationality">({{ referee.nationality }})</span>
                </div>
              </div>
            </div>
          </div>
          

          <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom:10px;">
            <div>Резервный судья</div>
              <div style="font-weight: 500;">
                <div v-for="referee in events_list.referees" :key="`team-${referee.id}`"> 
                  <div v-if="referee.type == 'fourth_official'" >
                      <span >{{ referee.name }}</span>
                      <span v-if="referee.nationality">({{ referee.nationality }})</span>
                  </div>
                </div>
              </div>
          </div>
          
        </div>

        <div v-if="events_list && events_list.venue_name" style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Стадион</span>
          <span>{{ events_list.venue_name }} ({{ events_list.venue_address }})</span>
        </div>
        <div v-else style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Стадион</span>
          <span></span>
        </div>
      </div>

      <div v-else style="display: flex; flex-direction: column; width:100%;">
        
        <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <div>Судья</div>
          <div></div>
        </div>

        <!-- [{"id":"sr:referee:52599","name":"Тюрпен, Клеман","nationality":"Франция","country_code":"FRA","type":"main_referee"},
        {"id":"sr:referee:74118","name":"Николя Дано","nationality":"Франция","country_code":"FRA","type":"first_assistant_referee"},
        {"id":"sr:referee:208712","name":"Бенджамин Пагес","nationality":"Франция","country_code":"FRA","type":"second_assistant_referee"},
        {"id":"sr:referee:585220","name":"Пиньяр, Джереми","nationality":"Франция","country_code":"FRA","type":"fourth_official"},
        {"id":"sr:referee:86571","name":"Эрнандес, Алехандро Хосе Эрнандес","nationality":"Испания","country_code":"ESP","type":"video_assistant_referee"}] -->

        <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Помощники судьи</span>
          <span></span>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Резервный судья</span>
          <span></span>
        </div>
     
        <div v-if="events_list.venue_name" style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Стадион</span>
          <span>{{ events_list.venue_name }} ({{ events_list.venue_address }})</span>
        </div>
        <div v-else style="display: flex; align-items: center; justify-content: space-between; padding: 10px 10px;font-size: 16px;">
          <span>Стадион</span>
          <span></span>
        </div>
      </div>
  
      </v-col>

    </v-row>
  </v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'
import {eventBus} from '@/main';

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'LeagueEvents',
  components: {
    //VueDatePicker
    //McvValidationErrors
  },
  data() {
    return {
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      loading:true,
      match_status:null,
      events_list:[],
      event_data:null
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.event.isLoading,
      event: state => state.event.data,
      validationErrors: state => state.event.validationErrors,
    }),
    // computedStyle() { 
    //   return {
    //     'background-color': '#fff'
    //   }
    // },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'event_date',
          width: '20%'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '80%'
        },
      ];

    },
  },
  watch: {
  },
  mounted() {
    this.fetchFeed()

    this.event_data = JSON.parse(localStorage.getItem('localEvent'))

    if(this.event_data && this.event_data.status &&  this.event_data.status != 'closed' && this.event_data.status != 'not_started' && this.event_data.status != 'ended' && this.event_data.status != 'postponed'){
      this.timer = setInterval(() => {
        console.log('set')
        this.fetchFeed()
      }, 10000)
    }
    
  },
  beforeDestroy() {
    console.log('close')
    clearInterval(this.timer)
  },
  methods: {
    slideToggle(ind){
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    fetchFeed() {
      var evv = JSON.parse(localStorage.getItem('localEvent'))

      console.log(evv)

      this.loading = true
      this.$store.dispatch(eventsActionTypes.getEventInfo,{event_id:evv.id}).then((data) => {
        console.log(data)
        this.events_list = data.event_list
        this.loading = false;
        // eventBus.$emit('setliveinfo', {
        //   home_score: data.home_score,
        //   away_score: data.away_score,
        //   match_status: data.match_status,
        //   status: data.status,
        //   time: data.time
        // })
      })

      
    },

  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
  border-bottom:3px solid #FCC341; 
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-image: var(--bgImage);
  background-repeat: no-repeat;
  background-position: 95% 42%;
    background-size: 50% 217%;
}

.demo-content {
  position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.timeline_item {
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
      padding:3px; 
      font-size:14px;
}
</style>

