import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AxiosPlugin from 'vue-axios-cors'
//import {mapActions} from 'vuex'

Vue.config.productionTip = false

let defData = {};
defData.state = {};
defData.state.userInterface = true;

export const eventBus = new Vue()

Vue.use(vuetify);
Vue.use(AxiosPlugin)

new Vue({
  router,
  store,
  vuetify,
  AxiosPlugin,
  render: h => h(App)
}).$mount('#app')
