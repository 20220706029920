<template>
  <v-container style="background-color: #EBEBEB; border-radius: 8px; max-width:100% !important;">

    <v-row class="demo-wrap" :style="{'--bgImage':team_image_bg ? team_image_bg : bgImage}" style=" border: 0.5px solid #E4E5E5;  background-color: #fff;margin-bottom: 16px; border-radius: 8px;">
      <!--height: 264px;-->
      <v-col
        class="d-flex demo-content"
        cols="12"
        style="flex-direction: column; padding: 24px 24px 0px 24px;"
        >
        <div class="d-flex">
          <div style="width:25px; cursor: pointer;" @click="$router.push({path: '/main', params: {}})">
            <v-img :src="require('@/assets/sc_home.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img></div>
          <span style="padding-left:10px;"> / </span>
          <div style="padding-left:10px; font-size: 14px; color: #747780;">{{ team_data.name }}</div>
        </div>

        <div class="d-flex" style="align-items: center; margin:20px 0px;">
          <div style="width:128px; cursor: pointer;">
            <v-img v-if="team_image && team_image.length > 0" :src="team_image" class="" alt="" contain  max-height="128"
            min-height="128"></v-img>
            <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt="" contain   max-height="128"
            min-height="128"></v-img>
          </div>
          <div class="d-flex" style="flex-direction:column; padding:0px 0px 0px 20px;">
              <div v-if="team_name"><span style="font-size: 22px; color: #333; font-weight: 500;" >{{ team_name }} </span> <span>{{ team_name.substr(0, 3) }}</span></div>
              <div v-else><span  style="font-size: 22px; color: #333; font-weight: 500;" >{{ team_data.name }} </span> <span>{{ team_data.name.substr(0, 3) }}</span></div>
              <span style="font-size: 12px; color: #333; font-weight: 300;">{{ team_country_ru ? team_country_ru : '' }}</span>
          </div>
        </div>

        <div class="d-flex blcks" style="align-items: center;">
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Events'" v-bind:class="{ active: leagueContent == 'Events' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>События</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Results'" v-bind:class="{ active: leagueContent == 'Results' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Результаты</span>
          </div>
          <div  class="d-flex" @click="newLeageu = false, leagueContent = 'Transfers'" v-bind:class="{ active: leagueContent == 'Transfers' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Переходы</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Players'" v-bind:class="{ active: leagueContent == 'Players' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Состав</span>
          </div>
        </div>

      </v-col>

    </v-row>

    <TeamEvents v-if="leagueContent == 'Events'" />

    <TeamResults v-if="leagueContent == 'Results'" />

    <TeamTransfers v-if="leagueContent == 'Transfers'" />

    <TeamPlayers v-if="leagueContent == 'Players'" />
    
    <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        
  </v-sheet>
  </v-container>


</template>

<script>
import {mapState} from "vuex";
import TeamEvents from '@/views/Team/TeamEvents.vue';
import TeamResults from '@/views/Team/TeamResults.vue';
import TeamTransfers from '@/views/Team/TeamTransfers.vue';
import TeamPlayers from '@/views/Team/TeamPlayers.vue';
import {teamsActionTypes} from '@/store/modules/teams'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'League',
  // props: ['league_id','league_value_name'],
  components: {
    TeamEvents,
    TeamResults,
    TeamTransfers,
    TeamPlayers
  },
  data() {
    return {
      newLeageu:false,
      team_data_def:[
        {
          league_name:'Английская премьер лига',
          league_id:'sr:competition:17',
          league_type:'national',
          name:'Челси',
          logo:"chelsea.png",
          country:'Англия'
        },
        {
          league_name:'Лига чемпионов',
          league_id:'sr:competition:1',
          league_type:'world',
          name:'Челси',
          logo:"chelsea.png",
          country:'Англия'
        },
        {
          league_name:'Лига европы',
          league_id:'sr:competition:7',
          league_type:'world',
          name:'Челси',
          logo:"chelsea.png",
          country:'Англия'
        }
      ],
      team_data:{
        league_name:'',
        league_id:'',
        league_type:'',
      },
      league_season:'2022/2023',
      loading:false,
      leagueContent:'Events',
      bgImage:`url(${require('../../assets/teams/chelsea.png')})`,
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      team_image:null,
      team_image_bg:null,
      team_name:null,
      team_country_ru:''
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.team.isLoading,
      team: state => state.team.data,
      validationErrors: state => state.team.validationErrors,
    }),
  },
  watch: {
  },
  created(){
  },
  mounted() {
    this.team_data = JSON.parse(localStorage.getItem('localTeam'))
    console.log(this.$route.params.slug.toString())


    this.getTeam(this.$route.params.slug.toString())
  },
  methods: {
    slideToggle(ind,id){
      console.log(ind)
      console.log(id)
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    getTeam(team){
      console.log(team)
        this.loading = true
        this.$store.dispatch(teamsActionTypes.getTeam,{competitor_id:team}).then((data) => {
          console.log(data)
          this.team_image = data.image
          this.team_name = data.name_ru
          this.team_country_ru = data.country_ru
          this.team_image_bg = `url(${data.image})`,
          this.loading = false;
      })
    }
  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-image: var(--bgImage);
  background-repeat: no-repeat;
  background-position: 95% 42%;
    background-size: 50% 217%;
}

.demo-content {
  position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field >>> .v-input__control >>> .v-input__slot:before {
  display: none !important;
}
.year_check >>> .v-input__slot:before {
  display: none !important;
}

.v-text-field >>> .v-input__slot:after {
  display: none !important;
}

.v-text-field >>> .v-input__slot:before {
  display: none !important;
}

</style>

