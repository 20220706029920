import axios from '@/api/axios'


const login = credentials => {
  return axios.post('/countries', {info: credentials})
}

const authentification = () => {
  return axios.post('/admin/user')
}

export default {
  login,
  authentification
}
