<template>
  <v-container style="background-color: white; border-radius: 8px; max-width:100% !important;">
    <!-- <v-toolbar-title style="padding:20px;">Страны</v-toolbar-title> -->

    <v-row v-for="(sport, sportindex) in sports_list" :key="`sprow-${sport.id}`">
      <v-col
      v-if="!loading"
        class="d-flex"
        cols="12"
        style="align-items:center; height: 72px; justify-content: space-between;"
      >
      <div @click="choseSport(sportindex)" style="display: flex; align-items: center; ">
        <v-img :src="require('@/assets/ball.svg')" class="" width="30" alt="" max-height="30" min-height="30" ></v-img>
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;" class="mob_size_span3">{{ sport.name_ru }} </span>
      </div>

       <div v-if="sportindex == 0" class="d-flex" style="align-items: center; height: 48px; border: 0.5px solid #E4E5E5; border-radius: 25px;">
        <div @click="changeWeek(-1)" class="d-flex handPickerItem" style="height:100%; width:45px; align-items: center; justify-content: center; border-radius:6px;">
          <v-img :src="require('@/assets/ar_left.svg')" class="" alt="" contain  max-height="24"
        min-height="24"></v-img>
        </div>
        <div @click="getShowCal()" class="d-flex handPickerItem handPickerItemCenter"  style="height:100%; position: relative; width:127px; align-items: center; justify-content: center; border-radius:6px; margin:0px 6px; ">
                     

                        <span style="font-size:12px; margin-right:8px;">{{ date_value }} </span>
                        <div style="display:flex; align-items:center; width:20px; height:20px;"><v-img :src="require('@/assets/calendar.svg')" class="" alt="" contain  max-height="24"
        min-height="24"></v-img>
                        </div>

                        <div v-if="showCal" class="handPicker" style="
                          display:flex; position: absolute; flex-direction: column; align-items: center; width:227px; height:auto; background: #fff;
                          border-radius: 8px;
                          top: 47px;
                          left: -48px;
                          /* border: 0.5px solid; */
                          align-items: center;
                          z-index: 100;
                          box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 15%), 0px 1px 2px 0px rgb(0 0 0 / 30%);
                          ">

                         
                          <div class="handPickerItem" v-for="(num, index) in date_list" @click="date_value = num.day+'.'+num.month+' '+num.text, getData(num.day,num.month)" :key="`row-${index}`" style="height: 24px; width:96%; ">
                              <div v-if="num.text == 'Сегодня'" style="background:#FCC341; border-radius: 8px;display: flex; align-items: center; height: 100%; width:100%; font-size:12px; cursor: pointer; justify-content: center;">
                                {{ num.text }}
                              </div>
                              <div v-else style="display: flex; align-items: center; border-radius: 8px;height: 100%; width:100%; font-size:12px; cursor: pointer; justify-content: center;">
                                {{ num.day }}.{{ num.month }} {{ num.text }}
                              </div>
                          </div>

                        </div>

        </div>
        <div @click="changeWeek(1)" class="d-flex handPickerItem" style="height:100%; width:45px; align-items: center; justify-content: center; border-radius:6px;">
          <v-img :src="require('@/assets/ar_right.svg')" class="" alt="" width="10"         contain
        max-height="24"
        min-height="24"></v-img>
        </div>
       </div>

      </v-col>
    <!-- </v-row> -->
    <v-col
    v-if="!loading"
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        >
    <v-row v-for="(num, index) in sport.events_list" :key="`row-${num.id}`">
      <v-col
      v-if="!loading"
        class="d-flex"
        cols="12"
        style="cursor: pointer; background-color:#F8F8F8; "
        @click="slideToggle(sportindex,index,num.id)"
      >
        <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
          <div style="display:flex; align-items:center;">
            <div style="width:30px; ">
              <v-img v-if="num.event_logo" :src="num.event_logo" class="" alt="" max-height="20" min-height="20" contain></v-img>
              
            </div>
            <span style="padding-left: 10px;" class="mob_size_span">{{ num.event_name }}</span>
          </div>
          <div style="display:flex; align-items: center;">
            <span class="mob_size_span2" style="text-decoration: underline; padding-right: 16px; cursor: pointer;" @click="setLeague(num)">Таблица</span>
            <v-img v-if="num.show" :src="require('@/assets/ar_up.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
            <v-img v-else :src="require('@/assets/ar_down.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
          </div>
        </div>
      </v-col>
      <!-- <vue-slide-up-down :active="num.show"> -->
      <v-col
        v-if="num.show"
        class="d-flex"
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        :id="`slide-${num.id}`"
      >
      <v-data-table
          :headers="headers"
          :items="num.items"
          item-key="id"
          :hide-default-footer="true"
          class="elevation-1"
          style="width:100%;"
        >
        <template v-slot:item.time="{item}">
          <div @click="setEvent(num,item)" style="cursor:pointer;width:100%; display: flex; flex-direction:column;" :id="'event-id-'+id">
            <div v-if="item.status != 'live'">
              <span style="color:#838383; font-size:14px;"> {{ item.time_description }}</span>
            </div>
            <div v-if="item.status == 'live'" style="display:flex; align-items:center; justify-content:center; width:80px; height:30px; padding:4px 8px; background: #FBEBEA; border-radius:4px;">
              <span style="color:#DC362E;"> 
                <b v-if="item.time_description != 'Перерыв' && item.time_description != 'С.П.'">{{ item.time_description }}'</b>
                <b v-else>{{ item.time_description }}</b>
              </span> 
            </div>
          </div>
        </template>
        <template v-slot:item.name="{item}">
          <div style="width:100%;height:100%;display: flex; align-items: center; padding:2px 0px; height:64px;" >
            
          <div style="display: flex; align-items: center">
            <div @click="setTeam(team,num)" v-for="team in item.teams" :key="`team-${team.id}`" style="cursor:pointer;display: flex;align-items: center; margin:2px 0px;">
              <div style="width:40px;">
                <v-img v-if="team.logo && team.logo.length > 3" :src="team.logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; margin-left:20px;">
            <div @click="setTeam(team,num)" v-for="team in item.teams" :key="`team-${team.id}`" style="cursor:pointer;display: flex;align-items: center; margin:2px 0px;">
              <span> {{ team.name }}</span>
            </div>
          </div>
            
          </div>
          
        </template>

        <template v-slot:item.result="{item}">
          <div @click="setEvent(num,item)" style="cursor:pointer;width:100%;height:100%;display: flex;align-items: center; flex-direction: column; padding:10px 0px; height:64px;" v-if="item.status == 'live' || item.status == 'ended'">
            <div v-for="team in item.teams" :key="`team-${team.id}`" style="display: flex;align-items: center;">
              <span style="font-weight: bold; font-size: 14px;"> {{ team.result }}</span>
              <span style="font-weight: bold; padding-left:12px; color:#747780; font-size: 14px;"> ({{ team.result_1 }})</span>
            </div>
          </div>
          <div @click="setEvent(num,item)" style="cursor:pointer;width:100%;height:100%;display: flex;align-items: center;" v-else>
            <span style="font-weight: bold;"> {{ item.result }}</span>
          </div>
        </template>

        <template v-slot:item.date="{item}">
          <div v-if="item.teams[0].penalty && item.teams[0].penalty.length > 0" style="min-width:300px; max-width:400px;height:100%; display: flex; flex-direction: column; padding:10px 0px;" >
            <div style="display: flex; align-items: center;" v-if="item.status == 'live'">
              <span style="color:#C70000; font-weight: bold; font-size: 14px;">{{ item.teams[0].penalty_result }}</span>
                <div v-for="pen in item.teams[0].penalty" :key="`pen-${pen}`" style="width:35px; padding-left:12px;">
                  <v-img v-if="pen === true" :src="require('@/assets/ball_green.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                  <v-img v-if="pen === false" :src="require('@/assets/ball_red.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                  <v-img v-if="pen === 'no'" :src="require('@/assets/ball_grey.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                </div>
            </div>
            <div style="display: flex; align-items: center;">
              <span v-if="item.status == 'live'" style="color:#C70000;font-weight: bold;">{{ item.teams[1].penalty_result }}</span>
                <div v-for="pen in item.teams[1].penalty" :key="`${item.id}-pen-${pen}`" style="width:35px; padding-left:12px;">
                  <v-img v-if="pen === true" :src="require('@/assets/ball_green.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                  <v-img v-if="pen === false" :src="require('@/assets/ball_red.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                  <v-img v-if="pen === 'no'" :src="require('@/assets/ball_grey.svg')" class="" alt="" max-height="30" min-height="30" contain></v-img>
                </div>
            </div>
          </div>
          <div v-else style="min-width:300px; max-width:400px;height:100%; display: flex; flex-direction: column; padding:10px 0px;" >
          </div>
        </template>

        <template v-slot:item.status="{item}">
          <div style="width:100%;height:100%;display: flex;align-items: center;">
            <span class="ppul" v-if="item.status == 'live'" style="color:#C70000;font-weight: bold;"> {{ item.status }}</span>
          </div>
        </template>
  
      </v-data-table>
      </v-col>
    <!-- </vue-slide-up-down> -->

    </v-row>
  </v-col>

  </v-row>

    <v-sheet
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
        style="margin-top: 30px;"
        v-if="loading"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        
  </v-sheet>
  </v-container>


</template>

<script>
import {mapState} from "vuex";
//import {eventBus} from '@/main'
import {eventsActionTypes} from '@/store/modules/events'
//import McvValidationErrors from '@/components/ValidationErrors.vue'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'McvOrders',
  components: {
    //McvValidationErrors
  },
  data() {
    return {
      date_value:"25.09 ПН",
      showCal:false,
      week_days_count:7,
      days_array:[],
      date_list:[],
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      loading:true,
      sports_list_locale:{
        "soccer":"Футбол",
        "tennis":"Теннис",
        "icehockey":"Хоккей"
      },
      sports_list: [
      ],
      weekCount:-1,
      weekCount2:0,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.events.isLoading,
      events: state => state.events.data,
      validationErrors: state => state.events.validationErrors,
    }),
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'time',
          width: '100px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '300px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'result',
          width: '200px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'date',
          width: '100px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'status',
          width: '50px'
        },
        
      ];
      
    }
  },
  watch: {
  },
  mounted() {
    this.loading = true
    this.fetchFeed()
    this.getDaysArrayText()

    this.timer = setInterval(() => {
      console.log('set')
      this.fetchFeed()
    }, 60000)
  },
  beforeDestroy() {
    console.log('close')
    clearInterval(this.timer)
  },
  methods: {
    setLeague(item){
      var leagueItem = {}
      leagueItem.id = item.competition_id
      leagueItem.name = item.event_name
      leagueItem.logo = item.event_logo
      if(leagueItem.name.split('Межд').length > 1 || leagueItem.name.split('межд').length > 1 || leagueItem.name.split('Int').length > 1  || leagueItem.name.split('int').length > 1){
        leagueItem.league_type = "world"
      } else {
        leagueItem.league_type = "national"
      }
    
      console.log(leagueItem)
      localStorage.setItem('localCompetition',JSON.stringify(leagueItem))
      if(this.$route.path.toString().split('guene').length > 1){
        this.$router.push({path: '/league/'+leagueItem.id+':table', replace: true})
      } else {
        this.$router.push({path: '/leaguenext/'+leagueItem.id+':table', replace: true})
      }
    },
    setTeam(item,num){
      item.country = num.event_name.split(',')[0] ? num.event_name.split(',')[0] : ''
      localStorage.setItem('localTeam',JSON.stringify(item))
      if(this.$route.path.toString().split('mnex').length > 1){
        this.$router.push({path: '/team/'+item.id, replace: true})
      } else {
        this.$router.push({path: '/teamnext/'+item.id, replace: true})
      }
    },
    setEvent(league,event){
      var eventItem = {}
      //leagueItem.id = item.competition_id
      eventItem.league_logo = league.event_logo
      eventItem.league_id = league.competition_id
      eventItem.league_name = league.event_name.split(',')[1]
      eventItem.league_country = league.event_name.split(',')[0]
      eventItem.id = event.id
      eventItem.name = event.name
      eventItem.status = event.status
      eventItem.date = (new Date().getDate() < 10 ? '0'+(new Date().getDate()) : new Date().getDate())+'.'+((new Date().getMonth()+1) < 10 ? '0'+((new Date().getMonth()+1)) : (new Date().getMonth()+1))
      eventItem.time = event.time_description
      eventItem.team_1_id = event.teams[0].id
      eventItem.team_1_name = event.teams[0].name
      eventItem.team_1_logo = event.teams[0].logo
      eventItem.team_1_result = event.teams[0].result 
      eventItem.team_1_result_1 = event.teams[0].result_1
      eventItem.team_2_id = event.teams[1].id
      eventItem.team_2_name = event.teams[1].name
      eventItem.team_2_logo = event.teams[1].logo
      eventItem.team_2_result = event.teams[1].result 
      eventItem.team_2_result_1 = event.teams[1].result_1
      console.log(eventItem)
      localStorage.setItem('localEvent',JSON.stringify(eventItem))

      var leagueItem = {}
      leagueItem.id = league.competition_id
      leagueItem.name = league.event_name
      leagueItem.logo = league.event_logo
      if(leagueItem.name.split('Межд').length > 1 || leagueItem.name.split('межд').length > 1 || leagueItem.name.split('Int').length > 1  || leagueItem.name.split('int').length > 1){
        leagueItem.league_type = "world"
      } else {
        leagueItem.league_type = "national"
      }
    
      console.log(leagueItem)
      localStorage.setItem('localCompetition',JSON.stringify(leagueItem))

      if(this.$route.path.toString().split('ntnex').length > 1){
        this.$router.push({path: '/event/'+eventItem.id, replace: true})
      } else {
        this.$router.push({path: '/eventnext/'+eventItem.id, replace: true})
      }
    },
    changeWeek(n){
      this.weekCount = this.weekCount + (n)
      this.weekCount2 = this.weekCount2 + (n)
      var dday = (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate())
      var mmonth = ((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1)
      this.date_value = dday+'.'+mmonth+' СР'
      this.getDaysArrayText()
    },
    getDaysArrayText(){
      var ar = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']
      //var weekCount = 1
      this.date_list = [
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - this.weekCount*7).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+1).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+2).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+3).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 3).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+4).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+5).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 5).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+6).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 6).getDay()]
        },
        {
          day:(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1),
          text:(this.weekCount == -1)?'Сегодня':'СР',
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+1)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+2)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+3)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 3).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+4)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+5)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 5).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getDate()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+6)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 6).getDay()]
        },
        {
          day:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getDate())<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getMonth()),
          month:((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (this.weekCount2*7+7)).getMonth()+1),
          text:ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7).getDay()]
        },
      ];
      console.log(this.date_list)
    },
    getShowCal(){
      if(!this.showCal){
        this.showCal = true
      } else {
        this.showCal = false
      }
    },
    getData(day,month){
      this.showCal = false
      // this.loading = true
      // let vm = this
      //   setTimeout(function() {
      //     //localStorage.setItem('getted',true)
      //     vm.loading = false
      //   }, 1000);
      this.fetchFeed({day:day,month:month})  
    },
    slideToggle(spid,ind,id){
      console.log(spid)
      console.log(ind)
      console.log(id)
      console.log(this.sports_list[spid])
      console.log(this.sports_list[spid].events_list[ind])
      this.sports_list[spid].events_list[ind].show = this.sports_list[spid].events_list[ind].show ? false : true 
      //this.sports_list[spid].events_list[ind].show != this.sports_list[spid].events_list[ind].show
    },
    fetchFeed(obj) {
      var ar = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']

      let day = (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate()):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getDate())
      let month = ((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1)<10)?'0'+(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1):(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - (-this.weekCount)*7+7).getMonth()+1)
      let ttext = ar[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7).getDay()]
      this.date_value = day+'.'+month+' '+ttext

      var data = {actual:true}
      if(obj){
        data = obj
        if(obj.day == new Date().getDate()) {
          data.actual = true 
        }
      }
      this.$store.dispatch(eventsActionTypes.getEvents,data).then((data) => {
        console.log(data)
        this.sports_list = data
        this.loading = false;
      })
    }

  },
}
</script>

<style scoped>
.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field >>> .v-input__control >>> .v-input__slot:before {
  display: none !important;
}
.year_check >>> .v-input__slot:before {
  display: none !important;
}

.v-text-field >>> .v-input__slot:after {
  display: none !important;
}

.v-text-field >>> .v-input__slot:before {
  display: none !important;
}
.v-menu__content >>> .theme--light >>> .menuable__content__active {
  width:227px !important;
  border-radius:8px !important;
  left:0px !important;
}
.handPicker,.handPickerItem {
  transition:0.5s;
}
.handPickerItem:hover{
  cursor:pointer;
background: #FFF9EC;
}

.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}

.v-data-table-header >>> .columnheader {
 
}

.ppul{
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
body {
  caret-color: red !important;
}
          

</style>