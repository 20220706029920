import axios from '@/api/axios'

const getSeasons = (data) => {
  return axios
        .post(`/api/getSeasonsList`,{data:data})
        .then(response => response.data)
}

const getSeason = (data) => {
  return axios
        .post(`/api/getSeason`,{data:data})
        .then(response => response.data)
}


export default {
  getSeasons,
  getSeason
}
