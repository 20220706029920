<template>
 
  <v-row style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;">Переходы </span>
      </div>

    </v-col>
  <!-- </v-row> -->
  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      >
  <v-row >

    <v-col
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      v-if="events_list && events_list[0] && events_list[0].player_name"
    >
    <v-data-table
        :headers="headers"
        :items="events_list"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        style="width:100%;"
      >
      <template v-slot:item.transfer_date="{item}">
        <div style="display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ item.transfer_date }}</span>
        </div>
      </template>
      <template v-slot:item.player_name="{item}">
        <div style="display: flex; align-items: center;">
          <div style="width:15%;height:100%;display: flex; align-items:center; padding:2px 0px; height:64px;" >
              <div style="width:28px;  border-radius: 50px; background: #F8F8F8;">
                <v-img :src="item.player_country_image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
          </div>
          <div style="width:100%;height:100%;display: flex;flex-direction: column; padding:2px 0px; height:64px; justify-content: center;" >
              <span> {{ item.player_name }}</span>
          </div>
      </div>
      </template>
      <template v-slot:item.competitor_id="{item}">
        <div style="display: flex; align-items: center;width:50%;">
            <div v-if="item.transfer_type == 'selling'" style="width:100%;height:100%;display: flex;padding:2px 0px; height:64px; align-items: center;" >
              <div style="width:38px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/line_red_right.svg')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
              <div style="width:28px;border-radius: 50px; background: #F8F8F8; margin:0px 10px;">
                <v-img v-if="item.competitor_image_to && item.competitor_image_to.image && item.competitor_image_to.image.length > 0" :src="item.competitor_image_to.image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                <v-img v-else-if="item.competitor_image_from && item.competitor_image_from.image && item.competitor_image_from.image.length > 0" :src="item.competitor_image_from.image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                <v-img v-else :src="item.competitor_image_to.country_image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
                <div style="cursor: pointer;" @click="setTeam(item.competitor_id_to)"><span style="font-weight: bold; font-size: 14px;"> {{ item.competitor_name_from }}</span></div>
            </div>
            <div v-else style="width:100%;height:100%;display: flex; padding:2px 0px; height:64px; align-items: center;" >
              <div style="width:28px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/line_green_left.svg')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
              <div style="width:28px;border-radius: 50px; background: #F8F8F8; margin:0px 10px;">
                <v-img v-if="item.competitor_image_from && item.competitor_image_from.image && item.competitor_image_from.image.length > 0" :src="item.competitor_image_from.image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                <v-img v-else-if="item.competitor_image_to && item.competitor_image_to.image && item.competitor_image_to.image.length > 0" :src="item.competitor_image_to.image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                <v-img v-else :src="item.competitor_image_from.country_image" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
              <div style="cursor: pointer;" @click="setTeam(item.competitor_id_from)"><span style="font-weight: bold; font-size: 14px;"> {{ item.competitor_name_to }}</span></div>
            </div>
        </div>
      </template>
      <template v-slot:item.transfer_type="{item}">
        <div style="display: flex; align-items: center; justify-content: flex-end;">
            <div style="width:100%;height:100%;display: flex;flex-direction: column; justify-content: center; padding:2px 0px; height:64px;" >
                <span style="font-weight: bold; font-size: 14px;"> {{ item.transfer_cost }}</span>
                <span style="font-weight: bold; color:#747780; font-size: 14px;"> {{ item.transfer_type_name }}</span>
            </div>
        </div>
      </template>

    </v-data-table>
    </v-col>
  <!-- </vue-slide-up-down> -->
  <v-col
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      v-else
    >
    <span>Данных не найдено</span>
    </v-col>
  </v-row>
</v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {teamsActionTypes} from '@/store/modules/teams'

export default {
inject: {
    theme: {
      default: { isDark: false },
    },
  },
name: 'TeamTransfers',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    events_list:[]
  }
},
computed: {
  ...mapState({
    isLoading: state => state.team.isLoading,
    team: state => state.team.data,
    validationErrors: state => state.team.validationErrors,
  }),
  headers() {
    return [
      {
        text: 'ДАТА',
        align: 'start',
        sortable: false,
        value: 'transfer_date',
        width: '20%'
      },
      {
        text: 'ИГРОК',
        align: 'start',
        sortable: false,
        value: 'player_name',
        width: '30%'
      },
      {
        text: 'ОТКУДА-КУДА',
        align: 'start',
        sortable: false,
        value: 'competitor_id',
        width: '30%'
      },
      {
        text: 'ТИП ПЕРЕХОДА',
        align: 'start',
        sortable: false,
        value: 'transfer_type',
        width: '20%'
      },
    ];

  },
},
watch: {
  
},
mounted() {
  this.fetchFeed()
},
methods: {
  setTeam(id){

      if(this.$route.path.toString().split('mnex').length > 1){
        this.$router.push({path: '/team/'+id, replace: true})
      } else {
        this.$router.push({path: '/teamnext/'+id, replace: true})
      }
  },
  slideToggle(ind,id){
    console.log(id)
    this.events_list[ind].show = this.events_list[ind].show ? false : true 
  },
  fetchFeed() {
    var team = this.$route.params.slug.toString()
      this.$store.dispatch(teamsActionTypes.getTeamTransfers,{competitor_id:team}).then((data) => {
        console.log(data)
        this.events_list = data.data
        this.loading = false;
      })
  },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}
</style>

