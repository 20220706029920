import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#45120b',
            },
        },
    },
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes,
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    }
});
