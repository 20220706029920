<template>
 
  <v-row style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 16px; line-height: 24px; font-weight: 500; padding-left: 12px;"> Статистика </span>
      </div>

      <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>

    </v-col>
  <!-- </v-row> -->

  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      
      >
  <v-row v-if="events_list && events_list.length > 0">
    <v-col
      class="d-flex"
      cols="12"
      style="padding:16px 24px; "
    >
      <div style="height:16px;display:flex; align-items:center; width:100%; padding: 10px 0px; ">
        <div class="stat_chose_but"
        @click="statKoef = 1, statContent = 'full'" v-bind:class="{ active: statContent == 'full' }"
        style="display:flex;cursor:pointer; align-items:center; justify-content: center; height:32px; width: 157px; border-radius: 6px;">
          <span style="color:#333; font-size: 14px;">Весь матч</span>
        </div>
        <div class="stat_chose_but"
        @click="statKoef = 0.5, statContent = '1_half'" v-bind:class="{ active: statContent == '1_half' }"
        style="margin-left:12px;cursor:pointer; justify-content: center; display:flex; align-items:center; height:32px; width: 157px; border-radius: 6px;">
          <span style="color:#333; font-size: 14px;">1 тайм</span>
        </div>
        <div class="stat_chose_but"
        @click="statKoef = 0.5, statContent = '2_half'" v-bind:class="{ active: statContent == '2_half' }"
        style="margin-left:12px;cursor:pointer; justify-content: center; display:flex; align-items:center; height:32px; width: 157px; border-radius: 6px;">
          <span style="color:#333; font-size: 14px;">2 тайм</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5; flex-direction: column;"
    >
      <div v-if="events_list && events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{events_list[0].statistics.ball_possession ? events_list[0].statistics.ball_possession*statKoef : 0}}%</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Владение мячом</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{events_list[0].statistics.ball_possession ? events_list[1].statistics.ball_possession*statKoef : 0}}%</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; background: linear-gradient(to right, #EBEBEB ${(100-events_list[0].statistics.ball_possession*statKoef)}%, #DC362E ${events_list[0].statistics.ball_possession*statKoef}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; background: linear-gradient(to right, #FCC341 ${events_list[1].statistics.ball_possession*statKoef}%, #EBEBEB ${(100-events_list[1].statistics.ball_possession)*statKoef}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics.shots_total && events_list[1].statistics.shots_total" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.shots_total)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Удары</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.shots_total)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to right, #EBEBEB ${100*checkVal(events_list[1].statistics.shots_total)/(checkVal(events_list[0].statistics.shots_total)+checkVal(events_list[1].statistics.shots_total))}%, #DC362E ${100*checkVal(events_list[0].statistics.shots_total)/(checkVal(events_list[0].statistics.shots_total)+checkVal(events_list[1].statistics.shots_total))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[0].statistics.shots_total)/(checkVal(events_list[0].statistics.shots_total)+checkVal(events_list[1].statistics.shots_total))}%, #EBEBEB ${100*checkVal(events_list[1].statistics.shots_total)/(checkVal(events_list[0].statistics.shots_total)+checkVal(events_list[1].statistics.shots_total))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.shots_on_target)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Удары в створ</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.shots_on_target)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.shots_on_target)/(checkVal(events_list[0].statistics.shots_on_target)+checkVal(events_list[1].statistics.shots_on_target))}%, transparent ${100*checkVal(events_list[0].statistics.shots_on_target)/(checkVal(events_list[0].statistics.shots_on_target)+checkVal(events_list[1].statistics.shots_on_target))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.shots_on_target)/(checkVal(events_list[0].statistics.shots_on_target)+checkVal(events_list[1].statistics.shots_on_target))}%, transparent ${100*checkVal(events_list[1].statistics.shots_on_target)/(checkVal(events_list[0].statistics.shots_on_target)+checkVal(events_list[1].statistics.shots_on_target))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.shots_off_target)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Удары мимо</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.shots_off_target)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.shots_off_target)/(checkVal(events_list[0].statistics.shots_off_target)+checkVal(events_list[1].statistics.shots_off_target))}%, transparent ${100*checkVal(events_list[0].statistics.shots_off_target)/(checkVal(events_list[0].statistics.shots_off_target)+checkVal(events_list[1].statistics.shots_off_target))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.shots_off_target)/(checkVal(events_list[0].statistics.shots_off_target)+checkVal(events_list[1].statistics.shots_off_target))}%, transparent ${100*checkVal(events_list[1].statistics.shots_off_target)/(checkVal(events_list[0].statistics.shots_off_target)+checkVal(events_list[1].statistics.shots_off_target))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.fouls)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Штрафные</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.fouls)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.fouls)/(checkVal(events_list[0].statistics.fouls)+checkVal(events_list[1].statistics.fouls))}%, transparent ${100*checkVal(events_list[0].statistics.fouls)/(checkVal(events_list[0].statistics.fouls)+checkVal(events_list[1].statistics.fouls))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.fouls)/(checkVal(events_list[0].statistics.fouls)+checkVal(events_list[1].statistics.fouls))}%, transparent ${100*checkVal(events_list[1].statistics.fouls)/(checkVal(events_list[0].statistics.fouls)+checkVal(events_list[1].statistics.fouls))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.corner_kicks)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Угловые</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.corner_kicks)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.corner_kicks)/(checkVal(events_list[0].statistics.corner_kicks)+checkVal(events_list[1].statistics.corner_kicks))}%, transparent ${100*checkVal(events_list[0].statistics.corner_kicks)/(checkVal(events_list[0].statistics.corner_kicks)+checkVal(events_list[1].statistics.corner_kicks))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.corner_kicks)/(checkVal(events_list[0].statistics.corner_kicks)+checkVal(events_list[1].statistics.corner_kicks))}%, transparent ${100*checkVal(events_list[1].statistics.corner_kicks)/(checkVal(events_list[0].statistics.corner_kicks)+checkVal(events_list[1].statistics.corner_kicks))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.offsides)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Офсайды</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.offsides)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.offsides)/(checkVal(events_list[0].statistics.offsides)+checkVal(events_list[1].statistics.offsides))}%, transparent ${100*checkVal(events_list[0].statistics.offsides)/(checkVal(events_list[0].statistics.offsides)+checkVal(events_list[1].statistics.offsides))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.offsides)/(checkVal(events_list[0].statistics.offsides)+checkVal(events_list[1].statistics.offsides))}%, transparent ${100*checkVal(events_list[1].statistics.offsides)/(checkVal(events_list[0].statistics.offsides)+checkVal(events_list[1].statistics.offsides))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.yellow_cards)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Жёлтые карточки</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.yellow_cards)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.yellow_cards)/(checkVal(events_list[0].statistics.yellow_cards)+checkVal(events_list[1].statistics.yellow_cards))}%, transparent ${100*checkVal(events_list[0].statistics.yellow_cards)/(checkVal(events_list[0].statistics.yellow_cards)+checkVal(events_list[1].statistics.yellow_cards))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.yellow_cards)/(checkVal(events_list[0].statistics.yellow_cards)+checkVal(events_list[1].statistics.yellow_cards))}%, transparent ${100*checkVal(events_list[1].statistics.yellow_cards)/(checkVal(events_list[0].statistics.yellow_cards)+checkVal(events_list[1].statistics.yellow_cards))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.red_cards)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Красные карточки</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.red_cards)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.red_cards)/(checkVal(events_list[0].statistics.red_cards)+checkVal(events_list[1].statistics.red_cards))}%, transparent ${100*checkVal(events_list[0].statistics.red_cards)/(checkVal(events_list[0].statistics.red_cards)+checkVal(events_list[1].statistics.red_cards))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.red_cards)/(checkVal(events_list[0].statistics.red_cards)+checkVal(events_list[1].statistics.red_cards))}%, transparent ${100*checkVal(events_list[1].statistics.red_cards)/(checkVal(events_list[0].statistics.red_cards)+checkVal(events_list[1].statistics.red_cards))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.substitutions)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Замены</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.substitutions)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.substitutions)/(checkVal(events_list[0].statistics.substitutions)+checkVal(events_list[1].statistics.substitutions))}%, transparent ${100*checkVal(events_list[0].statistics.substitutions)/(checkVal(events_list[0].statistics.substitutions)+checkVal(events_list[1].statistics.substitutions))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.substitutions)/(checkVal(events_list[0].statistics.substitutions)+checkVal(events_list[1].statistics.substitutions))}%, transparent ${100*checkVal(events_list[1].statistics.substitutions)/(checkVal(events_list[0].statistics.substitutions)+checkVal(events_list[1].statistics.substitutions))}%`"
          >
          </div>
        </div>

      </div>

      <div v-if="events_list[0].statistics && events_list[1].statistics" style="display:flex; align-items:center; width:100%; flex-direction: column; margin-bottom: 16px;">
        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; padding:6px 0px;">
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[0].statistics.injuries)}}</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">Травмы</span>
            </div>
            <div>
              <span style="line-height: 20px; font-size:14px;">{{checkVal(events_list[1].statistics.injuries)}}</span>
            </div>
        </div>

        <div style="display:flex; align-items:center; width:100%; justify-content: space-between; background-color: #EBEBEB;">
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px; 
          background: linear-gradient(to left, #DC362E ${100*checkVal(events_list[0].statistics.injuries)/(checkVal(events_list[0].statistics.injuries)+checkVal(events_list[1].statistics.injuries))}%, transparent ${100*checkVal(events_list[0].statistics.injuries)/(checkVal(events_list[0].statistics.injuries)+checkVal(events_list[1].statistics.injuries))}%`"
          >
          </div>
          <div
          :style="`transition:0.5s; height: 12px; width:49.5%; border-radius: 4px;
          background: linear-gradient(to right, #FCC341 ${100*checkVal(events_list[1].statistics.injuries)/(checkVal(events_list[0].statistics.injuries)+checkVal(events_list[1].statistics.injuries))}%, transparent ${100*checkVal(events_list[1].statistics.injuries)/(checkVal(events_list[0].statistics.injuries)+checkVal(events_list[1].statistics.injuries))}%`"
          >
          </div>
        </div>

      </div>


    </v-col>
  <!-- </vue-slide-up-down> -->

  </v-row>

  <v-row v-if="events_list.length == 0">
    <v-col
      class="d-flex"
      cols="12"
      style="padding:16px 24px; "
    >
    Статистика матча ожидается
    </v-col>
  </v-row>
</v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'

export default {

name: 'EventStats',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    statContent:'full',
    statKoef:1,
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    events_list: []
  }
},
computed: {
  ...mapState({
    isLoading: state => state.event.isLoading,
    event: state => state.event.data,
    validationErrors: state => state.event.validationErrors,
  }),
  headers() {
    return [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'start_time',
        width: '10%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'competitor_1_name',
        width: '40%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'period_1_home_score',
        width: '40%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'winner_id',
        width: '10%'
      },
    ];

  },
},
watch: {
},
mounted() {
  this.fetchFeed()
},
methods: {
  checkVal(val){
    return val ? val*this.statKoef : 0
  },
  getDateTime(timestamp){
    return this.CalcTime(new Date(timestamp).getDate()) + '.' + this.CalcTime(new Date(timestamp).getMonth()) + ' ' + this.CalcTime(new Date(timestamp).getHours()) + '.' + this.CalcTime(new Date(timestamp).getMinutes())
  },
  CalcTime(time){
    return (+time < 10) ? '0'+time : time 
  },
  fetchFeed() {
      var evv = JSON.parse(localStorage.getItem('localEvent'))

      console.log(evv)

      this.loading = true

      let eventStoke = eventsActionTypes.getEventStatsLive

      if(evv.status == 'live'){
        eventStoke = eventsActionTypes.getEventStatsLive
      }

      this.$store.dispatch(eventStoke,{event_id:evv.id}).then((data) => {
        console.log('adasdas3')
        console.log(data)
        this.events_list = data.event_list
        this.loading = false;
      })

      
    },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}
.stat_chose_but {
  transition: 0.5s;
}
.stat_chose_but:hover{
  transition: 0.5s;
  background: #FFF9EC;
}
.stat_chose_but.active{
  background: #FCC341;
}

</style>

