import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import leagues from '@/store/modules/leagues'
import events from '@/store/modules/events'
import competitions from '@/store/modules/competitions'
import seasons from '@/store/modules/seasons'
import teams from '@/store/modules/teams'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { userInterface: null },
  mutations: {
    setUserInterface: (state, data) => state.userInterface = data,
    unSetUserInterface: (state) => state.userInterface = null,
    setUserType: (state, data) => state.userType = data,
  },
  actions: {},
  modules: {
    auth,
    leagues,
    events,
    competitions,
    seasons,
    teams
  }
})
