import competitionsApi from '@/api/competitions'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  competitions: null,
  validationErrors: null,
  competition: null
}

export const mutationTypes = {

  getCompetitionsStart: '[competitions] Get competitions start',
  getCompetitionsSuccess: '[competitions] Get competitions success',
  getCompetitionsFailure: '[competitions] Get competitions failure',

  getCompetitionsUniversalStart: '[competitions] Get competitions universal start',
  getCompetitionsUniversalSuccess: '[competitions] Get competitions universal success',
  getCompetitionsUniversalFailure: '[competitions] Get competitions universal failure',

  getCompetitionsUniversalQualStart: '[competitions] Get competitions universal qual start',
  getCompetitionsUniversalQualSuccess: '[competitions] Get competitions universal qual success',
  getCompetitionsUniversalQualFailure: '[competitions] Get competitions universal qual failure',

  getCompetitionsUniversalPlayoffStart: '[competitions] Get competitions universal playoff start',
  getCompetitionsUniversalPlayoffSuccess: '[competitions] Get competitions universal playoff success',
  getCompetitionsUniversalPlayoffFailure: '[competitions] Get competitions universal playoff failure',

  getCompetitionStart: '[competition] Get competition start',
  getCompetitionSuccess: '[competition] Get competition success',
  getCompetitionFailure: '[competition] Get competition failure',
}

export const competitionsActionTypes = {
  getCompetitions: '[competitions] Get competitions',
  getCompetitionsUniversal: '[competitions] Get competitions universal',
  getCompetitionsUniversalQual: '[competitions] Get competitions universal qual',
  getCompetitionsUniversalPlayoff: '[competitions] Get competitions universal playoff',
  getCompetition: '[competition] Get competition'
}

const mutations = {
  [mutationTypes.getCompetitionsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getCompetitionsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.competitions = payload
  },
  [mutationTypes.getCompetitionsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getCompetitionsUniversalStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getCompetitionsUniversalSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.competitions = payload
  },
  [mutationTypes.getCompetitionsUniversalFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getCompetitionsUniversalQualStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getCompetitionsUniversalQualSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.competitions = payload
  },
  [mutationTypes.getCompetitionsUniversalQualFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getCompetitionsUniversalPlayoffStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getCompetitionsUniversalPlayoffSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.competitions = payload
  },
  [mutationTypes.getCompetitionsUniversalPlayoffFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getCompetitionStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getCompetitionSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.competition = payload
  },
  [mutationTypes.getCompetitionFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

}

const actions = {
  [competitionsActionTypes.getCompetitions](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCompetitionsStart)
      competitionsApi
        .getCompetitions(data)
        .then(response => {
          context.commit(mutationTypes.getCompetitionsSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompetitionsFailure)
        })
    })
  },
  [competitionsActionTypes.getCompetitionsUniversal](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCompetitionsUniversalStart)
      competitionsApi
        .getCompetitionsUniversal(data)
        .then(response => {
          context.commit(mutationTypes.getCompetitionsUniversalSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompetitionsUniversalFailure)
        })
    })
  },
  [competitionsActionTypes.getCompetitionsUniversalQual](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCompetitionsUniversalQualStart)
      competitionsApi
        .getCompetitionsUniversalQual(data)
        .then(response => {
          context.commit(mutationTypes.getCompetitionsUniversalQualSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompetitionsUniversalQualFailure)
        })
    })
  },
  [competitionsActionTypes.getCompetitionsUniversalPlayoff](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCompetitionsUniversalPlayoffStart)
      competitionsApi
        .getCompetitionsUniversalPlayoff(data)
        .then(response => {
          context.commit(mutationTypes.getCompetitionsUniversalPlayoffSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompetitionsUniversalPlayoffFailure)
        })
    })
  },
  [competitionsActionTypes.getCompetition](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getCompetitionStart)
      competitionsApi
        .getCompetition(data)
        .then(response => {
          context.commit(mutationTypes.getCompetitionSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getCompetitionFailure)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations
}
