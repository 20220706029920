import axios from '@/api/axios'

const getLeagues = (data) => {
  return axios
        .post(`/api/getCountries`,{data:data})
        .then(response => response.data)
}

const getLeague = (data) => {
  return axios
        .post(`/api/getLeague`,{data:data})
        .then(response => response.data)
}

const getPopular = (data) => {
  return axios
        .post(`/api/getLeaguesPopular`,{data:data})
        .then(response => response.data)
}

export default {
  getLeagues,
  getLeague,
  getPopular
}
