import seasonsApi from '@/api/seasons'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  seasons: null,
  validationErrors: null,
  season: null
}

export const mutationTypes = {

  getSeasonsStart: '[seasons] Get seasons start',
  getSeasonsSuccess: '[seasons] Get seasons success',
  getSeasonsFailure: '[seasons] Get seasons failure',

  getSeasonStart: '[season] Get season start',
  getSeasonSuccess: '[season] Get season success',
  getSeasonFailure: '[season] Get season failure',
}

export const seasonsActionTypes = {
  getSeasons: '[seasons] Get seasons',
  getSeason: '[season] Get season'
}

const mutations = {
  [mutationTypes.getSeasonsStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getSeasonsSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.seasons = payload
  },
  [mutationTypes.getSeasonsFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getSeasonStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getSeasonSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.season = payload
  },
  [mutationTypes.getSeasonFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

}

const actions = {
  [seasonsActionTypes.getSeasons](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getSeasonsStart)
      seasonsApi
        .getSeasons(data)
        .then(response => {
          context.commit(mutationTypes.getSeasonsSuccess, response)
          console.log(response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getSeasonsFailure)
        })
    })
  },
  [seasonsActionTypes.getSeason](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getSeasonStart)
      seasonsApi
        .getSeasons(data)
        .then(response => {
          context.commit(mutationTypes.getSeasonSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getSeasonFailure)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations
}
