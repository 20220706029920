<template>
    <v-navigation-drawer app expand-on-hover v-bind:class="{ menactive: mob_menu_open }" class="pt-5 left-sidebar v-navigation-drawer v-navigation-drawer--open" 
    style="top:80px;width:322px; padding-left: 26px; padding-bottom: 100px; background-color:#EBEBEB;" mini-variant>

<v-layout justify-space-between column fill-height>
<v-list dense>

<v-list-item-group v-if="chosed_country && chosed_country.name" v-model="group" style="background-color: #fff; border-radius: 8px; padding: 12px 0px; margin-bottom: 16px;">

  <div style="display: flex; width:100%; padding: 12px 24px; font-size:12px; margin-bottom: 12px;border-bottom: 0.5px solid #E4E5E5; color:#838383; text-transform: uppercase;"> 
    <div class="menu-logo"><v-img :src="chosed_country.logo" class="" alt="" width="10"         contain
        max-height="16"
        min-height="16"></v-img></div>
    <div style="display: flex; justify-content: space-between; align-items: center; width:100%">
      <span style="padding-left: 8px;">{{chosed_country.name}} </span>
      <div style="cursor: pointer; font-size: 10px; color:rgb(131, 131, 131)" @click="chosed_country = []"> Сбросить </div>
    </div>
  </div>

  <v-list-item v-for="(num, index) in chosed_country.items" :key="`row-${index}`">
    <v-list-item-title>
      <div style="cursor: pointer;" @click="mob_menu_open = false, menu_event_id = num.id, setCompetition(num)" class="menu" v-bind:class="{ activemen: (num.id == menu_event_id) ? true : false }">
        <!-- <div class="menu-logo">
          <v-img :src="require('@/assets/'+num.logo+'')" class="" alt="" width="10"         contain
        max-height="16"
        min-height="16"></v-img>
        </div> -->
        <div class="menu-title">
          {{num.name}}
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>

</v-list-item-group>

<v-list-item-group v-model="group" style="background-color: #fff; border-radius: 8px; padding: 12px 0px; margin-bottom: 16px;">

  <div style="display: flex; width:100%; padding: 12px 24px; font-size:12px; margin-bottom: 12px;border-bottom: 0.5px solid #E4E5E5; color:#838383; text-transform: uppercase;"> 
    Популярные лиги 
  </div>
  
  <v-list-item v-for="(num, index) in popular_legue_list" :key="`row-${index}`">
    <v-list-item-title>
      <div style="cursor: pointer;" @click="chosed_country = {}, mob_menu_open =false, menu_event_id = num.id, setCompetition(num)" class="menu" v-bind:class="{ activemen: (num.id == menu_event_id) ? true : false }" >
        <div class="menu-logo">
          <v-img v-if="num.logo2 && !num.logo2.split('//dev')[1]" :src="require('@/assets/'+num.logo2+'')" class="" alt="" width="10"         contain
        max-height="16"
        min-height="16"></v-img>
        <v-img v-else :src="num.logo2" class="" alt="" width="10"         contain
        max-height="16"
        min-height="16"></v-img>
        </div>
        <div class="menu-title">
          {{num.name}}
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>

</v-list-item-group>

<v-list-item-group v-model="group" style="background-color: #fff; border-radius: 8px; padding: 12px 0px; margin-bottom: 16px;">

<div style="display: flex; width:100%; padding: 12px 24px; font-size:12px; margin-bottom: 12px;border-bottom: 0.5px solid #E4E5E5; color:#838383; text-transform: uppercase;"> 
  Страны
</div>

<v-list-item v-for="(num, index) in filteredList" :key="`row-${index}`">
    <v-list-item-title>
      <div class="lparent" style="display:flex; flex-direction:column; cursor: pointer;" @click="num.items_show = !num.items_show">

      <div class="menu" v-bind:class="{ active: num.items_show }" style="width:100%; justify-content: space-between;">
      
        <div class="mmenus" style="display: flex; align-items: center;">
          <div class="menu-logo">
            <v-img :src="'http://'+num.logo" class="" alt="" width="10"         contain
          max-height="16"
          min-height="16"></v-img>
          <!-- <img :src="num.logo" width="16px" height="16px"/> -->

          </div>
          <div class="menu-title">
            {{num.name}}
          </div>
        </div>

        <div v-if="num.items_show" style="float:right;">
          <v-img :src="require('@/assets/ar_down.svg')" class="" alt="" width="10"         contain
          max-height="12"
          min-height="12"></v-img>
        </div>
      </div>

      <div class="lchild" v-if="num.items_show" style="display:flex; flex-direction:column; transition: 0.6s;">
              <div v-for="(ch, index2) in num.items" :key="`row-ch-${index2}`">
                <div @click="mob_menu_open = false, chosed_country = {name:num.name,id:num.id,logo:num.logo,items:num.items}, setCompetition(ch)" class="menu"> 
                  {{ ch.name }}
                </div>
            </div>
          </div>

      </div>
    </v-list-item-title>
  </v-list-item>

<div v-if="countryListLimit < 20" @click="showAll(true)" style="
height:32px;
display:flex;
align-items:center;
justify-content:center;
width:90%;
border-radius: 6px;
cursor:pointer;
margin:0% 5%;
border: 0.5px solid #333;
">
<span style="margin-right: 8px;">
Смотреть все
</span>
<div  style="width:20px">
          <v-img :src="require('@/assets/ar_down.svg')" class="" alt="" width="15"         contain
          max-height="15"
          min-height="15"></v-img>
        </div>
</div>
<div v-else @click="showAll(false)" style="
height:36px;
display:flex;
align-items:center;
justify-content:center;
width:90%;
border-radius: 6px;
cursor:pointer;
margin:0% 5%;
border: 0.5px solid #333;
">
<span>
Скрыть
</span>
<div style="20px">
          <v-img :src="require('@/assets/ar_up.svg')" class="" alt="" width="15"         contain
          max-height="15"
          min-height="15"></v-img>
        </div>
</div>

</v-list-item-group>

</v-list>
</v-layout>
</v-navigation-drawer>
</template>

<script>
import {mapState} from "vuex";
import {eventBus} from '@/main'
import {leaguesActionTypes} from '@/store/modules/leagues'

export default {
  name: "Menu",
  ...mapState({
      isLoading: state => state.leagues.isLoading,
      leagues: state => state.leagues.data,
      validationErrors: state => state.leagues.validationErrors,
    }),
  data() {
    return {
      countryListLimit:10,
      menu_event_id:"",
      chosed_country:{},
      drawer: true,
      group: null,
      mob_menu_open:false,
      popular_legue_list: [
            {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Лига Чемпионов",
              "logo":"leagues/uefa_champions.png",
              "logo2":"leagues/uefa_champions.png",
              "season_id":"sr:season:106479",
              "league_type":"world",
              "id": "sr:competition:7",
              "category": {
                  "id": "sr:category:393",
                  "name": "International Clubs",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Лига Европы",
              "logo":"leagues/uefa_europe.png",
              "season_id":"sr:season:108353",
              "league_type":"world",
              "logo2":"leagues/uefa_europe.png",
              "id": "sr:competition:679",
              "category": {
                  "id": "sr:category:4",
                  "name": "International Clubs",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Лига Конференций",
              "logo":"leagues/uefa_conference.png",
              "season_id":"sr:season:106773",
              "league_type":"world",
              "logo2":"leagues/uefa_conference.png",
              "id": "sr:competition:34480",
              "category": {
                  "id": "sr:category:4",
                  "name": "International",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Чемпионат Европы",
              "logo":"leagues/euro2020.svg",
              "season_id":"sr:season:58428",
              "league_type":"world",
              "logo2":"leagues/euro2020.svg",
              "id": "sr:competition:1",
              "category": {
                  "id": "sr:category:4",
                  "name": "International",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Английская Премьер Лига",
              "logo":"leagues/epl.png",
              "logo2":"flags/england.png",
              "league_type":"national",
              "id": "sr:competition:17",
              "category": {
                  "id": "sr:category:1",
                  "name": "England",
                  "country_code": "ENG",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Итальянская Серия А",
              "logo":"leagues/serie.png",
              "logo2":"flags/italy.png",
              "league_type":"national",
              "id": "sr:competition:23",
              "category": {
                  "id": "sr:category:31",
                  "name": "Italy",
                  "country_code": "ITA",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Французская Лига 1",
              "logo":"leagues/league1.png",
              "logo2":"flags/france.png",
              "id": "sr:competition:34",
              "league_type":"national",
              "category": {
                  "id": "sr:category:7",
                  "name": "France",
                  "country_code": "FRA",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Немецкая Бундеслига",
              "logo":"leagues/bundesliga.png",
              "logo2":"flags/germany.png",
              "id": "sr:competition:35",
              "league_type":"national",
              "category": {
                  "id": "sr:category:30",
                  "name": "Germany",
                  "country_code": "DEU",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Испанская Ла лига",
              "logo":"leagues/laliga.png",
              "logo2":"flags/spain.png",
              "id": "sr:competition:8",
              "league_type":"national",
              "category": {
                  "id": "sr:category:32",
                  "name": "Spain",
                  "country_code": "ESP",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Российская Премьер лига",
              "logo":"leagues/rpl.png",
              "logo2":"flags/russia.png",
              "id": "sr:competition:203",
              "league_type":"national",
              "category": {
                  "id": "sr:category:32",
                  "name": "Russia",
                  "country_code": "RUS",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Премьер Дивизион",
              "logo":"leagues/argprim.png",
              "logo2":"flags/ARG.png",
              "id": "sr:competition:155",
              "league_type":"national",
              "category": {
                  "id": "sr:category:48",
                  "name": "Argentina",
                  "country_code": "ARG",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Бразильская Серия А",
              "logo":"leagues/braseria.png",
              "logo2":"flags/BRA.png",
              "id": "sr:competition:325",
              "league_type":"national",
              "category": {
                  "id": "sr:category:13",
                  "name": "Brazil",
                  "country_code": "BRA",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Американская МЛС",
              "logo":"leagues/usaleague.png",
              "logo2":"flags/USA.png",
              "id": "sr:competition:242",
              "league_type":"national",
              "category": {
                  "id": "sr:category:26",
                  "name": "USA",
                  "country_code": "USA",
                  "sport": "soccer"
              },
              "sport": "soccer"
          },
          {
              "gender": "men",
              "parent_id": null,
              "active":false,
              "name": "Саудовская Проф Лига",
              "logo":"leagues/sauleague.svg",
              "logo2":"flags/SAU.png",
              "id": "sr:competition:955",
              "league_type":"national",
              "category": {
                  "id": "sr:category:310",
                  "name": "SAU",
                  "country_code": "SAU",
                  "sport": "soccer"
              },
              "sport": "soccer"
          }
      ],
      country_legue_list: [
          {
              "parent_id": null,
              "logo":"flags/england.png",
              "name": "England",
              "name_ru": "Англия",
              "id": "EN",
              "items_show":true,
              "items": [
                {
                "name": "Premier League",
                "id": "sr:competition:17",
                "logo":"flags/england.png",
                "sport": "soccer"
              },
              {
                "name": "Championsip",
                "id": "sr:competition:19",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },
          {
              "parent_id": null,
              "logo":"flags/germany.png",
              "name": "Germany",
              "name_ru": "Германия",
              "id": "GER",
              "items_show":false,
              "items":[
                {
                "name": "Bundesliga",
                "id": "sr:competition:2",
                "logo":"flags/england.png",
                "sport": "soccer"
              },
              {
                "name": "Bundesliga 2",
                "id": "sr:competition:22",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },
          {
              "parent_id": null,
              "logo":"flags/france.png",
              "name": "Франция",
              "name_ru": "France",
              "id": "FR",
              "items_show":false,
              "items":[
                {
                "name": "Ligue 1",
                "id": "sr:competition:233",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },
          {
              "parent_id": null,
              "logo":"flags/spain.png",
              "name": "Испания",
              "name_ru": "Spain",
              "id": "SP",
              "items_show":false,
              "items":[
                {
                "name": "Laliga",
                "id": "sr:competition:233",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },
          {
              "parent_id": null,
              "logo":"flags/italy.png",
              "name": "Италия",
              "name_ru": "Italy",
              "id": "ITA",
              "items_show":false,
              "items":[
                {
                "name": "Seria A",
                "id": "sr:competition:233",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },
          {
              "parent_id": null,
              "logo":"flags/russia.png",
              "name": "Russia",
              "name_ru": "Россия",
              "id": "RU",
              "items_show":false,
              "items":[
                {
                "name": "Premier Ligue",
                "id": "sr:competition:2212",
                "logo":"flags/england.png",
                "sport": "soccer"
              },
              {
                "name": "Cup of Russia",
                "id": "sr:competition:21232",
                "logo":"flags/england.png",
                "sport": "soccer"
              }
            ]
          },

      ],
      country_list: [
          {
            id:1,
            show:true,
            event_name: 'Англия. Премьер лига',
            event_logo: 'ball.svg',
            type:"soccer",
          }
      ],
      filteredList:[]
    }
  },
  created () {
    eventBus.$on('showmenu', data => {
      console.log('showww '+data)
      if(this.mob_menu_open){
        this.mob_menu_open = false
      } else {
        this.mob_menu_open = true
      }
    })
  },
  mounted () {
    this.fetchFeed()
    this.fetchPopular()
  },
  methods:{
    setCompetition(item){
      this.menu_event_id = item.id
      console.log(item)
      localStorage.setItem('localCompetition',JSON.stringify(item))
      console.log(this.$route.path)
      console.log(this.$route.path.toString().split('guene'))
      if(this.$route.path.toString().split('guene').length > 1){
        this.$router.push({path: '/league/'+item.id, replace: true})
      } else {
        this.$router.push({path: '/leaguenext/'+item.id, replace: true})
      }
    },
    showAll(y){
      if(y){
        this.countryListLimit = 200
        this.filteredList = this.country_legue_list
      } else {
        this.countryListLimit = 10
        this.filteredList = this.country_legue_list.slice(0, this.countryListLimit);
      }
    },
    getLogo(name){
      var logoObj = {
        'UEFA Champions League':'@/assets/shape.svg',
        'Euro Cup':'@/assets/ball.svg'
      }
      return logoObj[name] ? logoObj[name] : '@/assets/shape.svg'
    },
    fetchFeed() {
      this.$store.dispatch(leaguesActionTypes.getLeagues).then((data) => {
        console.log(data)
        this.country_legue_list = data
        this.filteredList = data.slice(0, this.countryListLimit);
      })
    },
    fetchPopular() {
      this.$store.dispatch(leaguesActionTypes.getPopular).then((data) => {
        console.log(data)
        this.popular_legue_list = data
      })
    }
  }
}
</script>

<style scoped>
.menu {
  height: 35px;
  padding: 0px 24px; 
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
}

.router-link-active {
  /* background: #F1F1F1 !important; */
  /* border-right: 4px solid #629F33;
  border-left: 4px solid transparent; */
}

.router-link-active>.menu-logo {
  /* background: #629F33 !important; */
}

.router-link-active>.menu-title {
  /* color: #629F33 !important; */
}

.router-link-active:hover {
  background: #F1F1F1 !important;
}

.router-link-active:hover>.menu-logo {
  /* background: #629F33 !important; */
}

.router-link-active:hover>.menu-title {
  /* color: #629F33 !important; */
}

.v-list-item:hover {
  background: none !important;
}

.menu:hover {
  transition: 0.5s;
  background: #FFF9EC; 
}

.menu:hover>.menu-logo {
  transition: 0.4s;
  transform: scale(1.1);
}
.menu:hover>.mmenus>.menu-logo {
  transition: 0.4s;
  transform: scale(1.1);
}

.menu:hover>.menu-title {
  color: #000;
}

.v-list-item {
  padding: 0px 0px 2px 0px !important;
}

.v-list-item:before {
  background: none !important;
}

.menu-logo {
  width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #edf0f7;
    /* border-radius: 25px; */
    color: #fff;
    /* border: 1px solid #2D3648; */
}

.menu-title {
  padding-left: 8px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1A202C;
}
.v-navigation-drawer__content{
overflow: inherit!important;
}
.v-navigation-drawer::-webkit-scrollbar {
    width: 4px !important;
}
html {
  scrollbar-width: thin;
}
.lparent > .active {
background-color: #FCC341;
}

.theme--light >>> .v-navigation-drawer__border {
    display: none !important;
}

.left-sidebar >>> ::-webkit-scrollbar {
  width: 5px !important;
  background: transparent !important;
}
 
.left-sidebar >>> ::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important; */
  border-top: 0px;
}
 
/* .left-sidebar::-webkit-scrollbar-thumb {
  background-color: red !important;
  outline: 1px solid slategrey !important;
} */
.menactive {
  transform: translateX(0%) !important;
  visibility: visible !important;
  padding-left:12px !important;
}
.activemen {
  background: #FCC341;
}
</style>