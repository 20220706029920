<template>
 
  <v-row style="background-color: #fff; border-radius: 8px;">
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;"> Состав </span>
      </div>

      <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>

    </v-col>
  <!-- </v-row> -->

  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
      
      >
  <v-row v-if="events_list.length > 0">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; flex-direction: column;"
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">4-4-2</span>
        </div>
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">РАССТАНОВКА</span>
        </div>
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">4-4-2</span>
        </div>
      </div>


    </v-col>
    <v-col
      class="d-flex"
      cols="12"
      style="border-top: 0.5px solid rgb(228, 229, 229);cursor: pointer; flex-direction: column; padding:0px;"
    >

      <div style="border-bottom: 0.5px solid rgb(228, 229, 229);background-color:#F8F8F8; height:16px; display:flex; align-items:center; justify-content: center; width:100%; padding: 12px 12px;     height: 43px;">
  
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">СТАРТОВЫЕ СОСТАВЫ</span>
        </div>
     
      </div>

      <div style="display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 12px; ">
  
        <div v-if="events_list && events_list[0] && events_list[0].started" style="display:flex;  width:50%; flex-direction: column;">
          <div v-for="(stage, index) in events_list[0].started" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px;">

            <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

              <div style="display:flex; flex-direction: column; margin-left: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

                <div v-if="stage.substituted_out && events_list[0].substituted_in[stage.substituted_out_index]" style="display:flex; align-items:center;">
                  <div style="width: 15px;">
                      <v-img :src="require('@/assets/subst.svg')" class="" alt="замена" title="замена" contain  max-height="15"
                              min-height="15"></v-img>
                    </div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[0].substituted_in[stage.substituted_out_index].number }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[0].substituted_in[stage.substituted_out_index].name }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ events_list[0].substituted_in[stage.substituted_out_index].type }})</div>
                </div>

              </div>

          </div>
        </div>

        <div v-if="events_list && events_list[1] && events_list[1].started" style="display:flex;  width:50%; flex-direction: column; align-items: end;">
          <div v-for="(stage, index) in events_list[1].started" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px; ">

            

              <div style="display:flex; flex-direction: column; margin-right: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  
                  
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

                

                <div v-if="stage.substituted_out && events_list[1].substituted_in[stage.substituted_out_index]" style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ events_list[1].substituted_in[stage.substituted_out_index].type }})</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[1].substituted_in[stage.substituted_out_index].name }}</div>
                  <div style="width: 15px;">
                      <v-img :src="require('@/assets/subst.svg')" class="" alt="замена" title="замена" contain  max-height="15"
                              min-height="15"></v-img>
                    </div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[1].substituted_in[stage.substituted_out_index].number }}</div>
                 
                  
                </div>

              </div>

              <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

          </div>
        </div>

      </div>



    </v-col>

    <v-col
      class="d-flex"
      cols="12"
      style="border-top: 0.5px solid rgb(228, 229, 229);cursor: pointer; flex-direction: column; padding:0px;"
    >

      <div style="border-bottom: 0.5px solid rgb(228, 229, 229);background-color:#F8F8F8; height:16px; display:flex; align-items:center; justify-content: center; width:100%; padding: 12px 12px;     height: 43px;">
  
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">ЗАМЕНЁННЫЕ ИГРОКИ</span>
        </div>
     
      </div>

      <div style="display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 12px; ">
  
        <div v-if="events_list && events_list[0] && events_list[0].substituted_out" style="display:flex;  width:50%; flex-direction: column;">
          <div v-for="(stage, index) in events_list[0].substituted_out" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px;">

            <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

              <div style="display:flex; flex-direction: column; margin-left: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

                <div v-if="stage.substituted_out && events_list[0].substituted_in[stage.substituted_out_index]" style="display:flex; align-items:center;">
                  <div style="width: 15px;">
                      <v-img :src="require('@/assets/subst.svg')" class="" alt="замена" title="замена" contain  max-height="15"
                              min-height="15"></v-img>
                    </div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[0].substituted_in[stage.substituted_out_index].number }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[0].substituted_in[stage.substituted_out_index].name }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ events_list[0].substituted_in[stage.substituted_out_index].type }})</div>
                </div>

              </div>

          </div>
        </div>

        <div v-if="events_list && events_list[1] && events_list[1].substituted_out" style="display:flex;  width:50%; flex-direction: column; align-items: end;">
          <div v-for="(stage, index) in events_list[1].substituted_out" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px; ">

            

              <div style="display:flex; flex-direction: column; margin-right: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  
                  
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

                

                <div v-if="stage.substituted_out && events_list[1].substituted_in[stage.substituted_out_index]" style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ events_list[1].substituted_in[stage.substituted_out_index].type }})</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[1].substituted_in[stage.substituted_out_index].name }}</div>
                  <div style="width: 15px;">
                      <v-img :src="require('@/assets/subst.svg')" class="" alt="замена" title="замена" contain  max-height="15"
                              min-height="15"></v-img>
                    </div>
                  <div style="padding:3px; font-size:14px;color:#838383;">{{ events_list[1].substituted_in[stage.substituted_out_index].number }}</div>
                 
                  
                </div>

              </div>

              <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

          </div>
        </div>

      </div>



    </v-col>

    <v-col
      class="d-flex"
      cols="12"
      style="border-top: 0.5px solid rgb(228, 229, 229);cursor: pointer; flex-direction: column; padding:0px;"
    >

      <div style="border-bottom: 0.5px solid rgb(228, 229, 229);background-color:#F8F8F8; height:16px; display:flex; align-items:center; justify-content: center; width:100%; padding: 12px 12px;     height: 43px;">
  
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">ВЫШЕДШИЕ НА ЗАМЕНУ</span>
        </div>
     
      </div>

      <div style="display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 12px; ">
  
        <div v-if="events_list && events_list[0] && events_list[0].substituted_in" style="display:flex;  width:50%; flex-direction: column;">
          <div v-for="(stage, index) in events_list[0].substituted_in" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px;">

            <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

              <div style="display:flex; flex-direction: column; margin-left: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

        
              </div>

          </div>
        </div>

        <div v-if="events_list && events_list[1] && events_list[1].substituted_in" style="display:flex;  width:50%; flex-direction: column; align-items: end;">
          <div v-for="(stage, index) in events_list[1].substituted_in" :key="`row-${index}-${stage.id}`" style="display:flex; align-items:center; min-height: 52px; ">

            

              <div style="display:flex; flex-direction: column; margin-right: 20px;">

                <div style="display:flex; align-items:center;">
                  <div style="padding:3px; font-size:14px;">{{ stage.name }}</div>
                  <div style="padding:3px; font-size:14px;">
                    <div style="width: 20px;">
                      <v-img :src="stage.country_logo" class="" :alt="stage.country" :title="stage.country" contain  max-height="24"
                              min-height="24"></v-img>
                    </div>
                  </div>
                  
                  
                  <div style="padding:3px; font-size:14px;color:#838383;">({{ stage.type }})</div>
                </div>

                

    

              </div>

              <div style="padding:3px; font-size:14px; ">{{ stage.number }}</div>

          </div>
        </div>

      </div>



    </v-col>
  <!-- </vue-slide-up-down> -->

  </v-row>

  <v-row v-if="events_list.length == 0">
    <v-col
      class="d-flex"
      cols="12"
      style="padding:16px 24px; "
    >
    Состав матча ожидается
    </v-col>
  </v-row>

</v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'

export default {

name: 'EventH2H',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    events_list: []
  }
},
computed: {
  ...mapState({
    isLoading: state => state.event.isLoading,
    event: state => state.event.data,
    validationErrors: state => state.event.validationErrors,
  }),
  headers() {
    return [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'start_time',
        width: '10%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'competitor_1_name',
        width: '40%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'period_1_home_score',
        width: '40%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'winner_id',
        width: '10%'
      },
    ];

  },
},
watch: {
},
mounted() {
  this.fetchFeed()
},
methods: {
  getDateTime(timestamp){
    return this.CalcTime(new Date(timestamp).getDate()) + '.' + this.CalcTime(new Date(timestamp).getMonth()) + ' ' + this.CalcTime(new Date(timestamp).getHours()) + '.' + this.CalcTime(new Date(timestamp).getMinutes())
  },
  CalcTime(time){
    return (+time < 10) ? '0'+time : time 
  },
  fetchFeed() {
      var evv = JSON.parse(localStorage.getItem('localEvent'))

      console.log(evv)

      this.loading = true

      console.log('adasdas2')

      this.$store.dispatch(eventsActionTypes.getEventTeam,{event_id:evv.id}).then((data) => {
        console.log('adasdas')
        console.log(data)
        this.events_list = data.event_list
        this.loading = false;
      })

      
    },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}
</style>

