import axios from '@/api/axios'

const getCompetitions = (data) => {
  return axios
        .post(`/api/getCompetitionTable`,{data:data})
        .then(response => response.data)
}

const getCompetitionsUniversal = (data) => {
  return axios
        .post(`/api/getUniversalTable`,{data:data})
        .then(response => response.data)
}

const getCompetitionsUniversalQual = (data) => {
  return axios
        .post(`/api/getUniversalTableQual`,{data:data})
        .then(response => response.data)
}

const getCompetitionsUniversalPlayoff = (data) => {
  return axios
        .post(`/api/getUniversalTablePlayoff`,{data:data})
        .then(response => response.data)
}

const getCompetition = (data) => {
  return axios
        .post(`/api/getCompetition`,{data:data})
        .then(response => response.data)
}


export default {
  getCompetitions,
  getCompetition,
  getCompetitionsUniversal,
  getCompetitionsUniversalQual,
  getCompetitionsUniversalPlayoff
}
