import axios from '@/api/axios'

const getTeam = (data) => {
  return axios
        .post(`/api/getTeamById`,{data:data})
        .then(response => response.data)
}

const getTeamPlayers = (data) => {
  return axios
        .post(`/api/teamPlayers`,{data:data})
        .then(response => response.data)
}

const getTeamTransfers = (data) => {
  return axios
        .post(`/api/teamTransfers`,{data:data})
        .then(response => response.data)
}


export default {
  getTeam,
  getTeamPlayers,
  getTeamTransfers
}
