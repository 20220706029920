<template>
 
    <v-row style="background-color: #fff; border-radius: 8px;">
      <v-col
        class="d-flex"
        cols="12"
        style="align-items:center; height: 56px;"
      >
        <div style="display: flex; align-items: center; ">
          <span style="font-size: 22px; font-weight: 500; padding-left: 12px;">События </span>
        </div>

      </v-col>
    <!-- </v-row> -->
    <v-col
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        v-if="events_list && events_list.length > 0 && events_list[0]"
        >
    <v-row v-for="(num, index) in events_list" :key="`row-${num.id}`">
      <v-col
        class="d-flex"
        cols="12"
        style="cursor: pointer; background-color:#F8F8F8; "
        @click="slideToggle(index,num.id)"
      >
        <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
          <div style="display:flex; align-items:center;">
            <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ num.event_name }}</span>
          </div>
          <div style="display:flex; align-items: center;">
            <v-img v-if="num.show" :src="require('@/assets/ar_up.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
            <v-img v-else :src="require('@/assets/ar_down.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
          </div>
        </div>
      </v-col>
      <!-- <vue-slide-up-down :active="num.show"> -->
      <v-col
        v-if="num.show"
        class="d-flex"
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        :id="`slide-${num.id}`"
      >
      <v-data-table
          :headers="headers"
          :items="num.items"
          item-key="id"

          :hide-default-footer="true"
          class="elevation-1"
          style="width:100%;"
        >
        <template v-slot:item.event_date="{item}">
          <div style="width:20%; display: flex; align-items:center;" :id="'event-id-'+id">
              <span style="color:#838383; font-size:14px;"> {{ item.event_date }}</span>
              <span style="color:#838383; font-size:14px; padding-left: 10px;"> {{ item.event_time }}</span>
          </div>
        </template>
        <template v-slot:item.name="{item}">
          <div style="display: flex; align-items: center;" >
            <div style="width:10%;height:100%;display: flex; align-items:center; padding:2px 0px; height:64px;" >
              <div v-for="team in item.teams" :key="`team-${team.id}`" style="display: flex;align-items: center; margin:2px 0px;">
                <div style="cursor:pointer; width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;" @click="setTeam(team.id)">
                  <v-img v-if="team.logo && team.logo.length > 0" :src="team.logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                </div>
              </div>
            </div>
            <div style="width:100%;height:100%;display: flex;flex-direction: column; padding:2px 0px; height:64px;" >
              <div v-for="team in item.teams" :key="`team-${team.id}`" style="cursor:pointer; display: flex;align-items: center; margin:2px 0px;" @click="setTeam(team.id)">
                <span> {{ team.name }}</span>
              </div>
            </div>
        </div>
        </template>
  
      </v-data-table>
      </v-col>
    <!-- </vue-slide-up-down> -->

    </v-row>
  </v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'LeagueEvents',
  components: {
    //VueDatePicker
    //McvValidationErrors
  },
  data() {
    return {
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      loading:true,
      events_list:[]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.events.isLoading,
      events: state => state.events.data,
      validationErrors: state => state.events.validationErrors,
    }),
    // computedStyle() { 
    //   return {
    //     'background-color': '#fff'
    //   }
    // },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'event_date',
          width: '10%'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '70%'
        },
      ];

    },
  },
  watch: {
  },
  mounted() {
    this.fetchFeed()
  },
  methods: {
    setTeam(id){
    if(this.$route.path.toString().split('mnex').length > 1){
      this.$router.push({path: '/team/'+id, replace: true})
    } else {
      this.$router.push({path: '/teamnext/'+id, replace: true})
    }
    },
    slideToggle(ind,id){
      console.log(ind)
      console.log(id)
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    fetchFeed() {
      var team = this.$route.params.slug.toString()
      this.$store.dispatch(eventsActionTypes.getTeamEvents,{competitor_id:team}).then((data) => {
        console.log(data)
        this.events_list = data
        this.loading = false;
      })
  },
  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
  border-bottom:3px solid #FCC341; 
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-image: var(--bgImage);
  background-repeat: no-repeat;
  background-position: 95% 42%;
    background-size: 50% 217%;
}

.demo-content {
  position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}

</style>

