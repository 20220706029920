<template>
 
    <v-row style="background-color: #fff; border-radius: 8px;">
      <v-col
        class="d-flex"
        cols="12"
        style="align-items:center; height: 56px;"
      >
        <div style="display: flex; align-items: center; ">
          <span style="font-size: 22px; font-weight: 500; padding-left: 12px;">События </span>
          <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>
        </div>

      </v-col>
    <!-- </v-row> -->
    <v-col
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        v-if="events_list && events_list.length > 0"
        >
    <v-row v-for="(num, index) in events_list" :key="`row-${num.id}`">
      <v-col
        class="d-flex"
        cols="12"
        style="cursor: pointer; background-color:#F8F8F8; "
        
      >
        <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
          <div style="display:flex; align-items:center;">
            <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ num.event_name }}</span>
          </div>
          <div style="display:flex; align-items: center;" @click="slideToggle(index,num.id)">
            <v-img v-if="num.show" :src="require('@/assets/ar_up.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
            <v-img v-else :src="require('@/assets/ar_down.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img>
          </div>
        </div>
      </v-col>
      <!-- <vue-slide-up-down :active="num.show"> -->
      <v-col class="d-flex" style="flex-direction:column;" cols="12" v-for="(num2, index2) in num.group_items" :key="`row-${num2.id}`" v-if="num.show" @click="slideToggle2(index,index2)">
        <div style="height:30px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; cursor: pointer; background-color:#F8F8F8; ">
          <div style="display:flex; align-items:center;">
            <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">ТУР {{num2[0].event_round}}</span>
          </div>
        </div>
        <v-data-table
          v-if="num2[0].show"
          :headers="headers"
          :items="num2"
          item-key="id"
          :hide-default-footer="true"
          class="elevation-1"
          style="width:100%;"
          disable-pagination
        >
        <template v-slot:item.event_date="{item}">
          <div @click="setEvent(num,item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
              <span style="color:#838383; font-size:14px;"> {{ item.event_date }}</span>
              <span style="color:#838383; font-size:14px; padding-left: 10px;"> {{ item.event_time }}</span>
          </div>
        </template>
        <template v-slot:item.name="{item}">
          <div style="display: flex; align-items: center; cursor:pointer; " @click="setEvent(num,item)">
            <div style="height:100%;display: flex; align-items:center; padding:2px 0px; height:64px;" >
              <div v-for="team in item.teams" :key="`team-${team.id}`" style="display: flex;align-items: center; margin:2px 0px;">
                <div @click="setTeam(team)" style="cursor:pointer;width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                  <!-- <v-img v-if="team.competitor_logo && team.competitor_logo.length > 3" :src="'https://'+team.competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/'+team.logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img> -->

                  <v-img v-if="team.competitor_logo && team.competitor_logo.length > 3" :src="team.competitor_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                </div>
              </div>
            </div>
            <div style="height:100%;display: flex;flex-direction: column; margin-left:20px;padding:2px 0px; height:64px;" >
              <div @click="setTeam(team)" v-for="team in item.teams" :key="`team-${team.id}`" style="cursor:pointer;display: flex;align-items: center; margin:2px 0px;">
                <span> {{ team.name }}</span>
              </div>
            </div>
        </div>
        </template>
        <template v-slot:item.status="{item}">
          <div v-if="item.status == 'closed'" @click="setEvent(num,item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
              <span style="color:#838383; font-size:14px;"> {{ item.result }}</span>
          </div>
        </template>
      </v-data-table>
      </v-col>  
      <!-- <v-col
        v-if="num.show"
        class="d-flex"
        cols="12"
        style="border-top:0.5px solid #E4E5E5;"
        :id="`slide-${num.id}`"
      >
      <v-data-table
          :headers="headers"
          :items="num.items"
          item-key="id"
          :hide-default-footer="true"
          class="elevation-1"
          style="width:100%;"
          disable-pagination
        >
        <template v-slot:item.event_date="{item}">
          <div @click="setEvent(num,item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
              <span style="color:#838383; font-size:14px;"> {{ item.event_date }}</span>
              <span style="color:#838383; font-size:14px; padding-left: 10px;"> {{ item.event_time }}</span>
          </div>
        </template>
        <template v-slot:item.name="{item}">
          <div style="display: flex; align-items: center; cursor:pointer; " @click="setEvent(num,item)">
            <div style="height:100%;display: flex; align-items:center; padding:2px 0px; height:64px;" >
              <div v-for="team in item.teams" :key="`team-${team.id}`" style="display: flex;align-items: center; margin:2px 0px;">
                <div @click="setTeam(team)" style="cursor:pointer;width:28px; height:28px; padding: 5px; border-radius: 50px; background: #F8F8F8;"><v-img :src="require('@/assets/'+team.logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img></div>
              </div>
            </div>
            <div style="height:100%;display: flex;flex-direction: column; margin-left:20px;padding:2px 0px; height:64px;" >
              <div @click="setTeam(team)" v-for="team in item.teams" :key="`team-${team.id}`" style="cursor:pointer;display: flex;align-items: center; margin:2px 0px;">
                <span> {{ team.name }}</span>
              </div>
            </div>
        </div>
        </template>
        <template v-slot:item.status="{item}">
          <div v-if="item.status == 'closed'" @click="setEvent(num,item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
              <span style="color:#838383; font-size:14px;"> {{ item.result }}</span>
          </div>
        </template>
      </v-data-table>
      </v-col> -->
    <!-- </vue-slide-up-down> -->

    </v-row>
  </v-col>

  <v-col v-else>
    <span v-if="!loading">Ближайших событий не найдено</span>
  </v-col>
  
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'
import {eventBus} from '@/main'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'LeagueEvents',
  components: {
    //VueDatePicker
    //McvValidationErrors
  },
  data() {
    return {
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      loading:true,
      events_list: [],
      events_list2: [
          {
            id:1,
            show:true,
            event_name: 'Тур 3 из 30',
            event_logo: 'teams/chelsea.png',
            type:"soccer",
            items: [
              {
                id:1,
                name: 'Манчестер Юнайтед - Челси',
                result: "0:0",
                event_time:"18:00",
                event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Манчестер Юнайтед",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Челси",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  }
                ]
              },
              {
                id:2,
                name: 'Тоттенхэм Хотспур - Лестер',
                result: "2:0",
                event_time:"18:00",
                event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Тотенхем",
                    result:"2",
                    result_1:"1",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Лестер",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  }
                ],
                time:"57",
                time_description:"2 тайм",
                status:"Live"
              },
              {
                id:3,
                name: 'Эвертон - Манчестер сити',
                result: "1:4",
                event_time:"18:00",
                event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Эвертон",
                    result:"1",
                    result_1:"1",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Манчестер сити",
                    result:"4",
                    result_1:"1",
                    penalty:[]
                  }
                ],
                time:"97",
                time_description:"Завершён",
                status:"ended"
              },
              {
                id:4,
                name: 'Ливерпуль - Арсенал',
                result: "-",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Ливерпуль",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Арсенал",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  }
                ],
                time:"-",
                time_description:"Перенесён",
                status:"delayed"
              },
              {
                id:5,
                name: 'Астон Вилла - Фулхэм',
                result: "-",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Астон Вилла",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Фулхэм",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  }
                ],
                time:"-",
                time_description:"12:00",
                status:"sheduled"
              },
              {
                id:6,
                name: 'Манчестер Юнайтед - Челси',
                result: "0:0",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Манчестер Юнайтед",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Челси",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  }
                ],
                time:"45",
                time_description:"Перерыв",
                status:"Live"
              },
              {
                id:7,
                name: 'Шеффилд Юнайтед - Бёрнли',
                result: "1:1",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Шеффилд Юнайтед",
                    result:"1",
                    result_1:"0",
                   penalty_result:"3",
penalty: [
                      true,
                      false,
                      true,
                      true,
                      'no'
                    ],
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Бёрнли",
                    result:"1",
                    result_1:"1",
                   penalty_result:"2",
penalty: [
                      true,
                      false,
                      true,
                      'no',
                      'no'
                    ],
                  }
                ],
                time:"-",
                time_description:"С.П.",
                status:"Live"
              },
              {
                id:8,
                name: 'Ливерпуль - Арсенал',
                result: "-",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Ливерпуль",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Арсенал",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  }
                ],
                time:"-",
                time_description:"Отменён",
                status:"canceled"
              },
            ],
            date:"19.09.2023"
          },
          {
            id:2,
            show:false,
            event_name: 'тур 4 из 30',
            event_logo: 'teams/chelsea.png',
            type:"soccer",
            items: [
              {
                id:1,
                name: 'Манчестер Юнайтед - Челси',
                result: "0:0",
                event_time:"18:00",
                event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Манчестер Юнайтед",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Челси",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  }
                ],
                time:"15",
                time_description:"1 тайм",
                status:"Live"
              },
              {
                id:2,
                name: 'Тоттенхэм Хотспур - Лестер',
                result: "2:0",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Тотенхем",
                    result:"2",
                    result_1:"1",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Лестер",
                    result:"0",
                    result_1:"0",
                    penalty:[]
                  }
                ],
                time:"57",
                time_description:"2 тайм",
                status:"Live"
              },
              {
                id:3,
                name: 'Эвертон - Манчестер сити',
                result: "1:4",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Эвертон",
                    result:"1",
                    result_1:"0",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Манчестер сити",
                    result:"4",
                    result_1:"2",
                    penalty:[]
                  }
                ],
                time:"97",
                time_description:"Завершён",
                status:"ended"
              },
              {
                id:4,
                name: 'Ливерпуль - Арсенал',
                result: "-",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Ливерпуль",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Арсенал",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  }
                ],
                time:"-",
                time_description:"Перенесён",
                status:"delayed"
              },
              {
                id:5,
                name: 'Астон Вилла - Фулхэм',
                result: "-",
                event_time:"18:00",
            event_date:"25.09",
                teams:[
                  {
                    id:1,
                    logo:"teams/chelsea.png",
                    name:"Астон Вилла",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  },
                  {
                    id:2,
                    logo:"teams/chelsea.png",
                    name:"Фулхэм",
                    result:"-",
                    result_1:"-",
                    penalty:[]
                  }
                ],
                time:"-",
                time_description:"12:00",
                status:"sheduled"
              },
            ],
            date:"19.09.2023"
          }
        ]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.events.isLoading,
      events: state => state.events.data,
      validationErrors: state => state.events.validationErrors,
    }),
    // computedStyle() { 
    //   return {
    //     'background-color': '#fff'
    //   }
    // },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'event_date',
          width: '100px'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '70%'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'status',
          width: '10%'
        },
      ];

    },
  },
  watch: {
  },
  created () {
    eventBus.$on('getevents', data => {
      console.log('getevents1 '+data)
        this.fetchFeed(data)
    })
  },
  mounted() {
    this.fetchFeed()
  },
  methods: {
    setEvent(league,event){

      console.log(league)
    console.log(event)
      var eventItem = {}
      eventItem.league_logo = league.event_logo
      eventItem.league_id = league.id
      eventItem.league_name = league.event_name.split(',')[1]
      eventItem.league_country = league.event_name.split(',')[0]
      eventItem.id = event.id
      eventItem.name = event.name
      eventItem.result = event.result
      eventItem.status = event.status
      eventItem.date = event.event_date
      eventItem.time = event.time_description
      eventItem.team_1_id = event.teams[0].id
      eventItem.team_1_name = event.teams[0].name
      eventItem.team_1_logo = event.teams[0].logo
      eventItem.team_1_result = event.teams[0].result 
      eventItem.team_1_result_1 = event.teams[0].result_1
      eventItem.team_2_id = event.teams[1].id
      eventItem.team_2_name = event.teams[1].name
      eventItem.team_2_logo = event.teams[1].logo
      eventItem.team_2_result = event.teams[1].result 
      eventItem.team_2_result_1 = event.teams[1].result_1
      console.log(eventItem)
      localStorage.setItem('localEvent',JSON.stringify(eventItem))
      if(this.$route.path.toString().split('ntnex').length > 1){
        this.$router.push({path: '/event/'+eventItem.id, replace: true})
      } else {
        this.$router.push({path: '/eventnext/'+eventItem.id, replace: true})
      }
      },
    setTeam(item){
      localStorage.setItem('localTeam',JSON.stringify(item))
      console.log(item)
      if(this.$route.path.toString().split('mnex').length > 1){
        this.$router.push({path: '/team/'+item.id, replace: true})
      } else {
        this.$router.push({path: '/teamnext/'+item.id, replace: true})
      }
  },
    slideToggle(ind,id){
      console.log(ind)
      console.log(id)
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    slideToggle2(ind,ind2){
      console.log(ind)
      this.events_list[ind].group_items[ind2][0].show = this.events_list[ind].group_items[ind2][0].show ? false : true 
    },
    fetchFeed(season) {
      var evv = JSON.parse(localStorage.getItem('localCompetition'))
      console.log(season)
      this.loading = true
      this.$store.dispatch(eventsActionTypes.getLeagueEvents,{competition_id:evv.id,season_id:season?season.season:null}).then((data) => {
        console.log(data)
        this.events_list = data.events_list
        this.loading = false;
      })

      
    },

  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
  border-bottom:3px solid #FCC341; 
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-image: var(--bgImage);
  background-repeat: no-repeat;
  background-position: 95% 42%;
    background-size: 50% 217%;
}

.demo-content {
  position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}

</style>

