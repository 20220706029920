<template>
  <v-container style="background-color: #EBEBEB; border-radius: 8px; max-width:100% !important;">

    <v-row class="demo-wrap" :style="{'--bgImage': bgImage}" style=" border: 0.5px solid #E4E5E5;  background-color: #fff;margin-bottom: 16px; border-radius: 8px;">
      <!--height: 264px;-->
      <v-col
        class="d-flex demo-content"
        cols="12"
        style="flex-direction: column; padding: 24px 24px 0px 24px;"
        >
        <div class="d-flex mob_size_span0" style="align-items:center;">
          <div style="width:25px; cursor: pointer;" @click="$router.push({path: '/main', params: {}})">
            <v-img :src="require('@/assets/sc_home.svg')" class="" alt="" contain  max-height="24"
            min-height="24"></v-img></div>
          <span style="padding-left:10px;"> / </span>
          <div style="padding-left:10px; font-size: 14px; color: #747780;">{{ league_data.name }}</div>
        </div>

        <div class="d-flex to_column" style="align-items: center; margin:20px 0px;">
          <div style="width:128px; cursor: pointer;">
            <v-img v-if="league_data.logo && !league_data.logo.split('//dev')[1]" :src="require('@/assets/'+league_data.logo+'')" class="" alt="" contain  max-height="128"
            min-height="128"></v-img>
            <v-img v-else-if="league_data.logo && league_data.logo.split('//dev')[1]" :src="league_data.logo" class="" alt="" contain  max-height="128"
            min-height="128"></v-img>
            <v-img v-else :src="require('@/assets/ball.svg')" class="" alt="" contain  max-height="128"
            min-height="128"></v-img>
          </div>
          <div class="d-flex" style="flex-direction:column; padding:0px 0px 0px 20px; position: relative;">
              <span class="mob_size_span3" style="font-size: 22px; color: #333; font-weight: 500;">{{ league_data.name }}</span>

              <div @click="showSeasonList = showSeasonList ? false : true" 
              style="display:flex; align-items:center; justify-content:space-between; cursor:pointer; border:0.5px solid #E4E5E5; border-radius: 6px; background-color: #fff; height: 36px; width:142px; padding: 1px 10px;">
                <div>сезон {{ league_season.year }}</div>
                <div>
                    <v-img v-if="showSeasonList" :src="require('@/assets/ar_up.svg')" class="" alt="" contain  max-height="24"
                    min-height="24"></v-img>
                    <v-img v-else :src="require('@/assets/ar_down.svg')" class="" alt="" contain  max-height="24"
                    min-height="24">
                    </v-img>
                </div>
              </div>
              <div v-if="showSeasonList" style="transition:0.5s; cursor:pointer; top: 77px;border:0.5px solid #E4E5E5; border-radius: 6px; background-color: #fff; height: auto; width:142px; padding: 5px 5px; position: absolute;">
                <div v-for="dateItem in competitionDates" :key="`team-${dateItem.season_id}`" style="display: flex;align-items: center; padding: 5px; ">
                    <div @click="showSeasonList = false, changeSeason(dateItem)">сезон {{ dateItem.year }}</div>
                </div>  
              </div>
          </div>
        </div>

        <div class="d-flex blcks" style="align-items: center; flex-wrap: wrap;">
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Events'" v-bind:class="{ active: leagueContent == 'Events' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>События</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Results'" v-bind:class="{ active: leagueContent == 'Results' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Результаты</span>
          </div>
          <div v-if="league_data.league_type == 'world'" class="d-flex" @click="newLeageu = false, leagueContent = 'Table'" v-bind:class="{ active: leagueContent == 'Table' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Таблица</span>
          </div>
          <div v-if="league_data.league_type != 'world'" class="d-flex" @click="newLeageu = false, leagueContent = 'TableNational'" v-bind:class="{ active: leagueContent == 'TableNational' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Таблица</span>
          </div>
          <div class="d-flex" @click="newLeageu = false, leagueContent = 'Scorers'" v-bind:class="{ active: leagueContent == 'Scorers' }" style="cursor:pointer;align-items: center; justify-content: center; padding: 16px;">
            <span>Бомбардиры</span>
          </div>
        </div>

      </v-col>

    </v-row>

    <LeagueEvents v-if="leagueContent == 'Events'" />

    <LeagueResults v-if="leagueContent == 'Results'" />

    <LeagueTable v-if="leagueContent == 'Table'" />

    <LeagueScorers v-if="leagueContent == 'Scorers'" />

    <LeagueTableNational v-if="leagueContent == 'TableNational'" />

    <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
        
  </v-sheet>
  </v-container>


</template>

<script>
import {mapState} from "vuex";
import {seasonsActionTypes} from '@/store/modules/seasons'
import LeagueEvents from '@/views/League/LeagueEvents.vue';
import LeagueResults from '@/views/League/LeagueResults.vue';
import LeagueTable from '@/views/League/LeagueTable.vue';
import LeagueScorers from '@/views/League/LeagueScorers.vue';
import LeagueTableNational from '@/views/League/LeagueTableNational.vue';
import {eventBus} from '@/main'

export default {
  inject: {
      theme: {
        default: { isDark: false },
      },
    },
  name: 'League',
  // props: ['league_id','league_value_name'],
  components: {
    LeagueEvents,
    LeagueResults,
    LeagueTable,
    LeagueTableNational,
    LeagueScorers
  },
  data() {
    return {
      showSeasonList: false,
      newLeageu:false,
      league_data_def:[
        {
          name:'Английская премьер лига',
          league_id:'sr:competition:17',
          league_type:'national',
        },
        {
          name:'Лига чемпионов',
          league_id:'sr:competition:1',
          league_type:'world',
        },
        {
          name:'Лига европы',
          league_id:'sr:competition:7',
          league_type:'world',
        }
      ],
      league_data:{
        name:'',
        league_id:'',
        league_type:'',
      },
      league_season:{year:"23/24",season_id:1},
      loading:false,
      leagueContent:'Events',
      //bgImage:"url('@/assets/apl.png')",
      bgImage:`url(${require('../../assets/apl.png')})`,
      //bgImage:0.6,
      dateRangeText:new Date(),
      format:'DD.MM dd',
      id: "",
      name:"",
      status:"",
      result:"",
      date_receipt_id:"",
      status_id: "",
      shop_name:"",
      user_data:"",
      city:"",
      created:"",
      payments:"",
      sports_list_locale:{
        "soccer":"Футбол",
        "tennis":"Теннис",
        "icehockey":"Хоккей",
      },
      competitionDates:[
        '2022/2023',
         '2021/2022'
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.seasons.isLoading,
      seasons: state => state.seasons.data,
      validationErrors: state => state.seasons.validationErrors,
    }),
    // computedStyle() { 
    //   return {
    //     'background-color': '#fff'
    //   }
    // },
  },
  watch: {

  },
  created(){
    // console.log(this.$route.params.slug.toString())
  },
  mounted() {
    this.league_data = JSON.parse(localStorage.getItem('localCompetition'))

    console.log(this.league_data)

    if(this.$route.params.slug.toString().split(':tab').length > 1){
      console.log('da')
      this.leagueContent = 'TableNational'
    }

    this.fetchFeed()
  },
  methods: {
    changeSeason(dateItem){
    console.log(this.league_season) 
    console.log(this.leagueContent) 
    this.league_season.year = dateItem.year
    this.league_season.season_id = dateItem.season_id
    if(this.leagueContent == 'Events') {
      eventBus.$emit('getevents', {
        season: dateItem.season_id,
      })
    }
    if(this.leagueContent == 'Results') {
      eventBus.$emit('getresults', {
        season: dateItem.season_id,
      })
    }
    if(this.leagueContent == 'Table') {
      eventBus.$emit('gettable', {
        season: dateItem.season_id,
      })
    }
    if(this.leagueContent == 'TableNational') {
      eventBus.$emit('gettablenational', {
        season: dateItem.season_id,
      })
    }
    if(this.leagueContent == 'Scorers') {
      eventBus.$emit('getscorers', {
        season: dateItem.season_id,
      })
    }
    },
    // getIn(){
    //   console.log(this.$route.params.slug.toString())
    // },
    slideToggle(ind,id){
      console.log(ind)
      console.log(id)
      this.events_list[ind].show = this.events_list[ind].show ? false : true 
    },
    fetchFeed() {
      var evv = JSON.parse(localStorage.getItem('localCompetition'))
      console.log(evv)
      this.$store.dispatch(seasonsActionTypes.getSeasons,{competition_id:evv.id}).then((data) => {
        console.log(data)
        this.competitionDates = data
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>
.blcks>.d-flex:hover {
  transition: 0.4s;
  background: #FFF9EC;
}
.blcks>.active {
  border-bottom:3px solid #FCC341;
  background: #FFF9EC; 
}

.demo-wrap {
  position: relative;
}

.demo-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-image: var(--bgImage);
  background-repeat: no-repeat;
  background-position: 95% 42%;
    background-size: 50% 217%;
}

.demo-content {
  position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}
.theme--light.v-text-field >>> .v-input__control >>> .v-input__slot:before {
  display: none !important;
}
.year_check >>> .v-input__slot:before {
  display: none !important;
}

.v-text-field >>> .v-input__slot:after {
  display: none !important;
}

.v-text-field >>> .v-input__slot:before {
  display: none !important;
}

</style>

