import axios from '@/api/axios'

const getEvents = (data) => {
  return axios
        .post(`/api/getEvents`,{data:data})
        .then(response => response.data)
}

const getEvent = (data) => {
  return axios
        .post(`/api/getEvent`,{data:data})
        .then(response => response.data)
}

const getLeagueEvents = (data) => {
  return axios
        .post(`/api/getLeagueEvents`,{data:data})
        .then(response => response.data)
}

const getTeamEvents = (data) => {
  return axios
        .post(`/api/getTeamEvents`,{data:data})
        .then(response => response.data)
}

const getEventInfo = (data) => {
  return axios
        .post(`/api/getEventLiveInfo`,{data:data})
        .then(response => response.data)
}

const getEventH = (data) => {
  return axios
        .post(`/api/getEventH2H`,{data:data})
        .then(response => response.data)
}

const getEventStats = (data) => {
  return axios
        .post(`/api/getEventStats`,{data:data})
        .then(response => response.data)
}

const getEventStatsLive = (data) => {
  return axios
        .post(`/api/getEventLiveStats`,{data:data})
        .then(response => response.data)
}

const getEventTeam = (data) => {
  return axios
        .post(`/api/getEventTeam`,{data:data})
        .then(response => response.data)
}

export default {
  getEvents,
  getEvent,
  getLeagueEvents,
  getTeamEvents,
  getEventInfo,
  getEventH,
  getEventStats,
  getEventTeam,
  getEventStatsLive
}
