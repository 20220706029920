<template>
  <v-app-bar
      app
      color="#fff"
      flat
      class="header-layout"
      height="80px"
      style="left:0px;"
  >
    <v-container class="py-0 fill-height d-flex justify-space-between" style="width:100%; max-width: 100% !important;">
      <div class="header-actions" style="width:100%;">
        <div class="d-flex align-center" style="width:100%; justify-content: space-between;">
          <!-- <div style="width:100%; left:0px !important; height:100px; z-index:20; display:flex; align-items:center; padding:15px;"> -->
                <div class="mob_logo" @click="goMain()" style="width:141px; height:48px; display:flex; align-items:center;box-sizing:border-box;">
                  <v-img :src="require('@/assets/sc_logo.svg')" class="" alt="" width="100" contain
                    max-height="40"
                    min-height="40">
                  </v-img>
                </div>
                <div class="show_mob" @click="showMenu()" style="width:80px; display:none">
                  <v-img :src="require('@/assets/ham_menu.png')" class="" alt="" width="100" contain
                    max-height="40"
                    min-height="40">
                  </v-img>
                </div>
                <div class="mob_search" style="width:80%; height: 48px; position: relative;">
                  <div style="width: 20px;
                      position: absolute;
                      top: 29%;
                      left: 2%;">
                    <v-img :src="require('@/assets/search.svg')" class="" alt="" width="20" contain
                      max-height="20"
                      min-height="20">
                    </v-img>
                  </div>
                  <v-autocomplete
                    v-model="filter.country"
                    base-color="#fff"
                    color="#fff"
                    rounded
                    variant="solo"
                    :items="countryList"
                    :search-input.sync="search"
                    :loading="isLoading"
                    item-text="Name"
                    item-value="id"
                    hide-no-data
                    height="48px"
                    style="height:48px; max-height: 48px; background: rgba(255, 255, 255, 0.15); border-radius: 28px; padding-left: 30px; color:#ffffff;"
                    >
                    </v-autocomplete>
                </div>
              </div>
        <!-- </div> -->
      </div>
      
    </v-container>
  </v-app-bar>
</template>

<script>
//import {mapState} from "vuex";
import {eventBus} from '@/main'

export default {
  name: "Header",
  props: ['type'],
  data() {
    return {
      search:null,
      filter: {
        country: ""
      },
      isLoading:false,
      statusLogs: {
        '1': 'Оплата процентов по группе билетов',
        '2': 'Оплата процентов по одному билету',
        '3': 'Увеличение займа по одному билету',
        '4': 'Уменьшение займа частично по одному билету',
        '5': 'Уменьшение займа полностью по одному билету',
        '6': 'Уменьшение займа частично по группе билетов',
        '7': 'Уменьшение займа полностью по группе билетов'

      },
      countryList: [
        { id: '1', Name: 'Англия' },
        { id: '2', Name: 'Австралия' },
        { id: '3', Name: 'Австрия' },
        { id: '4', Name: 'Айзербаджан' },
        { id: '5', Name: 'Албания' },
        { id: '6', Name: 'Алжир' }
      ],
    }
  },
  computed: {
    // ...mapState({
    //   isLoading: state => state.client.isLoading,
    //   listClient: state => state.client.listClient,
    //   listMonitor: state => state.monitor.listMonitor,
    //   userType: state => state.userType, //Роль пользователя
    //   userFIO: state => state.userFIO, //Фио пользователя
    // })
  },
  methods: {
    goMain(){
      this.$router.push({path: '/main', replace: true})
    },
   showMenu(){
    eventBus.$emit('showmenu', {
      show: true,
    })
   },
    // sendRequest() {
    //   this.cardRightNow = false;
    //   var xmlhttp = new XMLHttpRequest();
    //   let vm = this

    //   xmlhttp.onreadystatechange = function() {
    //     if (xmlhttp.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
    //       console.log('RESSS'+xmlhttp.responseText)
    //       //let responseTest = '3FB5DF5B ';
    //       if (xmlhttp.status == 200) {
    //         clearInterval(vm.counterInterval)
    //         if(xmlhttp.responseText.length>0)
    //         {
    //           if(!vm.cardRightNow)
    //           {
    //             var transformArray = xmlhttp.responseText.split(/(..)/g).filter(s => s);
    //             transformArray.reverse();
    //             var resultString = transformArray.join('')+'000000';
    //             console.log(resultString);
    //             console.log('Card has been placed on reader: '+resultString); // action here!!!!

    //             //vm.$router.push({ name: 'Desktop', params: { client_id: resultString } })
    //             vm.fetchFeedCard(resultString);
    //             vm.cardRightNow = true;
    //           }
    //         }
    //         else
    //         {
    //           vm.counterInterval = setInterval(vm.sendRequest, 1000);
    //           if(vm.cardRightNow)
    //           {
    //             cardRightNow = false;
    //             console.log('no card');
    //             console.log('Card has been removed from reader: '); //card removed (may be needed in future)
    //           }
    //         }
    //       }
    //       else if (xmlhttp.status == 400) {
    //         console.log('There was an error 400');
    //       }
    //       else {
    //         console.log('something else other than 200 was returned');
    //       }

    //     }
    //   };

    //   xmlhttp.open("GET", "http://localhost:8081/scard", true);
    //   xmlhttp.send();
    // },
  },
  destroyed: function(){
    //clearInterval(this.counterInterval)
  },
  watch: {
    // search() {
    //   if (this.search !== '' || !this.isLoading) {
    //     this.$store.dispatch(clientActionTypes.searchClient, {
    //       client_field: this.search
    //     })
    //   }
    // }
  }
}
</script>

<style scoped>
.profile-name {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.profile-position {
  font-size: 13px;
  line-height: 16px;
  color: #767676;
}
.profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #F1F1F1;
  border-radius: 23.75px;
}
.custom__menu {
  max-height: 112px !important;
  max-width: 218px !important;
}
.custom__menu > .v-list > .v-list-item {
  min-height: 32px !important;
}
.custom__menu > .v-list > .v-list-item > .v-list-item__title {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;
}

.search_client {
  min-width: 50%;
  display: flex;
  align-items: center;
}

.return-back {
  background-color: #fff !important;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  width: 48px;
  padding: 20px 16px !important;
  margin-right: 15px;
  box-shadow: none !important;
}

.edit-button {
  width: 100%;
  display: flex;
  align-items: center;
}

.user-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: 100px;
  height: 100px;
  border-radius: 145px;
  background-color: #F1F1F1;
}


.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}
.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 450px;
  height: 337.5px;
  background-color: #fff;
  position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}
.web-camera-container .camera-shoot {
  margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
  height: 15px;
  object-fit: cover;
}
.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.v-input__slot {
    min-height: 48px !important;
}
/* .v-text-field > .v-input__control > .v-input__slot:after {
    display: none !important;
} */
/*
  These are the selectors controlling the selected item
  in v-autocomplete and v-select
*/
.v-select.v-text-field input {
  color: white !important;
}
.theme--light.v-input input, .theme--light.v-input textarea {
    color: white !important;
}
#input-11 {
  color: white !important;
}
</style>