<template>
 
  <v-row style="background-color: #fff; border-radius: 8px;">
    <div width="50px" style="padding-left:20px;" v-if="loading" > <img :src="require('@/assets/load_gif.gif')" class="" alt="" width="50px" max-height="24"
            min-height="24">
          </div>
    <v-col
      class="d-flex"
      cols="12"
      style="align-items:center; height: 56px;"
    >
      <div style="display: flex; align-items: center; ">
        <span style="font-size: 22px; font-weight: 500; padding-left: 12px;"> Последние матчи </span>
      </div>

    </v-col>

    <v-col
      class="d-flex"
      cols="12"
      style="padding:16px 24px; "
      v-if="events_list && events_list[0] && events_list[0].games && events_list[0].games[0]"
    >
      <div style="height:16px;display:flex; align-items:center; width:100%; padding: 10px 0px; ">
        <div class="stat_chose_but"
        @click="statContent = 'all'" v-bind:class="{ active: statContent == 'all' }"
        style="display:flex;cursor:pointer; align-items:center; justify-content: center; height:32px; width: 157px; border-radius: 6px;">
          <span style="color:#333; font-size: 14px;">Итого</span>
        </div>
        <div class="stat_chose_but"
        @click="statContent = 'home'" v-bind:class="{ active: statContent == 'home' }"
        style="margin-left:12px;cursor:pointer; justify-content: center; display:flex; align-items:center; height:32px; min-width: 157px; padding:0px 10px; border-radius: 6px;">
        
        <span style="color:#333; font-size: 14px;" v-if="def_event && def_event.team_1_name">{{ def_event.team_1_name }} - дома</span>
        <span style="color:#333; font-size: 14px;" v-else-if="events_list[0].games[0].competitor_1_name">{{ events_list[0].games[0].competitor_1_name }} - дома</span>
        </div>
        <div class="stat_chose_but"
        @click="statContent = 'away'" v-bind:class="{ active: statContent == 'away' }"
        style="margin-left:12px;cursor:pointer; justify-content: center; display:flex; align-items:center; height:32px; min-width: 157px; padding:0px 10px; border-radius: 6px;">
        <span style="color:#333; font-size: 14px;" v-if="def_event && def_event.team_2_name">{{ def_event.team_2_name }} - в гостях</span>
        <span style="color:#333; font-size: 14px;" v-else-if="events_list[0].games[0].competitor_2_name">{{ events_list[0].games[0].competitor_2_name }} - в гостях</span>
        </div>
      </div>
    </v-col>
  <!-- </v-row> -->

  <v-col
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"

      >


  <v-row v-if="statContent == 'all'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span v-if="events_list[5] && events_list[5].name" style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[5].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[5].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_logo="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;">
            <div><span style="color:#838383; font-size:14px;text-transform: uppercase;"> {{ getSeasonName(item.season_name) }}</span></div>
              <div v-if="item.competitor_1_country_code == item.competitor_2_country_code" style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/'+item.competitor_1_country_code+'.png'" class="" :alt="item.season_name" :title="item.season_name"  max-height="24" min-height="24" contain></v-img>
              </div>
              <div v-else style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/world.png'" class="" :alt="item.season_name" :title="item.season_name"  max-height="24" min-height="24" contain></v-img>
              </div>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_1_logo && item.competitor_1_logo.length > 3" :src="item.competitor_1_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_2_logo && item.competitor_2_logo.length > 3" :src="item.competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)"  style="display: flex; align-items: center;justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row>

  <v-row v-if="statContent == 'all'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span v-if="events_list[6] && events_list[6].name" style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[6].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[6].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_logo="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;">
            <div><span style="color:#838383; font-size:14px;text-transform: uppercase;"> {{ getSeasonName(item.season_name) }}</span></div>
              <div v-if="item.competitor_1_country_code == item.competitor_2_country_code" style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/'+item.competitor_1_country_code+'.png'" class="" :alt="item.season_name" :title="item.season_name"  max-height="24" min-height="24" contain></v-img>
              </div>
              <div v-else style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/world.png'" class="" :alt="item.season_name" :title="item.season_name"  max-height="24" min-height="24" contain></v-img>
              </div>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_1_logo && item.competitor_1_logo.length > 3" :src="item.competitor_1_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_2_logo && item.competitor_2_logo.length > 3" :src="item.competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)"  style="display: flex; align-items: center; justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row>




  <!------------------------------------------------------------------------------->


  <v-row v-if="statContent == 'home'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span v-if="events_list[1] && events_list[1].name" style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[1].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[1].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_logo="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;">
            <div><span style="color:#838383; font-size:14px;text-transform: uppercase;"> {{ getSeasonName(item.season_name) }}</span></div>
              <div v-if="item.competitor_1_country_code == item.competitor_2_country_code" style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/'+item.competitor_1_country_code+'.png'" class="" :alt="item.season_name" :title="item.season_name"  max-height="24" min-height="24" contain></v-img>
              </div>
              <div v-else style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/world.png'" class="" :alt="item.season_name" :title="item.season_name" max-height="24" min-height="24" contain></v-img>
              </div>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_1_logo && item.competitor_1_logo.length > 3" :src="item.competitor_1_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_2_logo && item.competitor_2_logo.length > 3" :src="item.competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)"  style="display: flex; align-items: center; justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row>

  <!-- <v-row v-if="statContent == 'home'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[3].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[3].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/'+item.competitor_1_logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/'+item.competitor_2_logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)"  style="display: flex; align-items: center;justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row> -->

    <!------------------------------------------------------------------------------->


  <!-- <v-row v-if="statContent == 'away'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[2].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[2].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/'+item.competitor_1_logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="require('@/assets/'+item.competitor_2_logo+'')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)"  style="display: flex; align-items: center; justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row> -->

  <v-row v-if="statContent == 'away'">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      v-if="statContent == 'away'"
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span v-if="events_list[4] && events_list[4].name" style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[4].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[4].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_logo="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;">
            <div><span style="color:#838383; font-size:14px;text-transform: uppercase;"> {{ getSeasonName(item.season_name) }}</span></div>
              <div v-if="item.competitor_1_country_code == item.competitor_2_country_code" style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/'+item.competitor_1_country_code+'.png'" class="" :alt="item.season_name" :title="item.season_name" max-height="24" min-height="24" contain></v-img>
              </div>
              <div v-else style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/world.png'" class="" :alt="item.season_name" :title="item.season_name" max-height="24" min-height="24" contain></v-img>
              </div>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_1_logo && item.competitor_1_logo.length > 3" :src="item.competitor_1_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_2_logo && item.competitor_2_logo.length > 3" :src="item.competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)" style="display: flex; align-items: center;justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row>

  <v-row v-if="statContent">
    <v-col
      class="d-flex"
      cols="12"
      style="cursor: pointer; background-color:#F8F8F8; "
      
    >
      <div style="height:16px; display:flex; align-items:center; justify-content: space-between; width:100%; padding: 10px 0px; ">
        <div style="display:flex; align-items:center;">
          <span v-if="events_list[0] && events_list[0].name" style="padding-left: 10px; text-transform: uppercase; color:#838383; font-size: 12px;">{{ events_list[0].name }}</span>
        </div>
      </div>
    </v-col>
    <v-col
    
      class="d-flex"
      cols="12"
      style="border-top:0.5px solid #E4E5E5;"
    >
    <v-data-table
        :headers="headers"
        :items="events_list[0].games"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        disable-pagination
        style="width:100%;"
      >
      <template v-slot:item.start_time="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;" :id="'event-id-'+id">
            <span style="color:#838383; font-size:14px;"> {{ getDateTime(item.start_time) }}</span>
        </div>
      </template>
      <template v-slot:item.competitor_1_logo="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items:center;">
            <div><span style="color:#838383; font-size:14px; text-transform: uppercase;"> {{ getSeasonName(item.season_name) }}</span></div>
              <div v-if="item.competitor_1_country_code == item.competitor_2_country_code" style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/'+item.competitor_1_country_code+'.png'" class="" :alt="item.season_name" :title="item.season_name" max-height="24" min-height="24" contain></v-img>
              </div>
              <div v-else style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img :src="'https://dev.skaut.app/images/country/world.png'" class="" :alt="item.season_name" :title="item.season_name" max-height="24" min-height="24" contain></v-img>
              </div>
        </div>
      </template>
      <template v-slot:item.competitor_1_name="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">
          <div style="height:100%;display: flex; align-items:center; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_1_logo && item.competitor_1_logo.length > 3" :src="item.competitor_1_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <div style="width:28px; height:28px; display:flex; align-items:center; padding: 5px; border-radius: 50px; background: #F8F8F8;">
                <v-img v-if="item.competitor_2_logo && item.competitor_2_logo.length > 3" :src="item.competitor_2_logo" class="" alt=""  max-height="24" min-height="24" contain></v-img>
                  <v-img v-else :src="require('@/assets/teams/defteam.png')" class="" alt=""  max-height="24" min-height="24" contain></v-img>
              </div>
            </div>
          </div>
          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_1_name }}</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span> {{ item.competitor_2_name }}</span>
            </div>
          </div>
      </div>
      </template>
      <template v-slot:item.period_1_home_score="{item}">
        <div @click="setEvent(item)" style="cursor:pointer; display: flex; align-items: center;">

          <div style="height:100%;display: flex;flex-direction: column; padding:2px 10px; " >
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.home_score"> {{ item.home_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_home_score"> ({{ item.period_1_home_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
            <div style="display: flex;align-items: center; margin:2px 0px;">
              <span style="font-weight: bold; font-size: 14px;" v-if="item.away_score"> {{ item.away_score }}</span>
              <span style="font-weight: bold; font-size: 14px;" v-else> 0 </span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-if="item.period_1_away_score"> ({{ item.period_1_away_score }})</span>
              <span style="font-weight: bold; padding-left:8px; color:#747780; font-size: 14px;" v-else> (0)</span>
            </div>
          </div>
      </div>
      </template>

      <template v-slot:item.winner_id="{item}">
        <div @click="setEvent(item)" style="display: flex; align-items: center; justify-content: flex-end;">

          <div v-if="item.winner_id && item.winner_id == item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
              style="position:relative; margin-right:8px; cursor:pointer; background: #019C1A; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center; color:#fff; font-size:14px;"> 
              <span>В</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="item.winner_id && item.winner_id != item.competitor_1_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="!item.winner_id && item.event_status != 'not_started'" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #E46E00; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>Н</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div>
            <!-- <div v-else-if="item.winner_id" @mouseover="item.show_hidden = false" @mouseleave="item.show_hidden = true" 
            style="position:relative;margin-right:8px; cursor:pointer; background: #C70000; width:24px; height:24px;padding: 2px 0px;border-radius: 4px; display: flex; align-items:center; justify-content: center;color:#fff; font-size:14px;"> 
              <span>П</span>
              <div v-if="!item.show_hidden" style="display:flex; flex-direction:column; opacity: 0.9; position:absolute; z-index: 400; transition:0.3s; left:-248px; width:236px; min-height:48px; height:auto; box-sizing: border-box; background-color: #333; padding:10px; color:#fff; font-size: 12px; border-radius: 8px;">
                <div>
                  <span>{{ item.season_name }}</span>
                </div>
              </div>
            </div> -->

      </div>
      </template>

    </v-data-table>
    </v-col>

  </v-row>

</v-col>
</v-row>


</template>

<script>
import {mapState} from "vuex";
import {eventsActionTypes} from '@/store/modules/events'

export default {

name: 'EventH2H',
components: {
  //VueDatePicker
  //McvValidationErrors
},
data() {
  return {
    statContent:'all',
    dateRangeText:new Date(),
    format:'DD.MM dd',
    id: "",
    name:"",
    status:"",
    result:"",
    date_receipt_id:"",
    status_id: "",
    shop_name:"",
    user_data:"",
    city:"",
    created:"",
    payments:"",
    loading:true,
    events_list: [],
    def_event:null
  }
},
computed: {
  ...mapState({
    isLoading: state => state.event.isLoading,
    event: state => state.event.data,
    validationErrors: state => state.event.validationErrors,
  }),
  headers() {
    return [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'start_time',
        width: '10%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'competitor_1_logo',
        width: '10%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'competitor_1_name',
        width: '30%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'period_1_home_score',
        width: '40%'
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'winner_id',
        width: '10%'
      },
    ];

  },
},
watch: {
},
mounted() {
  this.def_event = JSON.parse(localStorage.getItem('localEvent'))
  this.fetchFeed()
},
methods: {
  getSeasonName(name){
    let res = ''
    if(!name){
      return res
    }
    if(name.split(' ')[1]) {
      if(name.split(" ")[1].split('20')[1] || name.split(" ")[1].split('21')[1] || name.split(" ")[1].split('22')[1] || name.split(" ")[1].split('23')[1] || name.split(" ")[1].split('24')[1]){
        if(name.split(" ")[0].split('-л')[1] || name.split(" ")[0].split('-Л')[1] || name.split(" ")[0].split('-l')[1]){
          res = name.split(" ")[0].substr(0, 1)+''+name.split(" ")[0].split('-')[1].substr(0, 1)
        } else {
          res = name.split(" ")[0].substr(0, 4)
        }

      } else {
        res = name.split(" ")[0].substr(0, 1) + '' + name.split(" ")[1].substr(0, 1)
      }
    } else {
      res = name.split(" ")[0].substr(0, 2)
    }
    return res
  },
  setEvent(event){
  console.log(event)
  var eventItem = {}
  eventItem.league_logo = event.event_logo ? event.event_logo : "leagues/uefa_champions.png"
  eventItem.league_id = event.competition_id
  eventItem.league_name = event.competition_name
  eventItem.league_country = event.category_name
  eventItem.id = event.event_id
  eventItem.name = event.event_name
  eventItem.result = (event.event_status == 'not_started' || event.event_status == 'live') ? ':' : (event.home_score?event.home_score:0)+':'+(event.away_score?event.away_score:0)
  eventItem.status = event.event_status
  eventItem.date = ''
  eventItem.time = this.getDateTime(event.start_time)
  eventItem.team_1_id = event.competitor_1_id
  eventItem.team_1_name = event.competitor_1_name
  eventItem.team_1_logo = event.competitor_1_logo
  eventItem.team_1_result = event.home_score ? event.home_score : 0
  eventItem.team_1_result_1 = event.period_1_home_score
  eventItem.team_2_id = event.competitor_2_id
  eventItem.team_2_name = event.competitor_2_name
  eventItem.team_2_logo = event.competitor_2_logo
  eventItem.team_2_result = event.home_score ? event.home_score : 0
  eventItem.team_2_result_1 = event.period_2_home_score
  console.log(eventItem)
  localStorage.setItem('localEvent',JSON.stringify(eventItem))
  if(this.$route.path.toString().split('ntnex').length > 1){
    this.$router.push({path: '/event/'+eventItem.id, replace: true})
  } else {
    this.$router.push({path: '/eventnext/'+eventItem.id, replace: true})
  }
  },
  getDateTime(timestamp){
    return this.CalcTime(new Date(timestamp).getDate()) + '.' + this.CalcTime(new Date(timestamp).getMonth() + 1) + '.' + this.CalcYear(new Date(timestamp).getUTCFullYear())
  },
  CalcTime(time){
    return (+time < 10) ? '0'+time : time 
  },
  CalcYear(year){
    return year-2000
  },
  fetchFeed() {
      var evv = JSON.parse(localStorage.getItem('localEvent'))

      this.loading = true

      this.$store.dispatch(eventsActionTypes.getEventH,{competitor_1_id:evv.team_1_id,competitor_2_id:evv.team_2_id}).then((data) => {
        console.log(data)
        this.events_list = data.event_list
        this.loading = false;
      })

      
    },

},
}
</script>

<style scoped>
.blcks>.d-flex:hover {
transition: 0.4s;
background: #FFF9EC;
border-bottom:3px solid #FCC341; 
}
.blcks>.active {
border-bottom:3px solid #FCC341;
background: #FFF9EC; 
}

.demo-wrap {
position: relative;
}

.demo-wrap:before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
opacity: 0.1;
/* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
background-image: var(--bgImage);
background-repeat: no-repeat;
background-position: 95% 42%;
  background-size: 50% 217%;
}

.demo-content {
position: relative;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
display: none !important;
}
.year_check > .v-input__control > .v-input__slot:before {
display: none !important;
}

.v-data-table >>> .v-data-table-header {
  height: 0px !important;
}

.v-data-table >>> th {
  height: 0px !important;
}

.stat_chose_but {
  transition: 0.5s;
}
.stat_chose_but:hover{
  transition: 0.5s;
  background: #FFF9EC;
}
.stat_chose_but.active{
  background: #FCC341;
}

</style>

