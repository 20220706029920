import teamsApi from '@/api/teams'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  teamplayers: null,
  validationErrors: null,
  team: null
}

export const mutationTypes = {

  getTeamStart: '[team] Get team start',
  getTeamSuccess: '[team] Get team success',
  getTeamFailure: '[team] Get team failure',

  getTeamPlayersStart: '[teamplayers] Get teamplayers start',
  getTeamPlayersSuccess: '[teamplayers] Get teamplayers success',
  getTeamPlayersFailure: '[teamplayers] Get teamplayers failure',

  getTeamTransfersStart: '[teamtransfers] Get teamtransfers start',
  getTeamTransfersSuccess: '[teamtransfers] Get teamtransfers success',
  getTeamTransfersFailure: '[teamtransfers] Get teamtransfers failure',
}

export const teamsActionTypes = {
  getTeam: '[teams] Get team',
  getTeamPlayers: '[teamplayers] Get teamplayers',
  getTeamTransfers: '[teamtransfers] Get teamtransfers'
}

const mutations = {
  [mutationTypes.getTeamStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getTeamSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.team = payload
  },
  [mutationTypes.getTeamFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getTeamPlayersStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getTeamPlayersSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.teamplayers = payload
  },
  [mutationTypes.getTeamPlayersFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getTeamTransfersStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.getTeamTransfersSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.teamtransfers = payload
  },
  [mutationTypes.getTeamTransfersFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

}

const actions = {
  [teamsActionTypes.getTeam](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTeamStart)
      teamsApi
        .getTeam(data)
        .then(response => {
          context.commit(mutationTypes.getTeamSuccess, response)
          console.log(response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getTeamFailure)
        })
    })
  },
  [teamsActionTypes.getTeamPlayers](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTeamPlayersStart)
      teamsApi
        .getTeamPlayers(data)
        .then(response => {
          context.commit(mutationTypes.getTeamPlayersSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getTeamPlayersFailure)
        })
    })
  },
  [teamsActionTypes.getTeamTransfers](context, data) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getTeamTransfersStart)
      teamsApi
        .getTeamTransfers(data)
        .then(response => {
          context.commit(mutationTypes.getTeamTransfersSuccess, response)
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.getTeamTransfersFailure)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations
}
